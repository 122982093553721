import React, { useState, useEffect, useCallback } from 'react';
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import { getTitle } from './constant';
import {
  fetchRules,
  fetchRulesById,
  fetchRuleMetaData,
} from '../../Utils/endPoints';
import {
  getInformationRuleUrl,
  getInformationRuleUrlById,
  getRuleMetaData,
} from '../../Utils/serviceUrls';
import { makeStyles } from '@mui/styles';
import styles from './styles.module.scss';
import Spinner from '../../common/Spinner';
import Tabs from '../../common/TabsComponent/TabsComponent';
import Link from '@mui/material/Link';
import MuiTableComponent from '../../common/TableComponent/MuiTableComponent';
import CustomiseModal from '../../common/Modal/Modal';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import Sidebar from '../../common/SidebarComponent';
import ActiveFilters from '../../common/ChipsComponent/ChipsComponent';
import FilterIcon from '../../assets/filter_icon.svg';
import { Download } from '@mui/icons-material';
import { addNotification } from '../../redux/Snackbar/actions';
import { useDispatch } from 'react-redux';
import useToggle from '../../Utils/hooks/useToggle';
import SearchBox from '../../common/DropDownComponent/DropDownComponent';
import LastUpdatedTime from '../../common/LastUpdatedTime';

const useStyles = makeStyles(() => ({
  root: {
    '&[disabled]': {
      color: 'grey',
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));
const initialState = {
  tabValue: 0,
  columns: [],
  filterList: [],
  pageNo: 0,
  rowsPerPage: 50,
  searchObject: {},
  checkedColumns: [],
  selectedColumn: '',
  selectedOrder: 'ASC',
  isRule: true,
};
const initialCriteria = {
  PRODUCT: 'id',
  HUB: 'name',
  CITY: 'name',
  CLUSTER: 'name',
  AREA_MANAGER: 'name',
};
const parseMethod = (value) => {
  return Number(value) || 0;
};

const RuleInformationList = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [ruleDetails, setRuleDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRuleList, setLoadingRuleList] = useState(false);
  const [metaLoading, setMetaLoading] = useState(false);
  const [selectedRule, setSelectedRule] = useState(
    { rule_id: queryParams?.selectedRule } || null,
  );
  const [tabList, setTabList] = useState([]);
  const [metaDataList, setMetaDataList] = useState({});
  const [isColumn, setIsColumn] = useState(false);
  const getQueryParams = () => {
    return {
      pageNo: parseMethod(queryParams?.pageNo || initialState.selectedColumn),
      rowsPerPage: parseMethod(
        queryParams?.rowsPerPage || initialState.rowsPerPage,
      ),
      tabValue: parseMethod(queryParams?.tabValue || initialState.tabValue),
      selectedColumn:
        queryParams?.selectedColumn || initialState.selectedColumn,
      selectedOrder: queryParams?.selectedOrder || initialState.selectedOrder,
    };
  };
  const [selectedTab, setSelectedTab] = useState({
    ...initialState,
    ...getQueryParams(),
  });
  const [isSidebarOpen, setIsSidebarOpen] = useToggle();
  const [selectedCriteria, setSelectedCriteria] = useState(initialCriteria);

  const {
    pageNo,
    rowsPerPage,
    selectedOrder,
    searchObject,
    selectedColumn,
    checkedColumns,
    tabValue,
    filterList,
    columns,
    isRule,
  } = selectedTab;

  useEffect(() => handleUrlParams(), [selectedTab, selectedRule]);

  useEffect(() => getRuleDetails(), []);

  useEffect(() => {
    if (isRule) getRuleDetailsById();
  }, [
    pageNo,
    rowsPerPage,
    selectedOrder,
    searchObject,
    selectedColumn,
    tabValue,
  ]);

  const handleSorting = (column) => {
    let order = selectedOrder;
    if (selectedOrder === 'DESC') order = 'ASC';
    else order = 'DESC';
    setSelectedTab({
      ...selectedTab,
      isRule: true,
      selectedColumn: column,
      selectedOrder: order,
    });
  };

  const columnList =
    React.useMemo(() => {
      if (selectedTab) return checkedColumns;
      else return [];
    }, [selectedTab]) || [];

  const getRuleDetails = () => {
    setMetaLoading(true);
    setLoadingRuleList(true);
    fetchRules(getInformationRuleUrl())
      .then((res) => {
        setLoadingRuleList(false);
        setMetaLoading(false);
        const { data } = res;
        setRuleDetails(data);
        const selectedObject = data.find(
          (each) => each.rule_id === parseMethod(queryParams.selectedRule),
        );
        onSelectionChange('', selectedObject || data[0], true);
      })
      .catch((e) => {
        dispatch(
          addNotification({
            level: 'error',
            message: e?.data?.errors?.[0]?.code,
          }),
        );
        setMetaLoading(false);
      });
  };
  const handleCheckBoxArray = (array, newValue) => {
    let index = array.findIndex((data) => data == newValue);
    if (index !== -1) {
      array.splice(index, 1);
      array = [newValue, ...array];
    }
    return array;
  };

  const updateCategoryList = (index, newValue) => {
    let array = handleCheckBoxArray(filterList[index]?.column_values, newValue);
    filterList[index] = { ...filterList[index], column_values: array };
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateCityList = (index, newValue) => {
    let array =
      selectedCriteria.CITY == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.city_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.city_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.CITY == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          city_name_filter: {
            ...filterList[index]?.city_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          city_id_filter: {
            ...filterList[index]?.city_id_filter,
            column_values: array,
          },
        });

    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateHubList = (index, newValue) => {
    let array =
      selectedCriteria.HUB == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.hub_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.hub_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.HUB == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          hub_name_filter: {
            ...filterList[index]?.hub_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          hub_id_filter: {
            ...filterList[index]?.hub_id_filter,
            column_values: array,
          },
        });
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateProductList = (index, newValue) => {
    let array =
      selectedCriteria.PRODUCT == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.product_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.product_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.PRODUCT == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          product_name_filter: {
            ...filterList[index]?.product_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          product_id_filter: {
            ...filterList[index]?.product_id_filter,
            column_values: array,
          },
        });
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };

  const onChange = (event, newValue, type) => {
    let index = filterList.findIndex((data) => data.type === type);
    if (index !== -1) {
      switch (type) {
        case 'CATEGORY':
          return updateCategoryList(index, newValue);
        case 'PRODUCT':
          return updateProductList(index, newValue);
        case 'CITY':
          return updateCityList(index, newValue);
        case 'HUB':
          return updateHubList(index, newValue);
        default:
          return updateCategoryList(index, newValue);
      }
    }
  };

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.code,
      }),
    );
    setMetaLoading(false);
    setLoading(false);
    setSelectedTab({
      ...initialState,
      columns: columns,
      checkedColumns: checkedColumns,
      filterList: filterList,
      tabValue: tabValue,
    });
  };
  const handlegetAllError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.code,
      }),
    );
    setTabList([]);
    setLoading(false);
    setMetaLoading(false);
    setSelectedTab(initialState);
  };

  const handleCheckBoxChange = (event, title) => {
    let checkedArray = searchObject[title] || [];
    let { value } = event.target;
    value = value.toString();
    checkedArray = [...checkedArray, value];
    if (searchObject[title]?.includes(value)) {
      checkedArray = checkedArray.filter((data) => data !== value);
    }
    setSelectedTab({
      ...selectedTab,
      isRule: true,
      searchObject: {
        ...searchObject,
        [title]: checkedArray,
      },
    });
  };
  const getQueryDetails = () => {
    let { searchObject } = selectedTab;
    let jsonObject = {};
    let columnList = [];
    searchObject = Object.keys(searchObject).reduce((accumulator, key) => {
      if (searchObject[key]?.length !== 0) {
        accumulator[key] = searchObject[key];
      }
      return accumulator;
    }, {});
    let length = getLength(searchObject);
    for (let key in searchObject) {
      if (length === 1) {
        jsonObject = {
          type: 'IN',
          column_name: key,
          column_values: searchObject[key],
        };
      } else {
        columnList.push({
          type: 'IN',
          column_name: key,
          column_values: searchObject[key],
        });
        jsonObject = {
          type: 'LOGICAL_AND',
          column_name: key,
          column_values: columnList,
        };
      }
    }
    return jsonObject;
  };
  const getLength = (object) => {
    return Object.keys(object)?.length;
  };
  const getRequestObject = (arrayList, type, tabValue) => {
    let object = {
      database_name: arrayList[tabValue]?.database_name,
      table_name: arrayList[tabValue]?.table_name,
      page_detail: {
        page_no: pageNo,
        page_size: rowsPerPage,
      },
    };
    if (type === 'alert') {
      object = {
        alert_type: arrayList[tabValue]?.alert_type,
        page_detail: {
          page_no: pageNo,
          page_size: rowsPerPage,
        },
      };
    }
    if (type === 'action') {
      object = {
        name: arrayList[tabValue]?.name,
        page_detail: {
          page_no: pageNo,
          page_size: rowsPerPage,
        },
      };
    }
    if (selectedColumn !== '') {
      object = {
        ...object,
        sort_details: [
          {
            sort_order: selectedOrder,
            sort_field: selectedColumn,
          },
        ],
      };
    }
    if (getLength(getQueryDetails()) > 0) {
      object = { ...object, query_condition: getQueryDetails() };
    }
    return [object];
  };

  const getRuleDetailsById = (
    metaDetails = metaDataList,
    tabValue = selectedTab?.tabValue,
  ) => {
    let ruleOutputJson = [];
    let ruleAlertJson = [];
    let ruleActionJson = [];
    if (metaDetails?.rule_id) {
      let length = getArray(metaDetails).length;
      if (length > tabValue) {
        let arrayList = getArray(metaDetails);
        arrayList && arrayList[tabValue]?.database_name
          ? (ruleOutputJson = getRequestObject(arrayList, 'output', tabValue))
          : arrayList[tabValue]?.alert_type
          ? (ruleAlertJson = getRequestObject(arrayList, 'alert', tabValue))
          : (ruleActionJson = getRequestObject(arrayList, 'action', tabValue));
        const body = {
          rule_id: metaDetails?.rule_id,
          rule_output_query_requests: ruleOutputJson,
          rule_alert_query_requests: ruleAlertJson,
          rule_action_output_query_requests: ruleActionJson,
        };
        setLoading(true);
        fetchRulesById(getInformationRuleUrlById(), body)
          .then((res) => {
            setLoading(false);
            const data = res?.data?.rule_output_data || [];
            const alertData = res?.data?.rule_alert_data || [];
            const actionData = res?.data?.rule_action_output_data || [];
            let tabArray = [];
            tabArray = [...arrayList];
            tabArray[tabValue] = [...data, ...alertData, ...actionData][0];
            setTabList(tabArray);
          })
          .catch((e) => {
            handleError(e);
          });
      }
    }
  };

  const getTabTitle = (tab) => {
    if (tab?.title) return tab.title;
  };

  const handleChangePage = (event, newPage) => {
    setSelectedTab({ ...selectedTab, pageNo: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setSelectedTab({
      ...selectedTab,
      pageNo: 0,
      rowsPerPage: +event.target.value,
    });
  };
  const handleRadio = (e, title) => {
    setSelectedCriteria({
      ...selectedCriteria,
      [title]: e.target.value,
    });
  };

  const getArray = (metaList = metaDataList) => {
    const arrayList = [
      ...metaList?.rule_output_details,
      ...metaList?.rule_alert_details,
      ...metaList?.rule_action_output_details,
    ];
    return arrayList;
  };
  const handleUrlParams = useCallback(() => {
    let searchString = '';
    for (let key in searchObject) {
      searchString = searchString.concat(`${key}=${searchObject[key]}&`);
    }
    searchString = searchString.slice(0, -1);
    const params = {
      selectedRule: selectedRule?.rule_id,
      tabValue,
      pageNo,
      rowsPerPage,
      selectedColumn,
      selectedOrder,
      searchString,
    };
    navigate({
      pathname: '',
      search: `?${createSearchParams(params)}`,
    });
  }, [selectedTab, selectedRule]);

  const handleChange = (event, newValue) => {
    const arrayList = getArray();
    let tabDetails = {
      ...initialState,
      tabValue: newValue,
      columns: arrayList[newValue]?.column_details,
      checkedColumns: arrayList[newValue]?.column_details,
      filterList: arrayList[newValue]?.base_filter_details,
    };
    setSelectedTab(tabDetails);
  };

  const handleMetaDeta = (res, reload = false) => {
    setMetaLoading(false);
    const arrayList = getArray(res?.data);
    const length = arrayList.length;
    const columns =
      arrayList[reload && length > tabValue ? tabValue : 0]?.column_details;
    const filterList =
      arrayList[reload && length > tabValue ? tabValue : 0]
        ?.base_filter_details;
    let tabDetails = reload
      ? {
          ...initialState,
          ...getQueryParams(),
          columns: columns,
          checkedColumns: columns,
          filterList: filterList,
          isRule: false,
          tabValue: length > tabValue ? tabValue : 0,
        }
      : {
          ...initialState,
          columns: columns,
          checkedColumns: columns,
          filterList: filterList,
          isRule: false,
        };

    getRuleDetailsById(res?.data, tabDetails.tabValue);
    setMetaDataList(res?.data);
    setSelectedTab(tabDetails);
  };
  const onSelectionChange = (event, newValue, reload = false) => {
    setSelectedRule(newValue ? newValue : null);
    setTabList([]);
    setSelectedTab(
      reload
        ? { ...initialState, isRule: false, ...getQueryParams() }
        : {
            ...initialState,
            isRule: false,
          },
    );
    if (newValue?.rule_id) {
      setMetaLoading(true);
      fetchRuleMetaData(getRuleMetaData(newValue?.rule_id))
        .then((res) => {
          handleMetaDeta(res, reload);
        })
        .catch((e) => {
          handlegetAllError(e);
        });
    } else {
      setTabList([]);
    }
  };
  const applyColumns = (columnData) => {
    let columnList = columns.filter((data) => columnData.includes(data.key));
    setSelectedTab({ ...selectedTab, checkedColumns: columnList });
    setIsColumn(false);
  };

  const handleCancelColumn = () => {
    setIsColumn(false);
  };
  const handleOpenColumn = () => {
    setIsColumn(true);
  };
  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleIndividualClear = (title) => {
    setSelectedTab({
      isRule: true,
      ...selectedTab,
      searchObject: { ...searchObject, [title]: [] },
    });
  };
  const handleClearAll = () => {
    setSelectedTab({
      ...selectedTab,
      searchObject: {},
      selectedOrder: 'ASC',
      selectedColumn: '',
      isRule: true,
    });
  };
  const handleDownload = () => {
    const url = tabList[tabValue]?.s3_url;
    try {
      if (url) window.open(url, '_blank');
    } catch (e) {
      dispatch(
        addNotification({
          level: 'error',
          message: 'csv is blank!!',
        }),
      );
    }
  };

  return (
    <>
      <div
        className={classNames(
          props.className,
          styles.container,
          isSidebarOpen ? styles.main_container : styles.less_container,
        )}>
        <div className={styles.left_container}>
          {metaLoading && isSidebarOpen ? (
            <Spinner />
          ) : (
            <Sidebar
              filterList={filterList}
              handleSidebar={handleSidebar}
              selectedRule={selectedRule}
              arrayList={ruleDetails}
              onSelectionChange={onSelectionChange}
              isSidebarOpen={isSidebarOpen}
              onChange={onChange}
              handleCheckBoxChange={handleCheckBoxChange}
              checkedArrayObject={searchObject}
              loading={loading}
              handleRadio={handleRadio}
              selectedCriteria={selectedCriteria}
            />
          )}
        </div>
        <div className={styles.right_container}>
          {!loadingRuleList && (
            <div className={styles.rule_list_select}>
              <div className={styles.search_box_container}>
                <SearchBox
                  arrayList={ruleDetails}
                  onChange={onSelectionChange}
                  value={selectedRule}
                  label="Rule"
                  placeholder="Select rule"
                />
              </div>
            </div>
          )}
          {!metaLoading && (
            <div className={styles.margin_32_px}>
              <Tabs
                tabValue={tabValue}
                handleChange={handleChange}
                tabList={tabList}
                getTabTitle={getTabTitle}
              />
            </div>
          )}
          {loading || metaLoading ? (
            <Spinner />
          ) : (
            <>
              {getLength(searchObject) > 0 && (
                <div>
                  <ActiveFilters
                    filterObject={searchObject}
                    handleIndividualClear={handleIndividualClear}
                  />
                </div>
              )}
              <div className={styles.flex_container}>
                <div
                  className={classNames(
                    styles.flex_1,
                    styles.table_title_container,
                  )}>
                  <p className={styles.title_container}>
                    <span className={styles.title}>
                      {getTitle(tabList[tabValue])}
                    </span>
                    <LastUpdatedTime
                      timestamp={tabList[tabValue]?.last_updated_at}
                    />
                  </p>
                  <Link
                    className={styles.link}
                    underline="hover"
                    onClick={handleOpenColumn}>
                    Customise
                  </Link>
                </div>
                <div className={styles.right_align_items}>
                  {getLength(searchObject) > 0 && (
                    <Link
                      className={styles.link}
                      underline="hover"
                      onClick={handleClearAll}>
                      Clear All
                    </Link>
                  )}
                  <div className={styles.padding_8}>
                    <RefreshOutlined
                      onClick={() => getRuleDetailsById()}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <p className={styles.padding_8}>
                    count:<span>{tabList[tabValue]?.count}</span>
                  </p>
                  {tabList[tabValue]?.s3_url && (
                    <div
                      className={styles.filter_button}
                      onClick={handleDownload}
                      aria-hidden="true">
                      <p> CSV</p>
                      <Download />
                    </div>
                  )}
                  <div
                    className={styles.filter_button}
                    onClick={handleSidebar}
                    aria-hidden="true">
                    <p>Filters</p>
                    <FilterIcon />
                  </div>
                </div>
              </div>
              <MuiTableComponent
                data-testid="customer_list_table"
                rows={tabList[tabValue]?.data}
                columns={columnList}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loading}
                count={tabList[tabValue]?.count || 0}
                isPagination={true}
                isSorting={true}
                sortBy={selectedColumn}
                handleSorting={handleSorting}
                selectedOrder={selectedOrder}
                selectedColumn={selectedColumn}
              />
            </>
          )}
        </div>
      </div>
      {isColumn && (
        <CustomiseModal
          visible={isColumn}
          applyColumns={applyColumns}
          handleCancel={handleCancelColumn}
          allColumns={columns}
          checkedColumns={checkedColumns}
        />
      )}
    </>
  );
};
export default RuleInformationList;
