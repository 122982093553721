import styles from './styles.module.scss';

export const getTitle = (rule) => {
  const name = rule?.title;
  if (name) return name.toUpperCase();
};

export const PERCENTAGE_COLUMNS = [
  'express_sla_percentage',
  'overall_sla_percentage',
  'scheduled_sla_percentage',
];

export const ListTableConfig = {
  1: [
    {
      id: 'product_name',
      title: 'Product Name',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
      style: styles.column_date,
    },

    {
      id: 'hub_name',
      title: 'Hub Name',
    },
    {
      id: 'sla_express',
      title: 'SLA Express',
    },
    {
      id: 'delivered_datetime',
      title: 'Delivered At',
      style: styles.column_date_time,
    },
    {
      id: 'city_name',
      title: 'City ',
    },

    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'created_at',
      title: 'Created At',
      style: styles.column_date_time,
    },
    {
      id: 'expected_cut_off_delivery_time',
      title: ' Cut Off Delivery Status',
    },
    {
      id: 'breached_order_time',
      title: 'Breached Order Time',
      style: styles.column_date_time,
    },
    {
      id: 'delivery_slot',
      title: 'Delivery Slot',
      style: styles.column_date,
    },
    {
      id: 'order_id',
      title: 'Order Id',
    },
  ],
  2: [
    {
      id: 'product_name',
      title: 'Product Name',
    },
    {
      id: 'rule_audit_id',
      title: 'Rule Audit Id',
    },
    {
      id: 'city_name',
      title: 'City Name',
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
    },
    {
      id: 'total_customer_inventory',
      title: 'Total Customer  Inventory',
    },
    {
      id: 'customer_inventory_variance',
      title: ' Inventory Variance',
    },
    {
      id: 'customer_orders_quantity',
      title: 'Customer Order Quantity',
    },
    {
      id: 'customer_hub_physical_closing_quantity',
      title: 'hub Physical Closing Quantity',
    },
    {
      id: 'superhub_to_customer_hub_inward_quantity',
      title: 'Superhub Physical Closing Quantity',
    },
    {
      id: 'created_at',
      title: 'Created  At',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'hub_name',
      title: 'Hub Name',
    },
  ],
  3: [
    {
      id: 'product_name',
      title: 'Product Name',
      headerClassName: styles.name_column,
    },
    {
      id: 'city_name',
      title: 'City Name',
    },
    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'quantity',
      title: 'Quantity',
    },
    {
      id: 'created_at',
      title: 'Created At',
      style: styles.column_date_time,
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'hub_name',
      title: 'Hub Name',
    },
  ],
  12: [
    {
      id: 'product_name',
      title: 'Product Name',
      data_type: 'STRING',
      style: styles.column_date_time,
    },
    { id: 'category', title: 'Category', data_type: 'STRING' },
    {
      id: 'sale_date',
      title: 'Sale Date',
      data_type: 'STRING',
      style: styles.column_date,
    },
    { id: 'hub_id', title: 'Hub Id', data_type: 'INTEGER' },
    { id: 'hub_name', title: 'Hub Name', data_type: 'STRING' },
    { id: 'city_id', title: 'City Id', data_type: 'INTEGER' },
    { id: 'city_name', title: 'City Name', data_type: 'STRING' },
    { id: 'order_id', title: 'Order Id', data_type: 'STRING' },
    { id: 'customer_id', title: 'Customer Id', data_type: 'STRING' },
    { id: 'sheduled_date', title: 'Scheduled Date', data_type: 'STRING' },
    {
      id: 'delivery_slot',
      title: 'Delivery Slot',
      data_type: 'STRING',
      style: styles.column_date,
    },
    { id: 'status', title: 'Status', data_type: 'STRING' },
    {
      id: 'delivered_datetime',
      title: 'Delivered Datetime',
      data_type: 'DATETIME',
    },
    {
      id: 'creation_datetime',
      title: 'Creation Datetime',
      data_type: 'DATETIME',
    },
    { id: 'express', title: 'Express Order', data_type: 'STRING' },
    { id: 'sla_express', title: 'SLA for express order', data_type: 'INTEGER' },
    {
      id: 'expected_cut_off_delivery_time',
      title: 'Expected Cutoff Time For Delivery',
      data_type: 'LONG',
      style: styles.column_date_time,
    },

    {
      id: 'breached_order_time_seconds',
      title: 'Breached Order Time ',
      data_type: 'LONG',
      style: styles.column_date_time,
    },

    {
      id: 'breached_order_time',
      title: 'Breached Order Time',
      data_type: 'STRING',
      style: styles.column_date_time,
    },

    {
      id: 'customer_orders_quantity',
      title: 'Customer Order Quantity',
      data_type: 'INTEGER',
    },
    {
      id: 'created_at',
      title: 'Created At',
      data_type: 'TIMESTAMP',
      style: styles.column_date_time,
    },
    { id: 'rule_audit_id', title: 'Rule Execution Id', data_type: 'LONG' },
  ],
};

export const STICKY_TABLE_CONFIG_MAP = {
  sla_live_city_level: ['sale_date', 'city_name'],
  sla_live_hub_level: ['sale_date', 'city_name', 'hub_name'],
  on_demand_city_level: ['city_name', 'no_of_hubs'],
  on_demand_hub_level: ['city_name', 'hub_name'],
};

export const RULE_GROUP_ID = 1;

export const TableColumnConfig = {};

export const HIDE_TABS_FROM_UI = [
  'SLA Status PAN View',
  'Customer Order Status View',
  'SLA Status City View',
  'On Demand PAN India View',
];

export const HIDE_COLUMNS = {
  'Delayed Orders': [
    'created_at',
    'rule_audit_id',
    'breached_order_time_seconds',
  ],
  'SLA Live Hub Level': [
    'created_at',
    'rule_audit_id',
    'overall_sla_successfull_orders',
  ],
  'SLA Status City View': [
    'created_at',
    'rule_audit_id',
    'overall_sla_successfull_orders',
  ],
  'Customer Orders Hub View': ['created_at', 'rule_audit_id'],
  OTHERS: ['created_at', 'rule_audit_id'],
};

export const SLA_PAGE_CONFIG = {
  'SLA Live City Level': {
    id: 2,
    sideView: true,
    shouldShowSlaView: true,
    slaViewTableTitle: 'SLA Status PAN View',
    shouldShowOrderView: false,
    tables: ['SLA Live City Level'],
    tabTitle: 'SLA View',
    hideColumns: HIDE_COLUMNS['SLA Live Hub Level'],
  },
  'Customer Orders Hub View': {
    id: 3,
    sideView: true,
    shouldShowSlaView: false,
    shouldShowOrderView: true,
    orderViewTableTitle: 'Customer Order Status View',
    tables: ['Customer Orders Hub View'],
    tabTitle: 'Customer Orders View',
    hideColumns: HIDE_COLUMNS['Customer Orders Hub View'],
  },
  'On Demand City View': {
    id: 4,
    sideView: true,
    shouldShowOndemandSlaView: true,
    shouldShowSlaView: false,
    shouldShowOrderView: false,
    ondemandSlaViewTableTitle: 'On Demand PAN India View',
    tables: ['On Demand City View'],
    tabTitle: 'On Demand SLA View',
    hideColumns: HIDE_COLUMNS['On Demand City View'],
  },
};
