import React from 'react';
import styles from '../../pageComponents/BrechManagementDasboardTab/styles.module.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const SDSuggestedActionTable = ({
  data,
  handleDropdownChange,
  disabledLogs,
  handleLogClick,
}) => {
  return (
    <>
      <TableContainer className={styles.suggested_action_table_container}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className={styles.suggested_action_table_header}>
            <TableRow>
              {['kmlId', 'currentState', 'actionTaken', 'log'].map(
                (key, colIndex) => (
                  <TableCell
                    key={colIndex}
                    align="center"
                    className={styles.suggested_action_header}>
                    {data?.value?.data[0][key]?.description}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.value?.data.map((row, rowIndex) => (
              <TableRow key={row?.kmlId?.value + rowIndex}>
                {['kmlId', 'currentState', 'actionTaken', 'log'].map(
                  (key, colIndex) => (
                    <TableCell
                      key={`${row?.kmlId?.value}-${colIndex}`}
                      className={styles.suggested_action_row}
                      align="center">
                      {key === 'kmlId' && row?.kmlId?.value ? (
                        <span style={{ textAlign: 'left' }}>
                          {row?.kmlId?.value}
                        </span>
                      ) : Array.isArray(row[key]?.value?.values) ? (
                        <>
                          <select
                            id={`${row[key].description}-dropdown-${data?.value?.data?.kmlId?.value}`}
                            className={styles.select_css}
                            style={{ width: '150px' }}
                            onChange={(event) =>
                              handleDropdownChange(
                                event,
                                rowIndex,
                                row[key].description,
                              )
                            }>
                            <option></option>
                            {row[key]?.value?.values.map((value, index) => (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : key === 'log' &&
                        row[key]?.value.type === 'actionButton' ? (
                        <button
                          disabled={disabledLogs[rowIndex]}
                          className={
                            disabledLogs[rowIndex]
                              ? styles.diabled_suggested_action_confirm_btn
                              : styles.suggested_action_confirm_btn
                          }
                          onClick={() => handleLogClick(row, rowIndex)}>
                          {row[key]?.description}
                        </button>
                      ) : (
                        row[key]?.value
                      )}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SDSuggestedActionTable;
