import { forwardRef } from 'react';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import VirtualisedList from './VirtualisedList';
import classNames from 'classnames';

const FilterOptionContainer = forwardRef((props, ref) => {
  const { children, onApplyFilters, onResetFilters, className, ...rest } =
    props;
  return (
    <div style={{ minWidth: '250px' }} ref={ref}>
      <ul {...rest} className={classNames(styles.ul_list, className)}>
        <VirtualisedList list={children} />
      </ul>
      <div className={styles.filter_apply_btn_container}>
        <Button
          data-testid="apply-btn-test-id"
          onMouseDown={onResetFilters}
          onClick={onResetFilters}
          className={styles.filter_apply_btn}
          variant="outlined"
          children="Reset"
        />
        <Button
          data-testid="apply-btn-test-id"
          onMouseDown={onApplyFilters}
          onClick={onApplyFilters}
          className={styles.filter_apply_btn}
          variant="contained"
          children="Apply"
        />
      </div>
    </div>
  );
});

export default FilterOptionContainer;
