import style from './style.module.scss';
import cx from 'classnames';
import LsCardValue from '../TabCard/CardValue';
import LsCardLabel from '../TabCard/CardLabel';
import LsTabCard from '../TabCard';

const LsTabCardList = ({ children, className, ...rest }) => {
  return (
    <div role="tablist" className={cx(style.card_list, className)} {...rest}>
      {children}
    </div>
  );
};

LsTabCardList.TabCard = LsTabCard;
LsTabCardList.TabCardValue = LsCardValue;
LsTabCardList.TabCardLabel = LsCardLabel;

export default LsTabCardList;
