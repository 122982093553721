import { Outlet } from 'react-router-dom';
import Header from '../../common/Header';
import LoginProvider from '../LoginProvider';
// import { useSelector } from 'react-redux';
// import { selectUserRoles } from '../../redux/UserReducer/selectors';

const AppWrapper = () => {
  //commented part might need in future
  // const userRoleDetails = useSelector(selectUserRoles);
  // let filteredRoles = userRoleDetails?.roles?.filter(function (str) {
  //   return str.includes('CENTRAL_CITY_ACESS');
  // });

  return (
    <LoginProvider>
      {/* <If condition={filteredRoles?.[0] === 'EVENT_CONFIG_UI'}>
        <ControlTowerHeader />
        <Else />
        <Header />
      </If> */}
      <Header />
      <Outlet />
    </LoginProvider>
  );
};

export default AppWrapper;
