import React from 'react';
import ControlTowerSideBar from '../../common/controlTowerSideBar';
import style from './styles.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';

const ControlTowerMainPage = () => {
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className={style.main_container}>
        <ControlTowerSideBar handleItemClick={handleItemClick} />
        <Outlet />
      </div>
    </>
  );
};

export default ControlTowerMainPage;
