import { apiCall } from './axiosWrapper';
export const fetchRules = (url, body = null) => {
  return apiCall('get', url, body);
};
export const fetchRulesById = (url, body = null) => {
  return apiCall('post', url, body);
};
export const fetchRuleMetaData = (url, body = null) => {
  return apiCall('get', url, body);
};
export const fetchGroupRuleIds = (url) => {
  return apiCall('get', url);
};

export const fetchAllJobConfig = (url, body = null) => {
  return apiCall('get', url, body);
};
export const fetchJobConfigData = (url, body = null) => {
  return apiCall('post', url, body);
};

export const fetchJobConfigDataS3Url = (url, body = null) => {
  return apiCall('get', url, body);
};
export const fetchJobConfigMetaData = (url, body = null) => {
  return apiCall('get', url, body);
};

//CEP Central CT endpoints
export const fetchCardsData = (url, body = null) => {
  return apiCall('post', url, body);
};
export const fetchColumnData = (url, body = null) => {
  return apiCall('get', url, body);
};

export const fetchRowData = (url, body = null) => {
  return apiCall('post', url, body);
};

export const fetchCardNames = (url, body = null) => {
  return apiCall('get', url, body);
};

export const fetchFiltersData = (url, body = null) => {
  return apiCall('get', url, body);
};

export const fetchAlertIdDetails = (url, body = null) => {
  return apiCall('post', url, body);
};

export const ResolveAlert = (url, body = null) => {
  return apiCall('put', url, body);
};

export const LogSubTask = (url, body = null) => {
  return apiCall('put', url, body);
};

export const fetchLinkedTicketDetails = (url, body = null, headers) => {
  return apiCall('get', url, body, headers);
};

export const fetchBreachDetails = (url, body = null, headers) => {
  return apiCall('post', url, body, headers);
};

export const fetchDailyTrendsTopCards = (url, body = null, headers) => {
  return apiCall('get', url, body, headers);
};
export const fetchDTCardWiseDetails = (url, body = null, headers) => {
  return apiCall('get', url, body, headers);
};
export const fetchListOfDegradation = (url, body = null, headers) => {
  return apiCall('get', url, body, headers);
};

export const fetchResonsOfDegradation = (url, body = null, headers) => {
  return apiCall('get', url, body, headers);
};

export const degradeService = (url, body = null, headers) => {
  return apiCall('post', url, body, headers);
};
