import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Spinner from '../../common/Spinner';
import Tabs from '../../common/TabsComponent/TabsComponent';
import SlaDetails from './slaDetails';
import OrderDetails from './orderDetails';

import { addNotification } from '../../redux/Snackbar/actions';
import {
  HIDE_TABS_FROM_UI,
  RULE_GROUP_ID,
  SLA_PAGE_CONFIG,
  STICKY_TABLE_CONFIG_MAP,
  TableColumnConfig,
} from './constant';
import {
  fetchRulesById,
  fetchRuleMetaData,
  fetchGroupRuleIds,
} from '../../Utils/endPoints';
import {
  getGroupRuleInfo,
  getInformationRuleUrlById,
  getRuleMetaData,
} from '../../Utils/serviceUrls';

import styles from './styles.module.scss';
import { useSearchParams } from 'react-router-dom';
import SlaPageTable from './Table';
import { getSlaViewPageApiBody } from './service';
import OnDemandSlaView from './onDemandSlaView';

const LiveDashboardComponent = (props) => {
  const dispatch = useDispatch();

  const isLoadingRuleDetails = useRef(false);

  const [loadingRules, setLoadingRules] = useState(false);
  const [rules, setRules] = useState([]);
  const [loadingRuleDetails, setLoadingRuleDetails] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState({});

  const [loadingSlaData, setLoadingSlaData] = useState(false);
  const [slaData, setSlaData] = useState({});
  const [loadingOrderDetails, setLoadingOrderDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [loadingOnDemandSlaData, setLoadingOnDemandSlaData] = useState(false);
  const [onDemandSlaData, setOnDemandSlaData] = useState({});

  const [allTabs, setAllTabs] = useState([]);
  const [searchParams] = useSearchParams();
  const defaultRuleGroupId = searchParams.get('rule_group') || RULE_GROUP_ID;
  const [ruleGroupId] = useState(defaultRuleGroupId);

  const tabConfig = useMemo(() => {
    const title = selectedTab?.title;
    const config = SLA_PAGE_CONFIG[title] || selectedTab;
    const { tables } = config;
    if (tables) {
      const transformedTables = tables.map((each) =>
        allTabs.find((e) => e.title === each),
      );
      return { ...config, tables: transformedTables };
    }
    return config;
  }, [selectedTab, allTabs]);

  const shouldShowSlaView = tabConfig.shouldShowSlaView;
  const shouldShowOrderView = tabConfig.shouldShowOrderView;
  const shouldShowOnDemandOrderView = tabConfig.shouldShowOndemandSlaView;
  const shouldShowSideBar =
    shouldShowSlaView || shouldShowOrderView || shouldShowOnDemandOrderView;

  const handleError = useCallback((e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.code,
      }),
    );
  }, []);

  // fetch rules
  useEffect(() => {
    setLoadingRules(true);
    fetchGroupRuleIds(getGroupRuleInfo(ruleGroupId))
      .then((res) => {
        const rules = res?.data || [];
        const transformedRules = rules.map((each) => ({
          ...each,
          label: each.title,
          value: each.rule_id,
        }));
        // const [rule] = transformedRules;
        // setSelectedRule(rule);
        setRules(transformedRules);
        setLoadingRules(false);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [ruleGroupId, handleError]);

  const getCustomTableColumnConfig = (column) => {
    return TableColumnConfig[column.key] || {};
  };

  const getStickyColumnConfig = (column, table) => {
    try {
      const sticky = STICKY_TABLE_CONFIG_MAP[table.table_name].includes(
        column.key,
      );
      return { sticky: !!sticky };
    } catch (e) {
      return { sticky: false };
    }
  };

  const fetchTabs = useCallback(
    (ruleId) => {
      return fetchRuleMetaData(getRuleMetaData(ruleId))
        .then((res) => {
          const tabs = res?.data?.rule_output_details || [];
          return tabs.map((each) => ({ ...each, ruleId }));
        })
        .then((res) => {
          return res.map((each) => {
            if (Array.isArray(each?.column_details)) {
              return {
                ...each,
                column_details: each.column_details.map((e) => ({
                  ...e,
                  ...getCustomTableColumnConfig(e),
                  ...getStickyColumnConfig(e, each),
                })),
              };
            }
            return each;
          });
        })
        .catch((e) => {
          handleError(e);
          return [];
        });
    },
    [handleError],
  );

  // fetch tabs
  useEffect(() => {
    if (!rules?.length) return;
    setLoadingRuleDetails(true);
    isLoadingRuleDetails.current = true;
    const promises = rules.map((each) => fetchTabs(each.rule_id));
    Promise.all(promises).then((res) => {
      const allTabs = res.flatMap((each) => each);
      const transformedTabs = allTabs
        .filter((each) => !HIDE_TABS_FROM_UI.includes(each.title))
        .map((each, index) => ({
          ...each,
          tabValue: index,
        }));
      const [tab] = transformedTabs || [];
      setAllTabs(allTabs);
      setTabs(transformedTabs);
      isLoadingRuleDetails.current = false;
      setSelectedTab(tab);
      setLoadingRuleDetails(false);
    });
  }, [rules, fetchTabs]);

  const fetchSlaData = useCallback(() => {
    const slaTabDetails = allTabs.find(
      (each) => each.title === tabConfig.slaViewTableTitle,
    );
    if (!slaTabDetails) return;
    const body = getSlaViewPageApiBody(slaTabDetails);
    setLoadingSlaData(true);
    fetchRulesById(getInformationRuleUrlById(), body)
      .then((res) => {
        const data = res?.data || {};
        const [_slaDetails] = data?.rule_output_data || [];
        setSlaData(_slaDetails);
        setLoadingSlaData(false);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [allTabs, handleError, tabConfig]);

  const fetchOrderDetails = useCallback(() => {
    const orderDetailsTab = allTabs.find(
      (each) => each.title === tabConfig.orderViewTableTitle,
    );
    if (!orderDetailsTab) return;
    const body = getSlaViewPageApiBody(orderDetailsTab);
    setLoadingOrderDetails(true);
    fetchRulesById(getInformationRuleUrlById(), body)
      .then((res) => {
        const data = res?.data || {};
        const [_orderDetails] = data?.rule_output_data || [];
        setOrderDetails(_orderDetails);
        setLoadingOrderDetails(false);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [allTabs, handleError, tabConfig]);

  const fetchOnDemandSlaData = useCallback(() => {
    const slaTabDetails = allTabs.find(
      (each) => each.title === tabConfig.ondemandSlaViewTableTitle,
    );
    if (!slaTabDetails) return;
    const body = getSlaViewPageApiBody(slaTabDetails);
    setLoadingOnDemandSlaData(true);
    fetchRulesById(getInformationRuleUrlById(), body)
      .then((res) => {
        const data = res?.data || {};
        const [_slaDetails] = data?.rule_output_data || [];
        setOnDemandSlaData(_slaDetails);
        setLoadingOnDemandSlaData(false);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [allTabs, handleError, tabConfig]);

  useEffect(() => {
    if (!shouldShowSlaView) return;
    // fetch sla data
    fetchSlaData();
  }, [shouldShowSlaView, fetchSlaData]);

  useEffect(() => {
    if (!shouldShowOrderView) return;
    // fetch order data
    fetchOrderDetails();
  }, [shouldShowOrderView, fetchOrderDetails]);

  useEffect(() => {
    if (!shouldShowOnDemandOrderView) return;
    // fetch ondemand sla data
    fetchOnDemandSlaData();
  }, [shouldShowOnDemandOrderView, fetchOnDemandSlaData]);

  const getTabTitle = (tab) => {
    const title = tab?.title;
    const config = SLA_PAGE_CONFIG[title] || {};
    return config.tabTitle || title || '';
  };

  const handleChangeActiveTab = (event, newValue) => {
    const _selectedTab = tabs[newValue] || {};
    setSelectedTab(_selectedTab);
  };

  const handleRefreshSlaDataView = () => {
    fetchSlaData();
  };

  const handleRefreshOrderDetailsView = () => {
    fetchOrderDetails();
  };

  const handleRefreshOnDemandSlaView = () => {
    fetchOnDemandSlaData();
  };

  return (
    <>
      <Choose>
        <When condition={loadingRules || loadingRuleDetails}>
          <Spinner />
        </When>
        <Otherwise>
          <div className={classNames(props.className, styles.container)}>
            <div className={styles.right_container}>
              <div
                className={classNames(
                  styles.tab_container,
                  styles.margin_32_px,
                )}>
                <Tabs
                  tabValue={selectedTab?.tabValue}
                  handleChange={handleChangeActiveTab}
                  tabList={tabs || []}
                  getTabTitle={getTabTitle}
                />
              </div>
              <div
                className={
                  shouldShowSideBar
                    ? styles.main_container
                    : styles.delay_container
                }>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <If condition={shouldShowSlaView}>
                    <div>
                      <SlaDetails
                        loading={loadingSlaData}
                        tabList={[slaData]}
                        tabValue={0}
                        onRefresh={handleRefreshSlaDataView}
                      />
                    </div>
                  </If>
                  <If condition={shouldShowOrderView}>
                    <div>
                      <OrderDetails
                        loading={loadingOrderDetails}
                        tabList={[orderDetails]}
                        tabValue={0}
                        onRefresh={handleRefreshOrderDetailsView}
                      />
                    </div>
                  </If>
                  <If condition={shouldShowOnDemandOrderView}>
                    <div>
                      <OnDemandSlaView
                        loading={loadingOnDemandSlaData}
                        tabList={[onDemandSlaData]}
                        tabValue={0}
                        onRefresh={handleRefreshOnDemandSlaView}
                      />
                    </div>
                  </If>
                </div>
                <div className={styles.table_container}>
                  <Choose>
                    <When condition={tabConfig?.tables?.length}>
                      <For of={tabConfig?.tables || []} each="each">
                        <SlaPageTable
                          key={each?.table_name}
                          handleError={handleError}
                          tabConfig={each || {}}
                        />
                      </For>
                    </When>
                    <Otherwise>
                      <SlaPageTable
                        key={tabConfig?.table_name}
                        handleError={handleError}
                        tabConfig={tabConfig || {}}
                      />
                    </Otherwise>
                  </Choose>
                </div>
              </div>
            </div>
          </div>
        </Otherwise>
      </Choose>
    </>
  );
};

export default LiveDashboardComponent;
