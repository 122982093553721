import './App.scss';
import { useRoutes } from 'react-router-dom';
import { ApplicationRoutes } from '../Routing/RouteConfig';

export const App = () => {
  const routes = useRoutes(ApplicationRoutes);

  return (
    <>
      <div>{routes}</div>
    </>
  );
};
