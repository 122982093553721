/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Checkbox } from '@mui/material';
import styles from './styles.module.scss';

function FilterOption({ optionProps, option, selected, onClick }) {
  const { onClick: _, ...rest } = optionProps;

  const handleClick = () => {
    onClick(option, !selected);
  };
  return (
    <li {...rest} onClick={handleClick}>
      <Checkbox
        className="margin-right-8"
        classes={{
          root: styles.checkbox_root,
        }}
        checked={selected}
      />
      {option.label}
    </li>
  );
}

export default FilterOption;
