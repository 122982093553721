import { combineReducers } from 'redux';
import { CLEAR_TOKEN } from './saga/UserReducer/constants';
import UserReducer from './UserReducer';
import NotificationReducer from './Snackbar';

const appReducer = combineReducers({
  authReducer: UserReducer,
  notificationReducer: NotificationReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === CLEAR_TOKEN) {
    state = undefined;
  }

  return appReducer(state, action);
};

// export default rootReducer;
export default rootReducer;
