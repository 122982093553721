import styles from './styles.module.scss';

function LastEventTime({ timestamp }) {
  if (!timestamp) return null;
  const text = new Date(timestamp).toLocaleString();
  return (
    <span className={styles.last_event_time}>Last Event Time: {text}</span>
  );
}

export default LastEventTime;
