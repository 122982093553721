import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressBar = (props) => {
  const { percentage } = props;

  const getColor = () => {
    if (!percentage) return '';
    if (percentage < 95) return '#ff9966';
    if (percentage < 98) return '#ffcc00';
    return '#99cc33';
  };

  return (
    <CircularProgressbar
      value={percentage || 0}
      text={`${percentage || 0}%`}
      minValue={0}
      maxValue={100}
      background={true}
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)
        rotation: 0.25,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',

        // Text size
        textSize: '16px',

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        pathColor: getColor(),
        textColor: `black`,
        // trailColor: '#D11243',
        backgroundColor: '#FFFFFF',
      })}
    />
  );
};
export default ProgressBar;
