import style from './style.module.scss';
import cx from 'classnames';

const LsCardValue = ({ children, className, ...rest }) => {
  return (
    <span
      data-testid={rest['data-testid']}
      className={cx(style.card_value, className)}>
      {children}
    </span>
  );
};

export default LsCardValue;
