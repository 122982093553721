import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearToken } from '../../redux/saga/UserReducer/actions.js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styles from './styles.module.scss';
import DownArrowIcon from '../../assets/down_arrow_icon.svg';
import Typography from '@mui/material/Typography';
import LiciousLogo from '../../assets/licious_logoSvg.svg';
import ControlTowerIcon from '../../assets/control_tower_logo.svg';
import ProfileIcon from '../../assets/profile_persona.svg';
import { DEFAULT_PATH } from '../../Routing/RouteConfig.js';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const ControlTowerHeader = (props) => {
  const dispatch = useDispatch();
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const name = authUserResponse?.email_id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(clearToken());
  };

  const handleNavigationToBM = () => {
    return navigate(DEFAULT_PATH);
  };

  return (
    <div className={styles.paper_effect}>
      <header>
        <div className={styles.header_container}>
          <ControlTowerIcon width={29} height={38} />

          <div className={styles.heading_txt}>Control Tower</div>

          <div className={styles.user_container}>
            <div>
              <Button
                className={styles.bm_btn}
                variant="contained"
                children="Apply"
                onClick={handleNavigationToBM}>
                Navigate SLA/CEP
              </Button>
            </div>

            <div
              onClick={handleClick}
              className={styles.user_wrapper}
              aria-hidden="true">
              <div className={styles.profile_icon}>
                <span className={styles.licious_logo_style}>
                  {<LiciousLogo />}
                </span>
                {<ProfileIcon />}
              </div>
              <div className={styles.down_arrow}>
                <DownArrowIcon />
              </div>
            </div>

            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-end"
              className={styles.popper_display}>
              <ClickAwayListener onClickAway={() => handleClose()}>
                <div className={styles.logout_txt}>
                  <nav aria-label="secondary mailbox folders">
                    <List style={{ paddingTop: '0px' }}>
                      <ListItem disablePadding>
                        <ListItemButton component="a">
                          <ListItemText
                            onClick={handleLogout}
                            disableTypography
                            primary={
                              <Typography
                                style={{
                                  fontFamily: 'Satoshi',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                }}>
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </nav>
                </div>
              </ClickAwayListener>
            </Popper>
          </div>
        </div>
      </header>
    </div>
  );
};

export default ControlTowerHeader;
