import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import style from './style.module.scss';
import { SIDEBAR_MENUS } from './constants';
import { matchPath, useLocation } from 'react-router-dom';

export default function ControlTowerSideBar({ items, handleItemClick }) {
  const { pathname } = useLocation();
  return (
    <div className={style.sidebar}>
      <Box>
        {SIDEBAR_MENUS.map((menu, index) => (
          <div
            // className={style.box_css}
            className={
              matchPath({ path: menu.to, end: false }, pathname)
                ? style.active_sidebar_box
                : style.sidebar_box
            }>
            <List className={style.list}>
              <div
                className={
                  matchPath({ path: menu.to, end: false }, pathname)
                    ? style.activeItem
                    : ''
                }>
                <div style={{ marginTop: '15px' }}>
                  <ListItem key={menu} disablePadding>
                    <ListItemButton onClick={() => handleItemClick(menu.to)}>
                      <ListItemIcon
                        className={
                          matchPath({ path: menu.to, end: false }, pathname)
                            ? style.selected_icon
                            : style.non_selected_icon
                        }>
                        {index % 2 === 0 ? (
                          <div className={style.icon_css}>
                            <InboxIcon />
                          </div>
                        ) : (
                          <div className={style.icon_css}>
                            <MailIcon />
                          </div>
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </div>
                <div className={style.tab_name}> {menu.label}</div>
              </div>
            </List>
          </div>
        ))}
      </Box>
    </div>
  );
}
