import React from 'react';
import CheckBoxComponent from '../RadioCheckBoxComponent/checkBoxComponent';
import RadioComponent from '../RadioCheckBoxComponent/radioComponent';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import styles from './styles.module.scss';
import SearchBox from '../../common/DropDownComponent/DropDownComponent';
import SearchFocusIcon from '../../assets/search_focus_icon.svg';
import SearchIcon from '../../assets/search_icon.svg';
import SearchBoxComponent from '../DropDownComponent/SearchComponent';
import { useState } from 'react';
import { TYPE_CONFIG } from './constants';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0',
  },
}));
const radioListByNameById = [
  { id: '1', name: 'By Name', value: 'name' },
  { id: '2', name: 'By ID', value: 'id' },
];

const Sidebar = (props) => {
  const {
    filterList,
    handleSidebar,
    onChange,
    arrayList,
    selectedRule,
    onSelectionChange,
    isSidebarOpen,
    handleCheckBoxChange,
    checkedArrayObject,
    loading,
    handleRadio,
    selectedCriteria,
  } = props;
  const getInitialSearch = () => {
    let object = {};
    for (let i = 0; i < filterList?.length; i++) {
      object = { ...object, [filterList[i].type]: false };
    }
    return object;
  };

  const [isSearchObject, setIsSearchObject] = useState(getInitialSearch());
  const checkSearch = (name) => {
    return isSearchObject[name] ? true : false;
  };
  const disableSearch = (name) => {
    setIsSearchObject({ ...isSearchObject, [name]: false });
  };
  const enableSearch = (name) => {
    setIsSearchObject({ ...isSearchObject, [name]: true });
  };
  const classes = useStyles();

  const getUniqueArray = (array) => {
    return [...new Set(array)];
  };

  const getCategory = (list) => {
    return (
      <div className={styles.sidebar_title_wrapper}>
        <div className={styles.sidebar_title}>
          <div className={styles.sidebar_filter_list_header}>
            <h3>{list?.name}</h3>
            {checkSearch(list?.type) ? (
              <SearchFocusIcon
                className={styles.focus}
                onClick={() => disableSearch(list?.type)}
              />
            ) : (
              <SearchIcon
                className={styles.focus}
                onClick={() => enableSearch(list?.type)}
              />
            )}
          </div>

          {checkSearch(list?.type) && (
            <SearchBoxComponent
              arrayList={getUniqueArray(list?.column_values) || []}
              label={'Search'}
              onChange={onChange}
              title={list?.column_name}
              name={list?.name}
              type={list?.type}
            />
          )}
          <CheckBoxComponent
            title={list?.column_name}
            handleChange={handleCheckBoxChange}
            arrayList={getUniqueArray(list?.column_values) || []}
            checkedArray={checkedArrayObject[list?.column_name] || []}
            disabled={loading}
          />
        </div>
      </div>
    );
  };

  const getReadioList = (idFilter, nameFilter) => {
    const radioList = [];
    if (nameFilter) radioList.push(radioListByNameById[0]);
    if (idFilter) radioList.push(radioListByNameById[1]);
    return radioList;
  };

  const getFilterSection = (list) => {
    const { type } = list;
    const config = TYPE_CONFIG[type] || {};
    const { column_name, column_values, name } =
      selectedCriteria[type] == 'id'
        ? list?.[config.idFilter]
        : list?.[config.nameFilter];
    if (column_values?.length > 0) {
      return (
        <div className={styles.sidebar_title_wrapper}>
          <div className={styles.sidebar_title}>
            <div className={styles.sidebar_filter_list_header}>
              <h3>{name}</h3>
              {checkSearch(type) ? (
                <SearchFocusIcon
                  className={styles.focus}
                  onClick={() => disableSearch(type)}
                />
              ) : (
                <SearchIcon
                  className={styles.focus}
                  onClick={() => enableSearch(type)}
                />
              )}
            </div>
            <RadioComponent
              title={''}
              value={selectedCriteria[type]}
              handleChange={(e) => handleRadio(e, type)}
              radioList={getReadioList(
                list?.[config.idFilter],
                list?.[config.nameFilter],
              )}
              default={selectedCriteria[type]}
            />
            {checkSearch(type) && (
              <SearchBoxComponent
                arrayList={getUniqueArray(column_values) || []}
                label={'Search'}
                onChange={onChange}
                title={column_name}
                name={name}
                type={type}
              />
            )}
            <CheckBoxComponent
              title={column_name}
              handleChange={handleCheckBoxChange}
              arrayList={getUniqueArray(column_values) || []}
              checkedArray={checkedArrayObject[column_name] || []}
              disabled={loading}
            />
          </div>
        </div>
      );
    }
  };

  const getList = (list) => {
    const { type } = list;
    const typeConfigList = Object.keys(TYPE_CONFIG);
    if (typeConfigList.includes(type)) return getFilterSection(list);
    return getCategory(list);
  };

  return (
    <div>
      <div
        style={
          !isSidebarOpen
            ? { position: 'absolute', left: '1%' }
            : { position: 'absolute', left: '18%' }
        }>
        {!isSidebarOpen ? (
          <KeyboardArrowRightIcon onClick={handleSidebar} />
        ) : (
          <KeyboardArrowLeftIcon onClick={handleSidebar} />
        )}
      </div>
      <div className={styles.sidebar_container}>
        <div className={styles.title_wrapper}>
          <h3 className={styles.filter_title}>Filters</h3>
          <Divider classes={{ root: classes.divider }} />
        </div>
        {/* <div className={styles.sidebar_title_wrapper}>
          <div className={styles.sidebar_title}>
            <div className={styles.sidebar_filter_list_header}>
              <h3>{'Rule'}</h3>
            </div>
            <SearchBox
              arrayList={arrayList}
              onChange={onSelectionChange}
              value={selectedRule}
            />
          </div>
        </div> */}
        {/* <Divider classes={{ root: classes.divider }} /> */}
        {filterList?.map((list) => getList(list))}
      </div>
    </div>
  );
};

export default Sidebar;
