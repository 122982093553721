import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import LoginProvider from '../LoginProvider/index';
import { DEFAULT_PATH } from '../../Routing/RouteConfig';
import { SSO_LOGIN_PATH } from '../../Utils/serviceUrls';
import styles from './styles.module.scss';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {
  const { initialised } = useSelector((state) => state.authReducer);
  /* Local State Managemnet */

  const navigate = useNavigate();

  const handleSubmit = () => {
    window.location.assign(SSO_LOGIN_PATH);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  useEffect(() => {
    if (initialised) {
      navigate(DEFAULT_PATH, { replace: true });
    }
  }, [initialised, navigate]);

  return (
    <LoginProvider>
      <div className={styles.login_form_container}>
        <LoginForm onSubmit={handleSubmit} isAuthLoading={initialised} />
      </div>
    </LoginProvider>
  );
}

export default Login;
