import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  RESOLVE_FAILURE_MSG,
  RESOLVE_WARNING_MSG,
  LOG_WARNING_MSG,
  SAME_VAL_WARNING,
  IS_WARNING_MSG,
} from './constants';
import GenericModal from '../../common/GenericModal';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/Snackbar/actions';
import { LogSubTaskUrl, getResolveAlertUrl } from '../../Utils/serviceUrls';
import { LogSubTask, ResolveAlert } from '../../Utils/endPoints';
import ISSuggestedActionTable from '../../common/ISSuggestedAction';
import SDSuggestedActionTable from '../../common/SDsuggestedAction';
import LastMileSuggestedActionTable from '../../common/LastMileSuggestedAction';
import DegradeServiceTable from '../../common/DegradeServiceTable';

const SuggestedActionComponent = ({
  data,
  taskInstanceId,
  showSDActionTaken,
  sliceResolvedAlertCard,
  selectedCardIssueType,
  handleDegradeService,
  handleCheckboxChange,
  handleSelectAllCheckbox,
  checkboxState,
  isAllChecked,
  degradeTicketResolved,
}) => {
  const dispatch = useDispatch();
  const [kmlStates, setKmlStates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [resolveModalTxt, setResolveModalTxt] = useState('');
  const [resolveSuccess, setResolveSuccess] = useState(true);
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [disabledLogs, setDisabledLogs] = useState(
    new Array(data.length).fill(false),
  );

  const [selectedDEType, setSelectedDEType] = useState('None');
  const [selectedDelay, setSelectedDelay] = useState('None');

  const serviceDegradationData =
    data?.value &&
    data?.value?.data &&
    data?.value?.data.length > 0 &&
    data?.value?.data[0].kmlId
      ? true
      : false;

  const inventoryShortfallData =
    data?.value &&
    data?.value?.data &&
    data?.value?.data.length > 0 &&
    data?.value?.data[0]?.actionRecommended
      ? true
      : false;

  const onDeTypeChange = (event, index, name) => {
    setSelectedDEType(event.target.value);
  };

  const handleDropdownChange = (
    event = {},
    rowIndex = -1,
    dropDownName = '',
  ) => {
    const value = event?.target?.value;
    if (rowIndex < 0) {
      return;
    }

    // Create a copy of the current state array
    const newStateArray = JSON.parse(JSON.stringify(kmlStates));

    // Find the index of the updated row
    const updatedIndex = newStateArray.findIndex(
      (obj) => obj.index === rowIndex,
    );

    // If the updated state object exists for the index, update it with the new dropdown value
    if (updatedIndex !== -1) {
      newStateArray[updatedIndex] = {
        ...newStateArray[updatedIndex],
        [dropDownName === 'Current State' ? 'currentState' : 'actionTaken']:
          value,
      };
    } else {
      // Create a new state object if it doesn't exist
      newStateArray.push({
        currentState: dropDownName === 'Current State' ? value : '',
        actionTaken: dropDownName === 'Action Taken' ? value : '',
        index: rowIndex,
      });
    }

    // Check if any dropdown value has changed for the current row
    const dropdownChanged = newStateArray.some(
      (obj) =>
        obj.index === rowIndex &&
        (obj.currentState !== '' || obj.actionTaken !== ''),
    );

    // Update log button state based on dropdown changes
    const updatedLogButtonDisabled = [...disabledLogs];

    // Enable the log button only if the dropdown has been changed for the same index
    if (dropdownChanged) {
      updatedLogButtonDisabled[rowIndex] = false;
    }

    setDisabledLogs(updatedLogButtonDisabled);
    // Update the state with the new array of dropdown states
    setKmlStates(newStateArray);
  };

  const handleDelayChange = (event) => {
    setSelectedDelay(event.target.value);
  };

  const logTheActionTakenAPI = (body, LogIndex, row) => {
    LogSubTask(LogSubTaskUrl(row?.subTaskInstanceId?.value), body)
      .then((res) => {
        if (res?.data?.status === 'COMPLETED') {
          const message = res?.data?.message;
          handleSuccess(message);

          const updatedDisabledLogs = [...disabledLogs];
          updatedDisabledLogs[LogIndex] = true;
          setDisabledLogs(updatedDisabledLogs);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleLogClick = (row, LogIndex, selectedDEType) => {
    if (selectedDEType) {
      const body = {
        status: 'COMPLETED',
        actionDetails: {
          actor: row?.actor?.value,
          actionRecommended: row?.actionRecommended?.value,
          deIssueType: selectedDEType,
        },
        user: authUserResponse?.email_id,
      };
      logTheActionTakenAPI(body, LogIndex, row);
    } else {
      if (!disabledLogs[LogIndex]) {
        // Check if kmlStates is an array
        if (Array.isArray(kmlStates)) {
          const stateObject = kmlStates.find(
            (state) => state.index === LogIndex,
          );
          if (
            stateObject &&
            stateObject.currentState &&
            stateObject.actionTaken &&
            stateObject.currentState !== '' &&
            stateObject.actionTaken !== ''
          ) {
            // Check if currentState and actionTaken are different
            if (stateObject.currentState !== stateObject.actionTaken) {
              const body = {
                status: 'COMPLETED',
                actionDetails: {
                  kmlId: row?.kmlId?.value,
                  currentState: stateObject.currentState,
                  actionTaken: stateObject.actionTaken,
                },
                user: authUserResponse?.email_id,
              };
              logTheActionTakenAPI(body, LogIndex, row);
            } else {
              // Show warning if currentState and actionTaken are the same
              setShowModal(true);
              setResolveModalTxt(SAME_VAL_WARNING);
              setResolveSuccess(false);
            }
          } else {
            // Show warning if currentState or actionTaken is missing or empty
            setShowModal(true);
            setResolveModalTxt(LOG_WARNING_MSG);
            setResolveSuccess(false);
          }
        } else {
          setShowModal(true);
          setResolveModalTxt(LOG_WARNING_MSG);
          setResolveSuccess(false);
        }
      }
    }
  };

  const handleConfirmClick = (row, index) => {
    const body = {
      status: 'COMPLETED',
      actionDetails: {
        actor: row?.actor?.value,
        actionRecommended: row?.actor?.confirm?.value,
        actionTaken: selectedDelay,
      },
      user: authUserResponse?.email_id,
    };

    LogSubTask(LogSubTaskUrl(row?.subTaskInstanceId?.value), body)
      .then((res) => {
        if (res?.data?.status === 'COMPLETED') {
          const message = res?.data?.message;
          handleSuccess(message);
          const updatedDisabledLogs = [...disabledLogs];
          updatedDisabledLogs[index] = true;
          setDisabledLogs(updatedDisabledLogs);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleResolveClick = () => {
    if (data?.value?.data.length && serviceDegradationData) {
      setShowModal(true);
      setResolveModalTxt(RESOLVE_WARNING_MSG);
      setResolveSuccess(false);
    } else if (data?.value?.data.length && inventoryShortfallData) {
      setShowModal(true);
      setResolveModalTxt(IS_WARNING_MSG);
      setResolveSuccess(false);
    } else {
      callResolveAPI();
    }
  };

  const onSuccessResolveOkClick = () => {
    sliceResolvedAlertCard();
    setShowModal(false);
    setSelectedDEType('None');
  };

  const callResolveAPI = () => {
    const id = taskInstanceId?.taskInstanceId?.value;
    setShowModal(false);
    setLoading(true);
    const body = {
      status: 'COMPLETED',
      actionDetails: {
        alertStatus: 'Resolved',
      },
      user: authUserResponse?.email_id,
    };
    ResolveAlert(getResolveAlertUrl(id), body)
      .then((res) => {
        if (res?.data?.status === 'COMPLETED') {
          setShowModal(true);
          setResolveModalTxt(res?.data?.message);
          setResolveSuccess(true);
          setLoading(false);
        } else {
          setShowModal(true);
          setResolveModalTxt(RESOLVE_FAILURE_MSG);
          setResolveSuccess(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.message || e,
      }),
    );
  };
  const handleSuccess = (msg) => {
    dispatch(
      addNotification({
        level: 'success',
        message: msg,
      }),
    );
  };

  const handleModalclose = () => {
    setShowModal(false);
  };

  // Check if at least one value is true
  const isAnyChecked =
    Array.isArray(checkboxState) &&
    checkboxState.some((value) => value === true);

  const isButtonEnabled = isAnyChecked;

  useEffect(() => {
    const initialDisabledLogs = Array(data.length).fill(false);
    setDisabledLogs(initialDisabledLogs);
  }, [data]);

  useEffect(() => {
    setKmlStates([]); //clearing kmlstates
  }, [data]);

  return (
    <div>
      <If
        condition={
          data &&
          Object.keys(data?.value).length > 0 &&
          data?.value?.message === undefined
        }>
        <div className={styles.suggestion_action_box}>
          <div className={styles.flex_css}>
            <div className={styles.suggestion_action_txt}>
              {data ? data?.description : ''}
            </div>
            <div>
              <If condition={data?.value?.resolve?.value?.isMultiAction}>
                <button
                  className={styles.degrade_service}
                  onClick={handleDegradeService}
                  disabled={!isButtonEnabled || degradeTicketResolved}>
                  {data?.value?.resolve
                    ? data?.value?.resolve?.description
                    : ''}
                </button>
                <Else />
                <button
                  className={styles.resolve_btn}
                  onClick={handleResolveClick}>
                  {data?.value?.resolve
                    ? data?.value?.resolve?.description
                    : ''}
                </button>
              </If>
            </div>
          </div>
          <GenericModal
            open={showModal}
            onClose={handleModalclose}
            title={resolveModalTxt}
            resolveSuccess={resolveSuccess}
            callResolveAPI={callResolveAPI}
            onSuccessResolveOkClick={onSuccessResolveOkClick}
          />
          <If condition={data?.value?.resolve?.value?.isMultiAction}>
            <If condition={!degradeTicketResolved}>
              <DegradeServiceTable
                data={data}
                handleCheckboxChange={handleCheckboxChange}
                handleSelectAllCheckbox={handleSelectAllCheckbox}
                checkboxState={checkboxState}
                isAllChecked={isAllChecked}
              />
              <Else />
              <span className={styles.resolved_alert_css}>
                This Ticket has been Resolved.
              </span>
            </If>

            <Else />
            <If condition={selectedCardIssueType === 'HUB_OPS'}>
              <SDSuggestedActionTable
                data={data}
                handleDropdownChange={handleDropdownChange}
                disabledLogs={disabledLogs}
                handleLogClick={handleLogClick}
              />
            </If>
          </If>

          <If condition={selectedCardIssueType === 'INVENTORY'}>
            <ISSuggestedActionTable
              data={data}
              handleConfirmClick={handleConfirmClick}
              disabledLogs={disabledLogs}
              handleDelayChange={handleDelayChange}
            />
          </If>
          <If condition={selectedCardIssueType === 'LAST_MILE'}>
            <LastMileSuggestedActionTable
              data={data}
              disabledLogs={disabledLogs}
              handleLogClick={handleLogClick}
              onDeTypeChange={onDeTypeChange}
              selectedDEType={selectedDEType}
            />
          </If>
        </div>
        <Else />
        {data &&
        Object.keys(data).length > 0 &&
        data.value &&
        Object.keys(data.value).length > 0 &&
        data.value.message &&
        data.value.message.length > 0 ? (
          <div className={styles.suggestion_action_box}>
            <span className={styles.resolved_alert_css}>
              This Ticket has been Resolved.
            </span>
          </div>
        ) : (
          <div></div>
        )}
        <Else />
        <div />
      </If>
    </div>
  );
};

export default SuggestedActionComponent;
