import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  indeterminateColor: {
    [`& .MuiSvgIcon-root`]: {
      color: '#868E96',
      fontSize: '20px',
    },
    [`&.Mui-checked .MuiSvgIcon-root`]: {
      color: '#C92A2A',
    },
  },
  label: {
    fontSize: '14px',
  },
  root: {
    [`& .MuiInputBase-input`]: {
      fontSize: '14px',
    },
    [`& .MuiInputBase-root `]: {
      paddingLeft: '22px',
    },
    [`& fieldset`]: {
      height: '60px',
      borderRadius: '28px',
      [`& legend`]: {
        fontSize: '12px',
      },
    },
    [`& .MuiInputLabel-root `]: {
      fontSize: '14px',
      color: '#ADB5BD',
    },
    [`& .MuiInputLabel-root.Mui-focused`]: {
      fontSize: '12px',
      color: '#ADB5BD',
      transform: 'translate(14px, -9px) scale(1)',
    },
    [`& .Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      borderColor: '#CED4DA',
    },
    [`& .MuiAutocomplete-endAdornment`]: {
      right: '10px',
      paddingTop: '5px',
      fontSize: '20px',
    },
  },
  input: {
    fontSize: 14,
    paddingLeft: '22px',
  },

  option: {
    fontSize: 14,
  },
  listbox: {
    height: '250px',
  },
});
