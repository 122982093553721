import {
  DEFAULT_HLD_PATH,
  DEFAULT_PATH,
  LOGIN_PATH,
} from '../../Routing/RouteConfig';
import StorageService from '../../Utils/storageService';
import {
  REDIRECT_URL_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from '../LoginProvider/constants';
import AuthProviderUtils from '../LoginProvider/utils';

function handleRedirectFromSSO(token, tokenStatus) {
  const success = tokenStatus ? tokenStatus === 'true' : false;
  if (token && success) {
    StorageService.setValue(USER_TOKEN_STORAGE_KEY, token);
    const redirectTo =
      StorageService.getValue(REDIRECT_URL_STORAGE_KEY) || DEFAULT_PATH;
    StorageService.removeKey(REDIRECT_URL_STORAGE_KEY);
    if (redirectTo === LOGIN_PATH) return DEFAULT_PATH;
    return redirectTo;
  } else {
    throw new Error();
  }
}

//NOTE:Commenting this func as authentication for breach-management is not done as of now.
// async function handleRedirectFromSSO(token, tokenStatus) {
//   const success = tokenStatus ? tokenStatus === 'true' : false;

//   if (token && success) {
//     StorageService.setValue(USER_TOKEN_STORAGE_KEY, token);
//     const redirectTo =
//       StorageService.getValue(REDIRECT_URL_STORAGE_KEY) || DEFAULT_PATH;
//     StorageService.removeKey(REDIRECT_URL_STORAGE_KEY);
//     const data = await AuthProviderUtils.verifyToken(token);
//     let filteredRoles = data?.claims?.roles?.filter(function (str) {
//       return str === 'EVENT_CONFIG_UI';
//     });

//     if (redirectTo === LOGIN_PATH) {
//       if (filteredRoles?.[0] === 'EVENT_CONFIG_UI') {
//         return DEFAULT_HLD_PATH;
//       } else {
//         return DEFAULT_PATH;
//       }
//     }
//     return redirectTo;
//   } else {
//     throw new Error();
//   }
// }

const SSORedirectUtils = {
  handleRedirectFromSSO,
};

export const accessibilityKey = (event) => {
  const BUTTON_PRESS_KEYS = ['Enter', 'Spacebar', ' '];
  return BUTTON_PRESS_KEYS.includes(event.key);
};

export default SSORedirectUtils;
