import axios from 'axios';
import { USER_TOKEN_STORAGE_KEY } from '../pageComponents/LoginProvider/constants';
import StorageService from './storageService';
export const BASE_URL = process.env.BACKEND_BASE_URL;

//need to get it from the API team
let client;
const init = (url, headers = {}) => {
  client = axios.create({
    baseURL: url,
    headers: {
      token: 'token',
      ...headers,
    },
  });
};
const tokenInit = (url, token, headers = {}) => {
  client = axios.create({
    baseURL: url,
    headers: {
      token: token,
      ...headers,
    },
  });
};

/*generic method to call APIs*/
export const apiCall = (method, url, body = null, headers = null) => {
  // change to this USER_TOKEN_STORAGE_KEY
  let token = StorageService.getValue(USER_TOKEN_STORAGE_KEY);
  if (token) tokenInit(url, token, headers);
  else init(url, headers);
  const parseResponse = (response) => response.data;
  const onError = (error) => {
    if (error.message === 'Network Error') {
      // The user doesn't have internet
      return Promise.reject(error);
    }
    try {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
          window.location.reload();
          localStorage.clear();
          break;
        case 404:
          // Show 404 page
          break;
        case 500:
          // Serveur Error redirect to 500
          break;
        default:
          // Unknow Error
          break;
      }
    } catch (e) {
      console.error('Server Down please try after sometime');
    }
    return Promise.reject(error.response || error.message);
  };
  return client({
    method,
    url,
    data: body,
  })
    .then(parseResponse)
    .catch(onError);
};
