const TIME_FORMAT = '';
const DATE_FORMAT = '';
const FULL_DATE_FORMAT = '';
const FOCUS_TO_SEARCH_PARAM = 'focusTo';
const FOCUS_TO_TIMEOUT = 200;
const FOCUS_TO_TOP_OFFSET = 100;
const CONTROL_TOWER_BASE_URL = process.env.CONTROL_TOWER_BASE_URL;
const CEP_BASE_URL = process.env.CEP_BASE_URL;
const AUTH_SERVICE_CLIENT_ID = process.env.AUTH_SERVICE_CLIENT_ID;
const AUTH_SERVICE_BASE_URL = process.env.AUTH_SERVICE_BASE_URL;
const env = process.env.NODE_ENV;
const CENTRAL_CITY_CEP_BASE_URL = process.env.CENTRAL_CITY_CEP_BASE_URL;
const SERVICEABILITY_BASE_URL = process.env.SERVICEABILITY_BASE_URL;

let AUTH_SERVICE_REDIRECT_URL = window.location.origin;
if (env === 'development') {
  AUTH_SERVICE_REDIRECT_URL = process.env.AUTH_SERVICE_REDIRECT_URL;
}

export {
  AUTH_SERVICE_REDIRECT_URL,
  TIME_FORMAT,
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  FOCUS_TO_SEARCH_PARAM,
  FOCUS_TO_TIMEOUT,
  FOCUS_TO_TOP_OFFSET,
  AUTH_SERVICE_CLIENT_ID,
  AUTH_SERVICE_BASE_URL,
  CONTROL_TOWER_BASE_URL,
  CEP_BASE_URL,
  CENTRAL_CITY_CEP_BASE_URL,
  SERVICEABILITY_BASE_URL,
};
