import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import './CustomiseModal.css';
import { useStyles } from './CustomiseModalStyle';
import CheckboxCheckedIcon from '../../assets/checkbox_checked_icon.svg';
import CheckboxUnCheckedIcon from '../../assets/checkbox_unchecked_icon.svg';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const CustomiseModal = ({
  allColumns,
  visible,
  applyColumns,
  handleCancel,
  checkedColumns,
}) => {
  const classes = useStyles();

  const [checkedRows, setCheckedrows] = useState(
    checkedColumns.map((column) => {
      return column.key;
    }),
  );
  const handleChangeCustomiseCheckBox = (event) => {
    const { name, value } = event.target;
    let newArray = [...checkedRows, value];
    if (checkedRows.includes(value)) {
      newArray = newArray.filter((column) => column !== value);
    }
    setCheckedrows(newArray);
  };

  const applyFilter = () => {
    handleCancel();
    applyColumns(checkedRows);
  };

  const handleReset = () => {
    let array = allColumns.map((column) => column.key);
    setCheckedrows(array);
    applyColumns(array);
    handleCancel();
  };

  const isChecked = (columnId) => checkedRows.includes(columnId);

  return (
    <>
      <Dialog
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={visible}
        classes={{ container: classes.dialogContainer }}>
        <DialogTitle>
          <div className="headerCustomiseContainer">
            <div className="headerCustomiseTitle">{'Add/Remove Columns'}</div>
            <div className="headerCustomiseRightMenu">
              <div
                className="headerCustomiseReset"
                onClick={handleReset}
                aria-hidden="true">
                Reset
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="parentOneCustomiseContainer">
            <div className="childOneCustomiseContainer">Available Columns</div>
          </div>
          <div className="parentTwoCustomiseContainer">
            <div className="childTwoCustomiseContainer">
              {allColumns &&
                allColumns.map((column) => {
                  return (
                    <div className="childTwoCustomiseCheckbox" key={column.key}>
                      <label className="customise-label">
                        <Checkbox
                          type="checkbox"
                          name={column.title}
                          value={column.key}
                          id={column.key}
                          onChange={handleChangeCustomiseCheckBox}
                          checked={isChecked(column.key)}
                          icon={<CheckboxUnCheckedIcon />}
                          checkedIcon={<CheckboxCheckedIcon />}
                          classes={{ root: classes.checkbox_root }}
                        />

                        <div className="columnTitle">{column.title}</div>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="customiseCancelButton"
            key="back"
            onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="customiseApplyButton"
            key="submit"
            type="primary"
            onClick={applyFilter}>
            Apply
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomiseModal;
