import style from './style.module.scss';
import cx from 'classnames';

const LsTabCard = ({ children, className, selected, ...rest }) => {
  return (
    <div
      role="tab"
      tabIndex={0}
      className={cx(style.card, className, { [style.selected]: selected })}
      {...rest}>
      {children}
    </div>
  );
};

export default LsTabCard;
