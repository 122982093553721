import React, { useEffect, useRef, useState } from 'react';
import TabCardList from '../../common/TabCardList';
import styles from './styles.module.scss';
import cx from 'classnames';
import TicketIcon from '../../assets/ticketIcon.svg';
import ScrollIcon from '../../assets/scrollIcon.svg';

const CardComponent = ({
  cardData,
  cardNames,
  accessibilityKey,
  handleTabSelect,
  selectedTab,
  customStyle,
}) => {
  const [finalCardData, setFinalCardData] = useState([]);
  const cardRef = useRef(null);

  const transformCardDetails = () => {
    const mergedArray = cardNames.map((item1) => {
      const matchingItem = cardData.find((item2) => item2.name === item1.name);
      if (matchingItem) {
        return {
          ...item1,
          ...(matchingItem && { data: matchingItem.data }),
          columns: [
            {
              ...item1.columns[0],
            },
          ],
        };
      }
      return item1;
    });
    setFinalCardData(mergedArray);
  };

  const handleScrollRight = () => {
    if (cardRef.current) {
      cardRef.current.scrollBy({
        behavior: 'smooth',
        left: 500,
      });
    }
  };

  useEffect(() => {
    transformCardDetails();
  }, [cardData]);

  return (
    <div style={{ overflow: 'auto', whiteSpace: 'nowrap' }} ref={cardRef}>
      <div className={styles.tab_card_container}>
        <div>
          <TabCardList
            className={styles.delivery_status_tabs}
            data-testid="dm-tab-card-list">
            <For of={finalCardData} each="tab">
              <TabCardList.TabCard
                key={tab.name}
                onClick={() => handleTabSelect(tab.name)}
                onKeyUp={(e) => {
                  if (accessibilityKey(e)) {
                    handleTabSelect(tab.name);
                  }
                }}
                className={cx(styles.tab, 'display-column-center')}
                selected={selectedTab === tab.name}>
                <div className={styles.card_details}>
                  <div className={styles.label_icon_container}>
                    <TicketIcon />
                    <TabCardList.TabCardLabel className={customStyle}>
                      {tab?.description}
                    </TabCardList.TabCardLabel>
                    <TabCardList.TabCardValue>
                      {tab?.data?.totalAlertCount}
                    </TabCardList.TabCardValue>
                  </div>
                </div>
              </TabCardList.TabCard>
            </For>
          </TabCardList>
        </div>
      </div>
      <div className={styles.scroll_icon_css}>
        <ScrollIcon onClick={handleScrollRight} />
      </div>
    </div>
  );
};

export default CardComponent;
