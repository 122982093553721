import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';

import styles from './styles.module.scss';
import { getColorClass } from './utils';

function PercentageCell({ column, value }) {
  const { key } = column || {};
  const percentageValue = useMemo(() => {
    const floatVal = parseFloat(value);
    return isNaN(floatVal) ? 0 : floatVal;
  }, [value]);

  const colorClass = useMemo(() => {
    return getColorClass(key, percentageValue);
  }, [percentageValue, key]);

  return (
    <TableCell
      key={column?.key}
      className={classNames(column.style, colorClass, styles.centerAlign)}>
      <div>{percentageValue} %</div>
    </TableCell>
  );
}

export default PercentageCell;
