import React from 'react';
import styles from './style.module.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DetailsComponent from './detailsComponent';

export default function BreachDetailsComponent({
  totalBreachesRowData,
  manuallyResolvedRowData,
  setDateFilter,
  date,
}) {
  let totalBreachesDisplayName = null;
  const totalBreachesFilteredData = Array.isArray(totalBreachesRowData)
    ? totalBreachesRowData.filter((item) => {
        if (item.fieldName === 'Total Breaches') {
          totalBreachesDisplayName = item.fieldName;
          return false;
        }
        return true;
      })
    : [];

  const totalBreachesVal =
    totalBreachesRowData && Object.keys(totalBreachesRowData).length
      ? totalBreachesRowData.find(
          (breach) => breach.fieldName === 'Total Breaches',
        )?.fieldValue
      : '-';

  const manuallyResolved =
    manuallyResolvedRowData && Object.keys(manuallyResolvedRowData).length
      ? manuallyResolvedRowData.find(
          (breach) => breach.fieldName === 'Manual Resolved',
        )?.fieldValue
      : '-';

  const avgTAT =
    manuallyResolvedRowData && Object.keys(manuallyResolvedRowData).length
      ? manuallyResolvedRowData.find((breach) => breach.fieldName === 'Avg TAT')
          ?.fieldValue
      : '-';

  let ManualResolvedDisplayName = null;
  let AvgTATDisplayName = null;

  const manuallyResolvedRowFilteredData = Array.isArray(manuallyResolvedRowData)
    ? manuallyResolvedRowData.filter((item) => {
        if (item.fieldName === 'Manual Resolved') {
          ManualResolvedDisplayName = item ? item?.fieldName : '-';
          return false;
        } else if (item.fieldName === 'Avg TAT') {
          AvgTATDisplayName = item ? item?.fieldName : '-';
          return false;
        }
        return true;
      })
    : [];

  return (
    <div className={styles.bm_main_container}>
      <div className={styles.breach_details}>
        <div className={styles.left_block}>
          <div className={styles.breach_key_css}>
            {totalBreachesDisplayName}
          </div>
          <div className={styles.total_breaches}>{totalBreachesVal}</div>
        </div>

        <div className={styles.center_blocks}>
          <DetailsComponent data={totalBreachesFilteredData} />
        </div>

        <div className={styles.right_block}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // label="Controlled picker"
              value={date ? dayjs(date) : null}
              onChange={(newValue) => setDateFilter(newValue)}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className={styles.content_divider} />
      <div className={styles.breach_details}>
        <div className={styles.left_block}>
          <div className={styles.breach_key_css}>
            {ManualResolvedDisplayName}
          </div>
          <div className={styles.positive_breaches}>{manuallyResolved}</div>
        </div>

        <div className={styles.center_blocks}>
          <DetailsComponent data={manuallyResolvedRowFilteredData} />
        </div>

        <div className={styles.right_block}>
          <div className={styles.breach_key_css}>{AvgTATDisplayName}</div>
          <div className={styles.positive_breaches}>{avgTAT} mins</div>
        </div>
      </div>
    </div>
  );
}
