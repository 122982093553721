import React from 'react';
import styles from './styles.module.scss';

const IssueDetailComponent = ({ data }) => {
  return (
    <>
      <If condition={data && Object.keys(data).length > 0}>
        <div className={styles.card_txt_align}>
          <div className={styles.detail_content}>
            {Object.keys(data).map((item, index) => (
              <>
                <div key={index}>
                  <div className={styles.content_key}>
                    {data[item].description}
                  </div>
                  <div className={styles.content_value}>
                    {/* Check if the value is present and if it's a number */}
                    <If condition={data[item].description === 'TRIGGER SLA'}>
                      {parseFloat(data[item].value).toFixed(2)}%
                      <Else />
                      {data[item] ? (
                        <div className={styles.content_value}>
                          {data[item].value}
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </If>
                  </div>
                </div>
                <div className={styles.content_divider}></div>
              </>
            ))}
          </div>
          {data?.deliveryDetails && (
            <div>
              <div className={styles.content_key_de}>DE</div>
              <div className={styles.content_value}>
                {data?.deliveryAgentName?.value
                  ? data?.deliveryAgentName?.value
                  : '-'}
              </div>
              <div className={styles.content_value}>
                {data?.deliveryAgentContact?.value
                  ? data?.deliveryAgentContact?.value
                  : '-'}
              </div>
            </div>
          )}
        </div>
        <Else />
        No Data Available!
      </If>
    </>
  );
};

export default IssueDetailComponent;
