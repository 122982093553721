import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_NOTIFICATION,
} from './constants';

export function addNotification({ level, message }) {
  return {
    type: ADD_NOTIFICATION,
    payload: { level, message },
  };
}

export function apiError(message) {
  return {
    type: ADD_NOTIFICATION,
    payload: { level: 'error', message },
  };
}
const getNotifications = (id, list) => {
  if (!id) return list;
  const index = list.findIndex((each) => each.id === id);
  if (index === -1) return list;
  return list.splice(index, 1);
};

export function removeNotification(id, list) {
  let notifications = getNotifications(id, list);
  return {
    type: REMOVE_NOTIFICATION,
    payload: notifications,
  };
}

export function resetNotification(payload) {
  return {
    type: RESET_NOTIFICATION,
    payload,
  };
}
