import AppWrapper from '../pageComponents/AppWrapper';
import Login from '../pageComponents/Login';
import SSORedirect from '../pageComponents/SSORedirect';
import RuleInformationList from '../pageComponents/RuleInformationPage/RuleInformationList';
import { Navigate } from 'react-router-dom';
import LiveDashboardComponent from '../pageComponents/LiveDashboardPage';
import JobConfigList from '../pageComponents/JobInformationPage/JobConfigList';
import BreachManagementDashboard from '../pageComponents/BrechManagementDasboardTab';
import ControlTowerMainPage from '../pageComponents/ControlTowerMainPage';
import DailyTrendsComponent from '../pageComponents/DailyTrends';

const ROOT = '/';
export const DEFAULT_PATH = '/generic-rule-dashboard';
export const LOGIN_PATH = '/login';
export const SSO_REDIRECT = '/sso/redirect';
export const SLA_PATH = '/sla';
export const CEP_PATH = '/cep';
export const DEFAULT_HLD_PATH = '/breach-management';
export const BREACH_MANAGEMENT_DASHBOARD = `${DEFAULT_HLD_PATH}/dashboard`;
export const DAILY_TRENDS = `${DEFAULT_HLD_PATH}/daily-trends`;

export const ApplicationRoutes = [
  { path: SSO_REDIRECT, element: <SSORedirect /> },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: ROOT,
    element: <AppWrapper />,
    children: [
      {
        index: true,
        element: <Navigate to={SLA_PATH} replace />,
      },
      {
        path: DEFAULT_PATH,
        element: <RuleInformationList />,
      },
      {
        path: DEFAULT_HLD_PATH,
        element: <ControlTowerMainPage />,
        children: [
          {
            index: true,
            element: <Navigate to={BREACH_MANAGEMENT_DASHBOARD} replace />,
          },
          {
            path: BREACH_MANAGEMENT_DASHBOARD,
            element: <BreachManagementDashboard />,
          },
          {
            path: DAILY_TRENDS,
            element: <DailyTrendsComponent />,
          },
        ],
      },

      {
        path: SLA_PATH,
        element: <LiveDashboardComponent />,
      },
      {
        path: CEP_PATH,
        element: <JobConfigList />,
      },
    ],
  },
];
