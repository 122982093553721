import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './style.module.scss';
import { FormControl, MenuItem, Select } from '@mui/material';
import TimePicker from '../TimePicker';

export default function DegradeServiceDialog({
  open,
  onCloseDegradeServiceModal,
  onSaveDegradeServiceModal,
  handleExtendDegradation,
  handleDegradationReasonSelection,
  extendOfDegradation,
  selectedExtendOfDegradation,
  reasonsOfDegradation,
  selectedReasonOfDegradation,
  hour,
  minute,
  period,
  setHour,
  setMinute,
  setPeriod,
  disableDegradeModalSave,
}) {
  if (!open) {
    return null;
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onCloseDegradeServiceModal}
        PaperProps={{
          className: styles.custom_dialog, // Apply custom class here
        }}>
        <DialogTitle className={styles.modal_heading}>
          Degradation Details
        </DialogTitle>
        <DialogContent className={styles.dialog}>
          <div>
            <DialogContentText className={styles.modal_sub_heading}>
              Extend of Degradation
            </DialogContentText>
            <FormControl className={styles.select_width}>
              <Select
                classes={{ select: styles.custom_select }}
                value={selectedExtendOfDegradation}
                name="extendDegradationDropdown"
                onChange={handleExtendDegradation}>
                {extendOfDegradation.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.time_slect_container}>
            <TimePicker
              hour={hour}
              minute={minute}
              period={period}
              setHour={setHour}
              setMinute={setMinute}
              setPeriod={setPeriod}
            />
            <div>
              <DialogContentText className={styles.reason_dropdown_name}>
                Degradation reason
              </DialogContentText>
              <FormControl className={styles.reason_select_width}>
                <Select
                  classes={{ select: styles.custom_select }}
                  value={selectedReasonOfDegradation}
                  onChange={handleDegradationReasonSelection}
                  name="reasonOfDegradationDropdown">
                  {reasonsOfDegradation &&
                    reasonsOfDegradation.map((option, index) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>

        <div className={styles.modal_footer}>
          <Button
            className={styles.cancel_btn_css}
            onClick={onCloseDegradeServiceModal}>
            Cancel
          </Button>
          <Button
            className={styles.next_btn_css}
            type="submit"
            onClick={onSaveDegradeServiceModal}
            disabled={disableDegradeModalSave}>
            Save
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
