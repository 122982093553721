import { createReducer } from '../../Utils/createReducer';
import {
  CLEAR_TOKEN,
  VERIFY_TOKEN_COMPLETE,
  VERIFY_TOKEN,
} from '../saga/UserReducer/constants';

const INITIAL_STATE = {
  initialised: false,
  tokenStatus: false,
  token: null,
  user: null,
};

const UserReducer = createReducer(INITIAL_STATE, {
  [VERIFY_TOKEN]: (state, action) => ({
    ...state,
    initialised: false,
    tokenStatus: false,
    token: null,
    user: null,
  }),
  [VERIFY_TOKEN_COMPLETE]: (state, action) => ({
    ...state,
    authUserResponse: action?.payload?.user,
    initialised: true,
  }),
  [CLEAR_TOKEN]: (state) => {
    return {
      ...state,
      initialised: false,
      tokenStatus: false,
      token: null,
      user: null,
    };
  },
});

export default UserReducer;
