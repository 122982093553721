import style from './style.module.scss';
import cx from 'classnames';

const LsCardLabel = ({ children, className, ...rest }) => {
  return (
    <span
      data-testid={rest['data-testid']}
      className={cx(style.card_label, className)}>
      {children}
    </span>
  );
};

export default LsCardLabel;
