import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styles from '../../pageComponents/BrechManagementDasboardTab/styles.module.scss';

const ISSuggestedActionTable = ({
  data,
  handleConfirmClick,
  disabledLogs,
  handleDelayChange,
}) => {
  if (
    !data ||
    !data.value ||
    !data.value.data ||
    data.value.data.length === 0
  ) {
    return <div>No data available</div>;
  }

  const tableHeaders = Object.keys(data?.value?.data[0]);

  return (
    <>
      <TableContainer className={styles.suggested_action_table_container}>
        <Table>
          <TableHead className={styles.suggested_action_table_header}>
            <TableRow>
              {tableHeaders
                .filter(
                  (header) =>
                    header !== 'subTaskInstanceId' && header !== 'confirm',
                )
                .map((header, index) => (
                  <TableCell
                    key={index}
                    className={styles.suggested_action_header}>
                    {header}
                  </TableCell>
                ))}
              <TableCell className={styles.suggested_action_header}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.value?.data.map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                {tableHeaders
                  .filter(
                    (header) =>
                      header !== 'subTaskInstanceId' && header !== 'confirm',
                  )
                  .map((header, colIndex) => (
                    <TableCell
                      key={colIndex}
                      className={styles.suggested_action_row}>
                      {typeof rowData[header]?.value === 'object' &&
                      rowData[header]?.value !== null ? (
                        Array.isArray(rowData[header]?.value?.values) ? (
                          <select
                            className={styles.select_css}
                            style={{ width: '150px' }}
                            onChange={(event) => handleDelayChange(event)}>
                            <option value="None">None</option>
                            {rowData[header]?.value?.values.map(
                              (option, optIndex) => (
                                <option key={optIndex} value={option}>
                                  {option}
                                </option>
                              ),
                            )}
                          </select>
                        ) : (
                          <div>{JSON.stringify(rowData[header]?.value)}</div>
                        )
                      ) : (
                        <div>{rowData[header]?.value ?? '-'}</div>
                      )}
                    </TableCell>
                  ))}
                <TableCell
                  key="actionButton"
                  className={styles.suggested_action_row}>
                  {rowData['confirm']?.value?.type === 'actionButton' ? (
                    <button
                      disabled={disabledLogs[rowIndex]}
                      className={
                        disabledLogs[rowIndex]
                          ? styles.diabled_suggested_action_confirm_btn
                          : styles.suggested_action_confirm_btn
                      }
                      onClick={() => handleConfirmClick(rowData, rowIndex)}>
                      {rowData['confirm']?.description || 'Button'}
                    </button>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ISSuggestedActionTable;
