import ReactDOM from 'react-dom';
import { App } from './App/App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/index';
import { StyledEngineProvider } from '@mui/material';
import Notification from './common/Notification';

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Notification />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
);
