import { Divider } from '@mui/material';
import React from 'react';
import styles from './style.module.scss';
import GreySqIcon from '../../assets/grey_sq.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const ToggleComponent = ({
  handleCityWiseClick,
  handleHubWiseClick,
  hubWiseClicked,
  cityWiseClicked,
  handleGraphDateFilter,
  identifier,
  graphDateFilter1,
  graphDateFilter2,
}) => {
  return (
    <div className={styles.first_block_top_card}>
      <div className={styles.gc_flex_property}>
        <div className={styles.city_hub_wise_block}>
          <div className={styles.gc_flex_property}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={handleCityWiseClick}
              className={
                cityWiseClicked
                  ? styles.city_wise_block_clicked
                  : styles.city_wise_block
              }
              onClick={handleCityWiseClick}>
              <div className={styles.block_padding}>
                <span className={styles.icon_padding}>
                  <GreySqIcon />
                </span>
                <span className={styles.block_txt}>City-Wise</span>
              </div>
            </div>
            <div style={{ backgroundColor: '#C0C1C3' }}>
              <Divider orientation="vertical" />
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={handleHubWiseClick}
              className={
                hubWiseClicked
                  ? styles.hub_wise_block_clicked
                  : styles.hub_wise_block
              }
              onClick={handleHubWiseClick}>
              <div className={styles.block_padding}>
                <span className={styles.icon_padding}>
                  <GreySqIcon />
                </span>
                <span className={styles.block_txt}>Hub-Wise</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_block}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={
                identifier === 'leftBlock' ? graphDateFilter1 : graphDateFilter2
              }
              onChange={(newValue) =>
                handleGraphDateFilter(newValue, identifier)
              }
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};
