import { TYPE_CONFIG } from '../../common/SidebarComponent/constants';

const getColumnNameByType = (config) => {
  const { type } = config || {};
  const typeConfig = TYPE_CONFIG[type];
  if (!typeConfig) return '';
  const { nameFilter } = typeConfig;
  return config[nameFilter]?.column_name || '';
};

const getFilterColumnValues = (appliedFilters, tabConfig) => {
  const { base_filter_details } = tabConfig || {};
  const filterConfigMap = base_filter_details.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr,
    }),
    {},
  );
  return Object.keys(appliedFilters)
    .map((each) => ({
      type: 'IN',
      column_name: getColumnNameByType(filterConfigMap[each]),
      column_values: appliedFilters[each] || [],
    }))
    .filter((each) => each.column_name && each.column_values.length);
};

const getQueryCondition = (appliedFilters, tab) => {
  const queryConditionColumnValues = getFilterColumnValues(appliedFilters, tab);
  if (!queryConditionColumnValues.length) return undefined;
  return {
    type: 'LOGICAL_AND',
    // column_name: '',
    column_values: queryConditionColumnValues,
  };
};

export const getSlaViewPageApiBody = (
  tab,
  activePage = 0,
  activePageSize = 50,
  sortBy = '',
  sortDir = 'ASC',
  appliedFilters = {},
) => {
  const body = {
    rule_id: tab.ruleId,
    rule_output_query_requests: [
      {
        database_name: tab?.database_name,
        table_name: tab?.table_name,
        page_detail: {
          page_no: activePage,
          page_size: activePageSize,
        },
        query_condition: getQueryCondition(appliedFilters, tab),
      },
    ],
  };
  if (sortBy) {
    body.rule_output_query_requests[0].sort_details = [
      {
        sort_field: sortBy,
        sort_order: sortDir,
      },
    ];
  }
  return body;
};

function generateFilterOptions(options) {
  return options.map((each) => ({ label: each, value: each }));
}

export const getAvailableFilters = (filter, cityHubList) => {
  const { type, city_name_filter, hub_name_filter } = filter;
  switch (type) {
    case 'CITY':
      return {
        type,
        options: generateFilterOptions(city_name_filter?.column_values || []),
      };
    case 'HUB':
      return {
        type,
        options: cityHubList
          ? cityHubList
          : generateFilterOptions(hub_name_filter?.column_values || []),
      };
    default:
      return [];
  }
};
