import { legacy_createStore, applyMiddleware } from 'redux';
import createSageMiddleware from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './saga/sagas';

const sagaMiddleware = createSageMiddleware();
let middlewares = [sagaMiddleware];
export const store = legacy_createStore(
  rootReducer,
  applyMiddleware(...middlewares),
);
sagaMiddleware.run(rootSaga);
