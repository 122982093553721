import React from 'react';
import styles from './style.module.scss'; // Adjust the path as needed
import BrownSqIcon from '../../assets/brown_sq_icon.svg';
import MustardSqIcon from '../../assets/mustard_sq_icon.svg';
import YellowSqIcon from '../../assets/yellow_sq_icon.svg';

const DetailsComponent = ({ data = [] }) => {
  const iconMapping = {
    High: <BrownSqIcon />,
    Medium: <MustardSqIcon />,
    Low: <YellowSqIcon />,
  };

  return (
    <div style={{ display: 'flex' }}>
      {data.map((item, index) => (
        <React.Fragment key={item.fieldName}>
          <div style={{ display: 'flex' }}>
            <div style={{ padding: '0px 24px 0px 24px' }}>
              <div className={styles.breach_key_css}>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '7px' }}>
                    {iconMapping[item.fieldName]}
                  </div>
                  <div>{`${item.fieldName}`}</div>
                </div>
              </div>
              <div className={styles.breach_value_css}>{item.fieldValue}</div>
            </div>
            {index < data.length - 1 && (
              <div className={styles.vertical_divider}></div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DetailsComponent;
