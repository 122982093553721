import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { clearToken } from '../../redux/saga/UserReducer/actions.js';
import LiciousLogo from '../../assets/licious_logo.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styles from './styles.module.scss';
import ProfileIcon from '../../assets/profile_icon.svg';
import DownArrowIcon from '../../assets/down_arrow_icon.svg';
import Typography from '@mui/material/Typography';
import {
  DEFAULT_PATH,
  SLA_PATH,
  CEP_PATH,
  DEFAULT_HLD_PATH,
  DAILY_TRENDS,
} from '../../Routing/RouteConfig.js';
import Tabs from '../../common/TabsComponent/TabsComponent';
import ControlTowerHeader from '../../common/controlTowerHeader';
import { Button } from '@mui/material';

const headerData = [
  {
    id: 1,
    label: 'Rule Dashboard',
    route: DEFAULT_PATH,
  },
  {
    id: 2,
    label: 'SLA',
    route: SLA_PATH,
  },
  {
    id: 2,
    label: 'CEP',
    route: CEP_PATH,
  },
];

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUserResponse } = useSelector((state) => state.authReducer);
  const name = authUserResponse?.email_id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = useState(0);
  const id = open ? 'simple-popper' : undefined;
  const { pathname } = useLocation();

  //Temprory fix as authentication for central city is not implemented from BE.
  const showTabs = () => {
    if (pathname.includes(DEFAULT_HLD_PATH)) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event, newValue) => {
    let path;
    if (newValue === 0) {
      path = DEFAULT_PATH;
    } else if (newValue === 1) {
      path = SLA_PATH;
    } else path = CEP_PATH;
    navigate(path);
  };
  useEffect(() => {
    let activeTab = getActiveTab();
    setActiveTab(activeTab);
  }, [pathname]);

  const getRoutes = () => {
    return pathname?.split('/')[1];
  };
  const getActiveTab = () => {
    let routes = getRoutes();
    switch (routes) {
      case 'generic-rule-dashboard':
        return 0;
      case 'sla':
        return 1;
      case 'cep':
        return 2;
      default:
        return 0;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(clearToken());
  };
  const getTabTitle = (tab) => {
    if (tab?.label) return tab.label;
  };
  const handleNavigate = (home) => {
    if (home === 'home') return navigate(DEFAULT_PATH);
  };
  const handleNavigationToBM = () => {
    return navigate(DAILY_TRENDS);
  };

  return (
    <>
      <If condition={showTabs()}>
        <header>
          <div className={styles.header_container}>
            <div className={styles.logo_container}>
              <img
                aria-hidden="true"
                src={LiciousLogo}
                alt="Licious Logo"
                onClick={() => handleNavigate('home')}
              />
            </div>
            <Box sx={{ flex: '1', width: 'inherit', margin: '10px' }}>
              {/* <If condition={showTabs()}> */}
              <Tabs
                tabValue={activeTab}
                handleChange={handleChange}
                tabList={headerData}
                getTabTitle={getTabTitle}
                centered={true}
                standard={true}
              />
              {/* </If> */}
            </Box>
            <div className={styles.user_container}>
              {/* <Link
              style={{ cursor: 'pointer' }}
              underline="hover"
              sx={{ color: '#1C7ED6' }}
              onClick={() => handleNavigate()}>
              {' '}
              go to Live Tracking
            </Link> */}
              {/* <NotificationWrapper>
          <div className="notification_icon">
            <NotificationIcon />
          </div>
        </NotificationWrapper> */}
              <Button
                className={styles.bm_btn}
                variant="contained"
                children="Apply"
                onClick={handleNavigationToBM}>
                BM Dashboard
              </Button>
              <div
                onClick={handleClick}
                className={styles.user_wrapper}
                aria-hidden="true">
                <div className={styles.profile_icon}>{<ProfileIcon />}</div>
                <p>{name || 'NO USER'}</p>
                <DownArrowIcon />
              </div>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                className={styles.popper_display}>
                <ClickAwayListener onClickAway={() => handleClose()}>
                  <div className={styles.logout_popper_container}>
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton component="a">
                            <ListItemText
                              onClick={handleLogout}
                              disableTypography
                              primary={
                                <Typography variant="h7">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </nav>
                  </div>
                </ClickAwayListener>
              </Popper>
            </div>
          </div>
        </header>
        <div className={styles.black_ribbon}></div>
        <Else />
        <ControlTowerHeader />
      </If>
    </>
  );
};

export default Header;
