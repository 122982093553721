import React from 'react';
import styles from './styles.module.scss';
import ProgressBar from './progressBar';
import { Divider, IconButton } from '@mui/material';
import Spinner from '../../common/Spinner';
import { RefreshOutlined } from '@mui/icons-material';
import LastUpdatedTime from '../../common/LastUpdatedTime';

const OnDemandSlaView = (props) => {
  const { tabList, tabValue, loading, onRefresh } = props;
  if (loading) return <Spinner className={styles.sla_details_spinner} />;
  const data = tabList[tabValue]?.data?.[0] || {};
  const config = tabList[tabValue] || {};
  const { last_updated_at } = config || {};
  return (
    <div className={styles.left_container}>
      <div className={styles.sidebar_title_container}>
        <div className={styles.title_container}>
          <span>SLA</span>
          <LastUpdatedTime timestamp={last_updated_at} />
        </div>
        <IconButton onClick={onRefresh} disabled={loading}>
          <RefreshOutlined />
        </IconButton>
      </div>
      <div className={styles.b1}>
        <div style={{ width: 100, height: 100 }}>
          <ProgressBar percentage={data.on_demand_sla_percentage || 0} />
        </div>
        <div className={styles.card_container}>
          <div className={styles.a1}>
            <div>{data.on_demand_over_licious_percentage || 0}%</div>
            <div>On Demand Percentage</div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.a1}>
            <div>{data.on_demand_assigned_orders || 0}</div>
            <div>Assigned Orders</div>
          </div>
          <div style={{ backgroundColor: 'white', margin: '10px  0px' }}>
            <Divider orientation="vertical" />
          </div>
          <div className={styles.a1}>
            <div>{data.on_demand_delivered_orders || 0}</div>
            <div>Delivered Orders</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnDemandSlaView;
