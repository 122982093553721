/* Primary React Imports */
import React from 'react';
/* Typography and Configuration */
/* SVG Imports */
import CloseFilterIcon from '../../assets/close_filter_icon.svg';
import styles from './styles.module.scss';

/* Base Styled Components and Component-specific Styles */

/* JSX */
const ActiveFilters = ({ handleIndividualClear, filterObject }) => {
  const getActiveFilters = () => {
    let filterArray = [];
    for (let key in filterObject) {
      filterArray.push({ title: key, count: filterObject[key].length });
    }
    return filterArray;
  };
  return (
    <div className={styles.chips_container}>
      <div className={styles.chips_wrapper}>
        {getActiveFilters()
          .filter((data) => data?.count > 0)
          .map((data) => (
            <div className={styles.chips}>
              <p
                className={
                  styles.paragraph
                }>{`${data.title} (${data.count}) `}</p>
              <CloseFilterIcon
                style={{ fontSize: 20 }}
                onClick={() => handleIndividualClear(data.title)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
export default ActiveFilters;
