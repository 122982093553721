import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { useStyles } from './utils';
import { MergeType } from '@mui/icons-material';

const SearchBoxComponent = (props) => {
  const { arrayList, label, value, width, type } = props;
  const classes = useStyles();

  return (
    <Stack spacing={2} width={width}>
      <Autocomplete
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        fullWidth={true}
        value={value}
        onChange={(event, newValue) => props.onChange(event, newValue, type)}
        options={arrayList}
        getOptionLabel={(option) => option || ''}
        renderInput={(params) => (
          <TextField
            classes={{ root: classes.root }}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              type: 'text',
              classes: {
                input: classes.input,
              },
            }}
            fullWidth
          />
        )}
      />
    </Stack>
  );
};
export default SearchBoxComponent;
