import {
  AUTH_SERVICE_BASE_URL,
  AUTH_SERVICE_REDIRECT_URL,
  AUTH_SERVICE_CLIENT_ID,
} from '../../../../constants';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN_COMPLETE = 'VERIFY_TOKEN_COMPLETE';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export const AUTH_SERVICE_LOGOUT_PAGE = `${AUTH_SERVICE_BASE_URL}v1/restricted/logout?sso_logout=false&url=${AUTH_SERVICE_REDIRECT_URL}&client_id=${AUTH_SERVICE_CLIENT_ID}`;
