import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useMemo } from 'react';

import styles from './styles.module.scss';

const CustomTableCell = forwardRef(
  ({ getPrevElements, sticky, loading, className, ...rest }, ref) => {
    const stickyStyles = useMemo(() => {
      if (sticky) {
        const prevElements = getPrevElements();
        const position = prevElements.reduce((acc, curr) => {
          return acc + curr.offsetWidth;
        }, 0);
        return {
          left: `${position}px`,
        };
      }
      return {};
    }, [sticky, loading]);

    return (
      <TableCell
        ref={ref}
        className={classNames(className, { [styles.sticky_column]: sticky })}
        style={stickyStyles}
        {...rest}
      />
    );
  },
);

CustomTableCell.displayName = 'CustomTableCell';

export default CustomTableCell;
