import { forwardRef } from 'react';
import { Autocomplete } from '@mui/material';
import LsInput from '../Input';

import styles from './styles.module.scss';

const LsSelect = forwardRef(
  (
    {
      label,
      placeholder,
      classes,
      error,
      helperText,
      count,
      InputProps,
      ...rest
    },
    ref,
  ) => {
    return (
      <Autocomplete
        renderInput={(params) => {
          return (
            <LsInput
              ref={ref}
              {...params}
              // placeholder={placeholder}
              label={label}
              error={error}
              // helperText={helperText}
              count={count}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
            />
          );
        }}
        classes={{
          inputRoot: styles.input_root,
          endAdornment: styles.end_adornment_root,
          ...classes,
        }}
        {...rest}
      />
    );
  },
);

export default LsSelect;
