import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './styles.module.scss';
import { getDelayTagColorClass } from './utils';

function DelayTagCell({ column, value }) {
  const { key } = column || {};

  const colorClass = useMemo(() => {
    return getDelayTagColorClass(key, value);
  }, [value, key]);

  return (
    <TableCell
      key={column?.key}
      className={classNames(
        column.style,
        colorClass,
        styles.delayTagCenterAlign,
      )}>
      <div>{value}</div>
    </TableCell>
  );
}

export default DelayTagCell;
