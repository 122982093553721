import {
  AUTH_SERVICE_BASE_URL,
  AUTH_SERVICE_CLIENT_ID,
  AUTH_SERVICE_REDIRECT_URL,
  CONTROL_TOWER_BASE_URL,
  CEP_BASE_URL,
  CENTRAL_CITY_CEP_BASE_URL,
  SERVICEABILITY_BASE_URL,
} from '../../constants';
export const SSO_LOGIN_PATH = `${AUTH_SERVICE_BASE_URL}v1/restricted/login?url=${AUTH_SERVICE_REDIRECT_URL}/sso/redirect&client_id=${AUTH_SERVICE_CLIENT_ID}`;
export const VERIFY_TOKEN_PATH = `${AUTH_SERVICE_BASE_URL}v1/restricted/verify`;

export const getInformationRuleUrl = () =>
  `${CONTROL_TOWER_BASE_URL}/rules/get/all`;
export const getInformationRuleUrlById = () =>
  `${CONTROL_TOWER_BASE_URL}/rules/get-data`;
export const getRuleMetaData = (id) =>
  `${CONTROL_TOWER_BASE_URL}/rules/get/${id}`;
export const getGroupRuleInfo = (ruleGroupId) =>
  `${CONTROL_TOWER_BASE_URL}/rule-groups/${ruleGroupId}`;

export const getAllJobConfigUrl = () => `${CEP_BASE_URL}/job-config/all`;
export const getDataForJobConfig = () => `${CEP_BASE_URL}/job-config/data`;

export const getS3UrlForTableData = (database, table) =>
  `${CEP_BASE_URL}/job-config/s3Url?database=${database}&table=${table}`;

export const getJobConfigMetaData = (id) =>
  `${CEP_BASE_URL}/job-config/metadata/${id}`;

export const getCardDataUrl = () =>
  `${CENTRAL_CITY_CEP_BASE_URL}/business-unit/all/data`;

export const getColumnDataUrl = (serviceName) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/business-unit/${serviceName}/config`;

export const getRowDataUrl = (serviceName) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/business-unit/${serviceName}/data`;

export const getCardNameUrl = (serviceName) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/business-unit/all/config`;

export const getFilterDataUrl = (bu) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/filter/static/${bu}`;

export const getAlertIdDetailsUrl = () =>
  `${CENTRAL_CITY_CEP_BASE_URL}/alert/data`;

export const getResolveAlertUrl = (id) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/task/taskInstance/${id}`;

export const LogSubTaskUrl = (id) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/task/subTaskInstance/${id}`;

export const fetchLinkedTicketUrl = (id) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/alert/action-data/${id}`;

export const getBreachDetailsUrl = () =>
  `${CENTRAL_CITY_CEP_BASE_URL}/views/data`;

export const getDailyTrendsTopCardsUrl = () =>
  `${CENTRAL_CITY_CEP_BASE_URL}/viewGroup/all`;

export const getDTCardWiseDataUrl = (id) =>
  `${CENTRAL_CITY_CEP_BASE_URL}/views/group/${id}`;

export const getListOfDegradationUrl = (id) =>
  `${SERVICEABILITY_BASE_URL}/v1/degradation/extent`;

export const getResonsOfDegradationUrl = (id) =>
  `${SERVICEABILITY_BASE_URL}/v1/degradation/reason`;

export const degradeServiceUrl = (id) =>
  `${SERVICEABILITY_BASE_URL}/v1/degradation`;
