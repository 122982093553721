import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const SearchBox = (props) => {
  const { arrayList, label, title } = props;
  return (
    <Autocomplete
      fullWidth={true}
      value={props?.value}
      onChange={(event, newValue) => props.onChange(event, newValue, title)}
      options={arrayList}
      getOptionLabel={(option) => option?.title || ''}
      disableClearable
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
          title={option.title}>
          {option.title}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          title={props?.value?.title}
          placeholder={props.placeholder}
          variant="outlined"
        />
      )}
    />
  );
};
export default SearchBox;
