import { Download, RefreshOutlined } from '@mui/icons-material';
import { Button, IconButton, Link } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import LastUpdatedTime from '../../common/LastUpdatedTime';
import CustomiseModal from '../../common/Modal/Modal';
import LsFilter from '../../common/Select/Filter';
import MuiTableComponent from '../../common/TableComponent/MuiTableComponent';
import { addNotification } from '../../redux/Snackbar/actions';
import { fetchRulesById } from '../../Utils/endPoints';
import { getInformationRuleUrlById } from '../../Utils/serviceUrls';
import { HIDE_COLUMNS } from './constant';
import { getAvailableFilters, getSlaViewPageApiBody } from './service';
import styles from './styles.module.scss';

function SlaPageTable({ tableTitle, tabConfig, handleError }) {
  const [showSelectColumnModal, setShowSelectColumnModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});

  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('ASC');
  const [activePage, setActivePage] = useState(0);
  const [activePageSize, setActivePageSize] = useState(50);
  const [allColumns, setAllColumns] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({});

  const dispatch = useDispatch();

  const handleCloseSelectColumnModal = () => {
    setShowSelectColumnModal(false);
  };

  const handleOpenSelectColumnModal = () => {
    setShowSelectColumnModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setActivePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setActivePage(0);
    setActivePageSize(+event.target.value);
  };

  const handleChangeSort = (column) => {
    setActivePage(0);
    setSortDir(sortDir === 'DESC' ? 'ASC' : 'DESC');
    setSortBy(column);
  };

  const handleChangeFilteredColumns = (columnData) => {
    let columnList = allColumns.filter((data) => columnData.includes(data.key));
    setFilteredColumns(columnList);
    setShowSelectColumnModal(false);
  };

  const handleDownloadCSV = () => {
    const url = tableData?.s3_url;
    try {
      if (url) window.open(url, '_blank');
    } catch (e) {
      dispatch(
        addNotification({
          level: 'error',
          message: 'csv is blank!!',
        }),
      );
    }
  };

  const fetchTabDetails = useCallback(() => {
    const tabName = tabConfig?.table_name;
    if (!tabName) return;

    setLoading(true);
    const body = getSlaViewPageApiBody(
      tabConfig,
      activePage,
      activePageSize,
      sortBy,
      sortDir,
      appliedFilters,
    );
    fetchRulesById(getInformationRuleUrlById(), body)
      .then((res) => {
        const data = res?.data || {};
        const [tabDetails] = data?.rule_output_data || [];
        const { table_name } = tabDetails || {};
        if (table_name !== tabName) return;
        setTableData(tabDetails);
        setLoading(false);
      })
      .catch((e) => {
        handleError(e);
      });
  }, [
    tabConfig,
    activePage,
    activePageSize,
    handleError,
    sortBy,
    sortDir,
    appliedFilters,
  ]);

  // fetch tab details
  useEffect(() => {
    fetchTabDetails();
  }, [fetchTabDetails]);

  useEffect(() => {
    const hiddenColumns =
      HIDE_COLUMNS[tabConfig.title] || HIDE_COLUMNS['OTHERS'];
    const _allColumns = tabConfig.column_details?.filter(
      (e) => !hiddenColumns.includes(e.key),
    );
    setAllColumns(_allColumns || []);
    setFilteredColumns(_allColumns || []);
    setActivePage(0);
    setActivePageSize(50);
    setSortBy('');
    setSortDir('ASC');
  }, [tabConfig]);

  const handleUpdateFilters = (values, type) => {
    setAppliedFilters((currValue) => {
      const dependentKey = getDependentKey(type);
      if (dependentKey) {
        return {
          ...currValue,
          [type]: values,
          [dependentKey]: [],
        };
      }
      return {
        ...currValue,
        [type]: values,
      };
    });
  };

  const getCityHubMapping = (filterDetails) => {
    const cityFilterConfig = filterDetails.find((each) => each.type === 'CITY');
    if (cityFilterConfig) {
      const { city_name_filter } = cityFilterConfig;
      const { city_name_hub_mapping } = city_name_filter || {};
      return Object.keys(city_name_hub_mapping)
        .map((each) => {
          return city_name_hub_mapping[each].hub_name_filter.column_values.map(
            (e) => ({ label: e, value: e, city: each }),
          );
        })
        .flatMap((each) => each);
    }
    return null;
  };

  const getDependentOn = (type) => {
    switch (type) {
      case 'HUB':
        return 'CITY';
      default:
        return '';
    }
  };

  const getDependentOnKey = (type) => {
    switch (type) {
      case 'HUB':
        return 'city';
      default:
        return '';
    }
  };

  const getDependentKey = (type) => {
    switch (type) {
      case 'CITY':
        return 'HUB';
      default:
        return '';
    }
  };

  const availableFilters = useMemo(() => {
    if (!Array.isArray(tabConfig?.base_filter_details)) return [];
    const cityHubList = getCityHubMapping(tabConfig?.base_filter_details);
    return tabConfig?.base_filter_details.map((each) =>
      getAvailableFilters(each, cityHubList),
    );
  }, [tabConfig?.base_filter_details]);

  const tableRows = useMemo(() => {
    return tableData?.data || [];
  }, [tableData]);

  return (
    <div>
      <div className={styles.flex_container}>
        <div
          className={classNames(styles.flex_1, styles.table_title_container)}>
          <p className={styles.title_container}>
            <span className={styles.title}>{tabConfig?.title}</span>
            <LastUpdatedTime timestamp={tableData?.last_updated_at} />
          </p>
          <Link
            className={styles.link}
            underline="hover"
            onClick={handleOpenSelectColumnModal}>
            Customise
          </Link>
          {availableFilters.map((each) => (
            <LsFilter
              key={each.type}
              options={each.options}
              placeholder={each.type}
              type={each.type}
              onUpdate={(values) => handleUpdateFilters(values, each.type)}
              values={appliedFilters[each.type] || []}
              appliedFilters={appliedFilters}
              dependentOn={getDependentOn(each.type)}
              dependentOnKey={getDependentOnKey(each.type)}
            />
          ))}
        </div>
        <div className={styles.right_align_items}>
          <div className={styles.padding_8}>
            <IconButton onClick={fetchTabDetails} disabled={loading}>
              <RefreshOutlined />
            </IconButton>
          </div>
          <p className={styles.padding_8}>
            count:&nbsp;
            {loading && <span>-</span>}
            {!loading && <span>{tableData?.count || 0}</span>}
          </p>
          <Button
            variant="outlined"
            disabled={loading || !tableData?.s3_url}
            onClick={handleDownloadCSV}>
            <p> CSV</p>
            &nbsp;
            <Download />
          </Button>
        </div>
      </div>
      <div className={styles.table_container}>
        <MuiTableComponent
          data-testid="customer_list_table"
          rows={tableRows}
          columns={filteredColumns || []}
          page={activePage}
          rowsPerPage={activePageSize}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          loading={loading}
          count={tableData?.count || 0}
          isPagination={true}
          isSorting={true}
          sortBy={sortBy}
          handleSorting={handleChangeSort}
          selectedOrder={sortDir}
          selectedColumn={sortBy}
        />
      </div>
      {showSelectColumnModal && (
        <CustomiseModal
          visible={showSelectColumnModal}
          applyColumns={handleChangeFilteredColumns}
          handleCancel={handleCloseSelectColumnModal}
          allColumns={allColumns || []}
          checkedColumns={filteredColumns || []}
        />
      )}
    </div>
  );
}

export default SlaPageTable;
