import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { accessibilityKey } from '../SSORedirect/utils';
import LsFilter from '../../common/Select/Filter';
import { FormControl, MenuItem, Select, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/Snackbar/actions';
import {
  getCardDataUrl,
  getRowDataUrl,
  getCardNameUrl,
  getFilterDataUrl,
  getAlertIdDetailsUrl,
  getListOfDegradationUrl,
  getResonsOfDegradationUrl,
  degradeServiceUrl,
  getResolveAlertUrl,
} from '../../Utils/serviceUrls';
import {
  fetchCardsData,
  fetchRowData,
  fetchCardNames,
  fetchFiltersData,
  fetchAlertIdDetails,
  fetchListOfDegradation,
  fetchResonsOfDegradation,
  degradeService,
  ResolveAlert,
} from '../../Utils/endPoints';
import CardComponent from './cardComponent';
import moment from 'moment';
import {
  DEGRADE_FAILURE_MSG,
  DEGRADE_SUCCESS_MSG,
  ISSUES_FILTER,
} from './constants';
import DataCardComponent from '../../common/DataCardBreachM/dataCard';
import DownArrowIcon from '../../assets/down_arrow_icon.svg';
import IssueDetailComponent from './issueDetailCard';
import SuggestedActionComponent from './suggestedAction';
import ActionTrailComponent from './ActionTrailComponent';
import Spinner from '../../common/Spinner';
import DEMapView from '../../common/GoogleMap';
import LastMileMapActionTrailComponent from './lastMileMapTable';
import RefreshIcon from '../../assets/refresh_icon.svg';
import LinkedTicketTable from './linkedTicketTable';
import DegradeServiceModal from '../../common/DegradeServiceModal';
import GenericModal from '../../common/GenericModal';

const BrechManagementDasboardTab = () => {
  const [selectedTab, setSelectedTab] = useState('ALL_TICKETS');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [cardNames, setCardNameList] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [tableRow, setTableRow] = useState([]);
  const [filters, setFilters] = useState([]);
  var [filterBodySelected, setFilterBody] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [paginationVal, setPaginationVal] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [issueType, setIssueType] = useState(ISSUES_FILTER?.options[0]?.value);
  const [showActionTrail, setActionTrail] = useState(false);
  const [showActionStatus, setActionStatus] = useState(true);
  const [showIssueTypeDropdown, setIssueTypeDropdown] = useState(false);
  const [showSDActionTaken, setSDActionTaken] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [actionTrailHeading, setActionTrailHeading] = useState([]);
  const [actionTrailData, setActionTrailData] = useState([]);
  const [suggestedAction, setSuggestedActionData] = useState({});
  const [taskInstanceId, setTaskInstanceId] = useState({});
  const [alertMessageId, setAlertMessageId] = useState();
  const [selectedCardIssueType, setSelectedCardIssueSource] = useState('');
  const [issueTypeLabel, setSelectedIssueTypeLabel] = useState(
    ISSUES_FILTER?.options[0]?.label,
  );
  const [alertDetailAPIStatus, setAlertDetailAPIStatus] = useState(false);
  const [iSModificationDetailHeading, setISModificationDetailHeading] =
    useState([]);
  const [iSModificationDetailRowData, setISModificationDetailRowData] =
    useState([]);
  const [lastMileMapFields, setLastMileMapFields] = useState([]);
  const [lastMileTableHeader, setLMTableHeader] = useState([]);
  const [lastMileTableData, setLMTableData] = useState([]);
  const [errorCase, setErrorCase] = useState(false);
  const [rightContainerLoading, setRightContainerLoading] = useState(false);
  const [selectedCardMsgId, setSelectedCardMsgId] = useState('');
  const [linkedTicketHeading, setLinkedTicketsHeading] = useState([]);
  const [linkedTicketTableData, setLinkedTicketData] = useState([]);
  const [showLinkedTickets, setLinkedTicketContainer] = useState(false);
  const [shortfallDetailHeaders, setShortfallDetailHeading] = useState([]);
  const [shortfallDetailData, setShortfallDetailData] = useState([]);
  const [degradeServiceModal, setDegradeServiceModal] = useState(false);
  const [productOutOfStockDetailHeader, setProductOutOfStockDetailHeading] =
    useState([]);
  const [productOutOfStockDetailData, setProductOutOfStockDetailData] =
    useState([]);
  const [succErrModal, setSuccErrModal] = useState(false);
  const [degradeSuccess, setDegradeSuccess] = useState(false);
  const [extendOfDegradation, setExtendOfDegradation] = useState([]);
  const [selectedExtendOfDegradation, setSelectedExtendOfDegradation] =
    useState('');
  const [reasonsOfDegradation, setReasonsOfDegradation] = useState([]);
  const [selectedReasonOfDegradation, setSelectedReasonOfDegradation] =
    useState('');

  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const [checkboxState, setCheckboxState] = useState();
  const [selectedKmlIds, setSelectedKmlIds] = useState([]);
  const [hubId, setHubId] = useState(null);
  const [degradeFailureMsg, setDegradeFailureMsg] =
    useState(DEGRADE_FAILURE_MSG);
  const [selectedCheckBoxRowData, setSelectedCheckBoxRowData] = useState([]);
  const [degradeTicketResolved, setDegradeTicketResolved] = useState(false);

  const { authUserResponse } = useSelector((state) => state.authReducer);
  const dateTime = new Date();
  const currentTimeStamp = moment(dateTime, 'MM-DD-YYYY HH:mm:ss', true).format(
    'YYYY-MM-DD HH:mm:ss',
  );
  const [disableDegradeModalSave, setDisableDegradeModalSave] = useState(false);
  const actionTrailRef = useRef(null);
  const leftCardRef = useRef(null);
  const linkedTicketRef = useRef(null);
  const dispatch = useDispatch();

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    setAppliedFilters({});
    setFilterBody([]);
    setTableRow([]);
    setCurrentCardIndex(0);
    setSuggestedActionData({});
    setLMTableHeader([]);
    setLMTableData([]);
    setActionTrailData([]);
    setActionTrailHeading([]);
    setISModificationDetailRowData([]);
    setISModificationDetailHeading([]);
    setShortfallDetailHeading([]);
    setShortfallDetailData([]);
    setLinkedTicketsHeading([]);
    setLinkedTicketData([]);
    setOrderDetails([]);
    setProductOutOfStockDetailHeading([]);
    setProductOutOfStockDetailData([]);
    getFilters(tab);
    if (tab === 'LAST_MILE') {
      setActionStatus(true);
      setIssueTypeDropdown(true);
      setSDActionTaken(false);
      getTableRow(tab);
    } else if (tab === 'INVENTORY') {
      setActionStatus(true);
      setIssueTypeDropdown(false);
      setSDActionTaken(false);
      getTableRow(tab);
    } else if (tab === 'HUB_OPS') {
      setActionStatus(true);
      setIssueTypeDropdown(false);
      setSDActionTaken(true);
      getTableRow(tab);
    } else if (tab === 'ALL_TICKETS') {
      getTableRow(tab);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPaginationVal(0);
    setTimeout(() => {
      getTableRow(selectedTab, 0, newRowsPerPage);
    }, 10);
  };

  const handleChangePage = (event, newPage) => {
    setPaginationVal(newPage);
    getTableRow(selectedTab, newPage, rowsPerPage);
    setCurrentCardIndex(0);
    handleScrollTop(0);
  };

  const getDependentOn = (type) => {
    switch (type) {
      case 'HUB':
        return 'CITY';
      default:
        return '';
    }
  };

  const getDependentOnKey = (type) => {
    switch (type) {
      case 'HUB':
        return 'city';
      default:
        return '';
    }
  };

  const getDependentKey = (type) => {
    switch (type) {
      case 'CITY':
        return 'HUB';
      default:
        return '';
    }
  };

  const handleUpdateFilters = (values, type) => {
    setCurrentCardIndex(0);
    handleScrollTop(0);
    setLinkedTicketData([]);
    setLinkedTicketsHeading([]);
    setSuggestedActionData({});
    setActionTrailData([]);
    setActionTrailHeading([]);
    setOrderDetails([]);
    setLMTableHeader([]);
    setLMTableData([]);
    setShortfallDetailHeading([]);
    setShortfallDetailData([]);
    setProductOutOfStockDetailHeading([]);
    setProductOutOfStockDetailData([]);
    setISModificationDetailHeading([]);
    setISModificationDetailRowData([]);

    var filterBody = {};
    var filterSelected = '';

    setAppliedFilters((currValue) => {
      const dependentKey = getDependentKey(type);
      if (dependentKey) {
        return {
          ...currValue,
          [type]: values,
          [dependentKey]: [],
        };
      }
      return {
        ...currValue,
        [type]: values,
      };
    });

    if (type === 'HUB') {
      filterSelected = 'hubName';
    } else if (type === 'CITY') {
      filterSelected = 'cityName';
      // Clear HUB object every time CITY is changed
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'hubName'),
        );
      }
      // Clear CITY object if values array is empty
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'cityId'),
        );
      }
    } else if (type === 'ALERT_TAG') {
      filterSelected = 'alertTag';
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'alertTag'),
        );
      }
    } else if (type === 'ISSUE_TYPE') {
      filterSelected = 'alertName';
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'alertName'),
        );
      }
    } else {
      filterSelected = type;
      if (values.length === 0) {
        setFilterBody((prevFilterBody) => {
          return prevFilterBody.filter((filter) => filter.columnName !== type);
        });
      }
    }
    // Check if values array is empty and clear the corresponding filter
    if (values.length === 0) {
      setFilterBody((prevFilterBody) => {
        const updatedFilterBody = prevFilterBody.filter(
          (filter) => filter.columnName !== filterSelected,
        );
        return updatedFilterBody;
      });
    } else {
      // Replace existing filter if values array is not empty
      if (type === 'HUB' || type === 'CITY' || type === 'ALERT_TAG') {
        if (values.length > 1) {
          filterBody = {
            type: 'IN',
            columnName: filterSelected,
            columnValue: {
              name: filterSelected,
              dataType: 'LIST',
              value: {
                type: 'Static',
                value: values,
              },
            },
          };
        } else {
          filterBody = {
            type: 'EQUAL',
            columnName: filterSelected,
            columnValue: {
              name: filterSelected,
              dataType: 'STRING',
              value: {
                type: 'Static',
                value: values[0] ? values[0] : [],
              },
            },
          };
        }
      } else if (type === 'ISSUE_TYPE') {
        const updatedValForIssueType = values.flat();
        filterBody = {
          type: 'IN',
          columnName: filterSelected,
          columnValue: {
            name: filterSelected,
            dataType: 'LIST',
            value: {
              type: 'Static',
              value: updatedValForIssueType,
            },
          },
        };
      }
      setFilterBody((prevFilterBody) => {
        const existingFilterIndex = prevFilterBody.findIndex(
          (filter) => filter.columnName === filterSelected,
        );

        if (existingFilterIndex !== -1) {
          // If filter with the same columnName exists, replace it
          prevFilterBody[existingFilterIndex] = filterBody;
        } else {
          // Otherwise, push the new filter
          prevFilterBody.push(filterBody);
        }
        return [...prevFilterBody];
      });
    }
  };

  useEffect(() => {
    getTableRow(selectedTab, 0, rowsPerPage, issueType);
  }, [filterBodySelected]);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.message,
      }),
    );
    setLoading(false);
  };
  const handleSuccess = (msg) => {
    dispatch(
      addNotification({
        level: 'success',
        message: msg,
      }),
    );
  };

  const getCardNames = () => {
    const body = {};
    fetchCardNames(getCardNameUrl(), body)
      .then((res) => {
        const data = res?.data;
        setCardNameList(data);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const getCardData = (selectedIssueType) => {
    const issueTypeFinal = selectedIssueType ? selectedIssueType : issueType;
    const body = {
      fromTime: moment(currentTimeStamp).format('YYYY-MM-DD') + ' 00:00:00',
      toTime: currentTimeStamp,
      condition: {
        type: 'EQUAL',
        columnName: 'alertStatus',
        columnValue: {
          name: 'alertStatus',
          dataType: 'STRING',
          value: {
            type: 'Static',
            value: issueTypeFinal,
          },
        },
      },
    };
    fetchCardsData(getCardDataUrl(), body)
      .then((res) => {
        setCardData(res?.data);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const transformLeftCardData = (dataArray) => {
    const transformedDataArray = dataArray
      .map((data) => {
        const transformedData = {};
        Object.keys(data).forEach((key, index) => {
          const description = key;
          const value = data[key]?.value;
          if (description !== undefined && value !== undefined) {
            transformedData[description] = value;
          }
        });
        return transformedData;
      })
      .map((item, index) => ({ ...item, index }));
    setTableRow(transformedDataArray);
    if (dataArray.length) {
      getAlertIdDetails(transformedDataArray[0]);
    }
  };

  const getTableRow = (currentTab, newPage, rowsPerPage, selectedIssueType) => {
    var tab = currentTab ? currentTab : selectedTab;
    var issueTypeFinal = selectedIssueType ? selectedIssueType : issueType;

    var body = {
      fromTime: moment(currentTimeStamp).format('YYYY-MM-DD') + ' 00:00:00',
      toTime: currentTimeStamp,
      pageDetail: {
        pageNo: newPage,
        pageSize: rowsPerPage ? rowsPerPage : 50,
      },
      condition: {
        type: 'LOGICAL_AND',
        conditions: [
          {
            type: 'EQUAL',
            columnName: 'alertStatus',
            columnValue: {
              name: 'alertStatus',
              dataType: 'STRING',
              value: {
                type: 'Static',
                value: issueTypeFinal,
              },
            },
          },
        ],
      },
    };

    if (
      filterBodySelected &&
      filterBodySelected !== undefined &&
      filterBodySelected.length
    ) {
      body = {
        ...body,
        condition: {
          ...body.condition,
          conditions: [...body.condition.conditions, ...filterBodySelected],
        },
      };
    }
    setLoading(true);
    fetchRowData(getRowDataUrl(tab), body)
      .then((res) => {
        const selectedIssueSource = res?.data?.data[0]
          ? res?.data?.data[0]?.bu?.value
          : '';
        const issueData = res?.data?.data.length ? res?.data?.data : [];
        if (issueData.length > 0) {
          transformLeftCardData(issueData);
        } else {
          setTableRow([]);
        }
        if (selectedIssueSource) {
          let issueSourceValue;

          if (typeof selectedIssueSource === 'object') {
            // Extract the specific value you want to render
            issueSourceValue = selectedIssueSource.values; // or selectedIssueSource.type;
          } else {
            issueSourceValue = selectedIssueSource;
          }
          setSelectedCardIssueSource(issueSourceValue);
          setTotalCount(res?.data?.totalCount);

          if (issueSourceValue === 'LAST_MILE') {
            setIssueTypeDropdown(true);
          }
        } else {
          setTableRow([]);
          console.error('selectedIssueSource is undefined or null');
        }

        setLoading(false);
        setCurrentCardIndex(0);
        handleScrollTop(0);
      })
      .catch((e) => {
        setErrorCase(true);
        handleError(e);
      });
  };

  const getFilters = (bu) => {
    const activeBu = bu ? bu : selectedTab;
    fetchFiltersData(getFilterDataUrl(activeBu))
      .then((res) => {
        if (res?.data?.length) {
          const data = res?.data;
          const modifiedFilterArray = data?.map((item) => {
            if (item?.name === 'CITY') {
              return {
                type: item?.name,
                options:
                  item.cityDetails.map((city) => ({
                    label: city.name,
                    value: city.name,
                  })) || [],
              };
            } else if (item?.name === 'HUB') {
              return {
                type: item?.name,
                options:
                  item.hubDetails.map((hub) => ({
                    label: hub.hubName,
                    value: hub.hubName,
                    city: hub?.cityName,
                  })) || [],
              };
            } else if (item?.name === 'ALERT_TAG') {
              return {
                type: item?.name,
                options:
                  item.values.map((flag) => ({
                    label: flag,
                    value: flag,
                  })) || [],
              };
            } else {
              return {
                type: item?.name,
                options:
                  (item?.values &&
                    Object.keys(item.values).map((key) => ({
                      label: key, // The key becomes the label, e.g., "SLA Breach"
                      value: item.values[key], // The corresponding array becomes the value
                    }))) ||
                  [],
              };
            }
          });
          setFilters(modifiedFilterArray);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const transformedLMTableData = (inputData) => {
    if (inputData && inputData.length > 0) {
      const tableData = inputData.reduce((headers, dataItem) => {
        Object.keys(dataItem).forEach((key) => {
          if (!headers.find((header) => header.value === key)) {
            headers.push({
              value: key,
              description: dataItem[key].description,
            });
          }

          if (dataItem?.promisedTime) {
            dataItem.promisedTime.value = moment(
              dataItem?.promisedTime?.value,
            ).format('DD-MMM-YYYY , hh:mm A');
          }
        });
        return headers;
      }, []);

      const columnHeaders = inputData.map((dataItem) => {
        const formattedDataItem = {};
        Object.keys(dataItem).forEach((key) => {
          formattedDataItem[key] = {
            value: dataItem[key].value,
            description: dataItem[key].description,
          };
        });
        return formattedDataItem;
      });

      setLMTableData(tableData);
      setLMTableHeader(columnHeaders);
    } else {
      setLMTableData([]);
      setLMTableHeader([]);
    }
  };

  const transformISModificationDetails = (inputData) => {
    const dataArray = inputData.value.data;
    let columnHeaders = [];
    let tableData = [];
    let encounteredKeys = [];

    dataArray.forEach((dataItem) => {
      Object.keys(dataItem).forEach((key) => {
        if (!encounteredKeys.includes(key)) {
          encounteredKeys.push(key);
          columnHeaders.push({
            value: key,
            description: dataItem[key].description,
          });
        }
      });

      tableData.push(dataItem);
    });
    setISModificationDetailHeading(columnHeaders);
    setISModificationDetailRowData(tableData);
  };

  const transformActionTrialResponse = (inputData) => {
    const dataArray = inputData.value.data;
    let columnHeaders = [];
    let tableData = [];
    let encounteredKeys = [];

    dataArray.forEach((dataItem) => {
      Object.keys(dataItem).forEach((key) => {
        if (!encounteredKeys.includes(key)) {
          encounteredKeys.push(key);
          columnHeaders.push({
            value: key,
            description: dataItem[key].description,
          });
        }
      });

      if (dataItem.timeStamp) {
        dataItem.timeStamp.value = moment(dataItem.timeStamp.value).format(
          'DD MMM YY, hh:mma',
        );
      }
      tableData.push(dataItem);
    });
    setActionTrailHeading(columnHeaders);
    setActionTrailData(tableData);
  };

  const transformLinkedTicketResponse = (inputData) => {
    if (Object.keys(inputData).length > 0) {
      const dataArray = inputData.value.data;
      let columnHeaders = [];
      let tableData = [];
      let encounteredKeys = [];

      dataArray.forEach((dataItem) => {
        Object.keys(dataItem).forEach((key) => {
          if (!encounteredKeys.includes(key)) {
            encounteredKeys.push(key);
            columnHeaders.push({
              value: key,
              description: dataItem[key].description,
            });
          }
        });

        if (dataItem.timeStamp) {
          dataItem.timeStamp.value = moment(dataItem.timeStamp.value).format(
            'DD MMM YY, hh:mma',
          );
        }

        tableData.push(dataItem);
      });

      setLinkedTicketsHeading(columnHeaders);
      setLinkedTicketData(tableData);
    } else {
      setLinkedTicketData([]);
    }
  };

  const transformProductShortfallDetails = (inputData, type) => {
    if (Object.keys(inputData).length > 0) {
      const dataArray = inputData?.value?.data;

      let columnHeaders = [];
      let tableData = [];
      let encounteredKeys = [];

      dataArray.forEach((dataItem) => {
        Object.keys(dataItem).forEach((key) => {
          if (!encounteredKeys.includes(key)) {
            encounteredKeys.push(key);
            columnHeaders.push({
              value: key,
              description: dataItem[key].description,
            });
          }
        });

        if (dataItem.timeStamp) {
          dataItem.timeStamp.value = moment(dataItem.timeStamp.value).format(
            'DD MMM YY, hh:mma',
          );
        }

        tableData.push(dataItem);
      });

      if (type === 'productShortfall') {
        setShortfallDetailHeading(columnHeaders);
        setShortfallDetailData(tableData);
      } else if (type === 'productOutOfStock') {
        setProductOutOfStockDetailHeading(columnHeaders);
        setProductOutOfStockDetailData(tableData);
      }
    } else {
      setShortfallDetailHeading([]);
      setShortfallDetailData([]);
      setProductOutOfStockDetailHeading([]);
      setProductOutOfStockDetailData([]);
    }
  };

  const onActionTrailCollapsible = () => {
    if (showActionTrail) {
      setActionTrail(false);
    } else {
      setActionTrail(true);
    }
    handleScrollBottom();
  };

  const onLinkedTicketCollapsible = () => {
    if (showLinkedTickets) {
      setLinkedTicketContainer(false);
    } else {
      setLinkedTicketContainer(true);
    }
    handleScrollBottomLT();
  };

  const onCardCollapsible = (index, val) => {
    setCurrentCardIndex(index);
    setSuggestedActionData({});
    setLMTableHeader([]);
    setLMTableData([]);
    setActionTrailData([]);
    setActionTrailHeading([]);
    setISModificationDetailRowData([]);
    setISModificationDetailHeading([]);
    setShortfallDetailHeading([]);
    setShortfallDetailData([]);
    setLinkedTicketsHeading([]);
    setLinkedTicketData([]);
    setOrderDetails([]);
    setProductOutOfStockDetailHeading([]);
    setProductOutOfStockDetailData([]);
    setDegradeTicketResolved(false);

    if (selectedCardMsgId !== val?.messageId) {
      getAlertIdDetails(val);
    }
    setSelectedCardIssueSource(val?.bu);
    setSelectedCardMsgId(val?.messageId);
  };

  const handleIssueFilter = (event) => {
    setCurrentCardIndex(0);
    handleScrollTop(0);
    setSuggestedActionData({});
    setLMTableHeader([]);
    setLMTableData([]);
    setActionTrailData([]);
    setActionTrailHeading([]);
    setISModificationDetailRowData([]);
    setISModificationDetailHeading([]);
    setShortfallDetailHeading([]);
    setShortfallDetailData([]);
    setLinkedTicketsHeading([]);
    setLinkedTicketData([]);
    setOrderDetails([]);

    const value = event.target.value;
    setIssueType(value);
    const selectedLabel = ISSUES_FILTER?.options.find(
      (item) => item.value === value,
    );
    setSelectedIssueTypeLabel(selectedLabel?.label);
    getTableRow('', paginationVal, rowsPerPage, value);
    getCardData(value);
    if (value === 'RESOLVED') {
      setActionTrail(true);
    }
  };

  const getAlertIdDetails = async (val) => {
    const body = {
      name: val?.alertName,
      messageId: val?.messageId,
    };
    setAlertMessageId(val?.messageId);
    setRightContainerLoading(true);
    await fetchAlertIdDetails(getAlertIdDetailsUrl(), body)
      .then((res) => {
        if (Object.keys(res?.data).length) {
          const serviceDegradation =
            res?.data?.slaDetails &&
            Object.keys(res?.data?.slaDetails?.value).length > 0
              ? true
              : false;

          const inventoryInwardDetails = res?.data?.inventoryInwardDetails;
          const crDelayDetails = res?.data?.crDelayDetails;
          const productOosAlertDetails = res?.data?.productOosDetails;

          const inventoryShortfall =
            (inventoryInwardDetails &&
              Object.keys(inventoryInwardDetails?.value || {}).length > 0) ||
            (crDelayDetails &&
              Object.keys(crDelayDetails?.value || {}).length > 0) ||
            (productOosAlertDetails &&
              Object.keys(productOosAlertDetails?.value || {}).length > 0);

          const lastMileAlertDetails =
            (res?.data && res?.data?.impactedTrip) ||
            res?.data?.de_stationary_trip_details
              ? true
              : false;
          if (serviceDegradation) {
            const {
              slaDetails,
              actionTrail,
              suggestedAction,
              taskInstanceId,
              linkedTickets,
            } = res?.data;

            const hubIdValue = taskInstanceId?.value?.hubId?.value; // Safely access hubId value

            setTaskInstanceId(taskInstanceId);
            if (hubIdValue !== undefined) {
              setHubId(hubIdValue); // Set the hubId in state
            }

            setAlertDetailAPIStatus(true);
            setOrderDetails(
              slaDetails && slaDetails !== undefined ? slaDetails?.value : [],
            );
            setSuggestedActionData(suggestedAction);

            if (
              actionTrail?.value !== undefined &&
              Object.keys(actionTrail?.value).length > 0 &&
              actionTrail?.value
            ) {
              transformActionTrialResponse(actionTrail);
            }
            transformLinkedTicketResponse(
              linkedTickets &&
                linkedTickets !== undefined &&
                Object.keys(linkedTickets?.value).length > 0
                ? linkedTickets
                : [],
            );
            if (
              suggestedAction &&
              suggestedAction?.value?.resolve?.value?.isMultiAction
            ) {
              setCheckboxState(
                suggestedAction?.value?.data.map(
                  (row) => row?.kml?.value?.checked === true,
                ),
              );
            }
            setRightContainerLoading(false);
          } else if (inventoryShortfall) {
            setRightContainerLoading(false);
            const {
              inventoryInwardDetails,
              actionTrail,
              suggestedAction,
              orderModificationDetails,
              taskInstanceId,
              linkedTickets,
              productShortfallDetails,
              crDelayDetails,
              productOosDetails,
              productOosAlertDetails,
            } = res?.data;

            if (
              inventoryInwardDetails &&
              Object.keys(inventoryInwardDetails?.value || {}).length > 0
            ) {
              setOrderDetails(inventoryInwardDetails?.value);
            } else if (
              crDelayDetails &&
              Object.keys(crDelayDetails?.value || {}).length > 0
            ) {
              setOrderDetails(crDelayDetails?.value);
            } else if (
              productOosAlertDetails &&
              Object.keys(productOosAlertDetails?.value || {}).length > 0
            ) {
              setOrderDetails(productOosAlertDetails?.value);
            } else {
              setOrderDetails(null); // Handle cases where all details are undefined or empty
            }

            setAlertDetailAPIStatus(true);
            setTaskInstanceId(taskInstanceId);

            setSuggestedActionData(
              suggestedAction && suggestedAction !== undefined
                ? suggestedAction
                : {},
            );

            if (
              actionTrail?.value !== undefined &&
              Object.keys(actionTrail?.value).length > 0 &&
              actionTrail?.value
            ) {
              transformActionTrialResponse(actionTrail);
            }

            if (
              orderModificationDetails &&
              Object.keys(orderModificationDetails).length &&
              orderModificationDetails !== undefined
            ) {
              transformISModificationDetails(orderModificationDetails);
            }

            if (
              productShortfallDetails &&
              Object.keys(productShortfallDetails).length &&
              productShortfallDetails !== undefined
            ) {
              transformProductShortfallDetails(
                productShortfallDetails,
                'productShortfall',
              );
            } else if (
              productOosDetails &&
              Object.keys(productOosDetails).length &&
              productOosDetails !== undefined
            ) {
              transformProductShortfallDetails(
                productOosDetails,
                'productOutOfStock',
              );
            }

            if (
              linkedTickets &&
              Object.keys(linkedTickets?.value).length > 0 &&
              linkedTickets !== undefined
            ) {
              transformLinkedTicketResponse(linkedTickets);
            }
          } else if (lastMileAlertDetails) {
            const {
              de_stationary_trip_details,
              actionTrail,
              suggestedAction,
              impactedTrip,
              taskInstanceId,
              linkedTickets,
            } = res?.data;
            if (impactedTrip && Object.keys(impactedTrip?.value).length > 0) {
              const mapData =
                impactedTrip?.value?.mapField.length > 0
                  ? impactedTrip?.value?.mapField
                  : [];
              const mapTableData =
                impactedTrip?.value?.dataField.length > 0
                  ? impactedTrip?.value?.dataField
                  : [];
              setLastMileMapFields(mapData);
              transformedLMTableData(mapTableData);
            }

            if (suggestedAction) {
              setSuggestedActionData(suggestedAction);
            }
            if (linkedTickets) {
              transformLinkedTicketResponse(
                Object.keys(linkedTickets?.value).length > 0
                  ? linkedTickets
                  : {},
              );
            }
            if (
              de_stationary_trip_details &&
              de_stationary_trip_details?.value
            ) {
              setOrderDetails(
                de_stationary_trip_details &&
                  de_stationary_trip_details !== undefined &&
                  de_stationary_trip_details?.value,
              );
            }

            if (
              actionTrail?.value !== undefined &&
              Object.keys(actionTrail?.value).length > 0 &&
              actionTrail?.value
            ) {
              transformActionTrialResponse(actionTrail);
            }
            setAlertDetailAPIStatus(true);
            setTaskInstanceId(taskInstanceId);
            setRightContainerLoading(false);
          } else {
            setRightContainerLoading(false);
            return;
          }
        }
      })
      .catch((e) => {
        setRightContainerLoading(false);
        handleError(e);
      });
  };

  const handleScrollBottom = () => {
    setTimeout(() => {
      actionTrailRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  };
  const handleScrollBottomLT = () => {
    setTimeout(() => {
      linkedTicketRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  };

  const handleScrollTop = (index) => {
    const selectedCardElement = document.getElementById(`dataCard_${index}`);
    const container = document.getElementById('scrollableContainer');
    if (container && selectedCardElement) {
      container.scrollTop = selectedCardElement.offsetTop;
    }
  };

  const sliceResolvedAlertCard = () => {
    var updatedTableRow =
      tableRow && tableRow.filter((item) => item.messageId !== alertMessageId);
    setTableRow(updatedTableRow);
    const nextVal = updatedTableRow[currentCardIndex];
    onCardCollapsible(currentCardIndex, nextVal);
  };

  const callLeftCardDataAndDetailAPI = () => {
    getTableRow();
  };

  const initializeDegradationModalTime = () => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 30); // Add 30 minutes to current time
    let currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const period = currentHour >= 12 ? 'PM' : 'AM';

    // Convert hour to 12-hour format
    if (currentHour > 12) {
      currentHour -= 12;
    } else if (currentHour === 0) {
      currentHour = 12;
    }

    setSelectedHour(currentHour.toString().padStart(2, '0'));
    setSelectedMinute(currentMinute.toString().padStart(2, '0'));
    setSelectedPeriod(period);
  };

  const callListOfDegradationAPI = () => {
    const body = {};
    fetchListOfDegradation(getListOfDegradationUrl(), body)
      .then((res) => {
        const data = res?.data;
        // Ensure data is available before setting
        if (data && data.length > 0) {
          setExtendOfDegradation(data);
          setSelectedExtendOfDegradation(data[0].id); // Set the first option from the fetched data
          setDisableDegradeModalSave(false);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const callReasonOfDegradationAPI = () => {
    const body = {};
    fetchResonsOfDegradation(getResonsOfDegradationUrl(), body)
      .then((res) => {
        const data = res?.data;

        // Ensure data is available before setting
        if (data && data.length > 0) {
          setReasonsOfDegradation(data);
          setSelectedReasonOfDegradation(data[0].id); // Set the first option from the fetched data
          setDisableDegradeModalSave(false);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleCheckboxChange = (event, rowIndex, kmlId, row) => {
    // Check if the KML is already degraded (i.e., has a value of [1 || true])
    if (row.kml.value.checked === true) {
      // If it's already degraded, skip updating selectedKmlIds
      return;
    }

    // Update the checkbox state for the clicked row
    const updatedCheckboxState = [...checkboxState];
    updatedCheckboxState[rowIndex] = event.target.checked;
    setCheckboxState(updatedCheckboxState);

    // Append or remove row data depending on checkbox state
    setSelectedCheckBoxRowData((prevData) => {
      if (event.target.checked) {
        return [...prevData, row];
      } else {
        return prevData.filter((item) => item.kmlId.value !== row.kmlId.value);
      }
    });

    // Update the selectedKmlIds array based on the checkbox state
    let updatedKmlIds = [...selectedKmlIds];

    if (event.target.checked) {
      // Only add the KML to selectedKmlIds if its value was 0 (i.e., not already degraded)
      if (row.kml.value.checked === false) {
        updatedKmlIds.push(kmlId);
      }
    } else {
      // If unchecked, remove the KML ID from the array
      updatedKmlIds = updatedKmlIds.filter((id) => id !== kmlId);
    }

    setSelectedKmlIds(updatedKmlIds); // Update the state with the new KML ID list

    // Handle "Select All" checkbox state
    const isAllChecked = updatedCheckboxState.every(Boolean);
    const selectAllCheckbox = document.getElementById('selectAllCheckbox');
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = isAllChecked;
    }
  };

  const handleSelectAllCheckbox = (event) => {
    const isChecked = event.target.checked;
    if (!suggestedAction.value || !suggestedAction.value.data) {
      return null; // or some loading state
    }
    // Update the checkboxState for all rows, only check non-degraded ones (checked === false)
    const updatedCheckboxState = suggestedAction.value.data.map(
      (row, rowIndex) => {
        // Only toggle the checkbox for rows that are not already degraded (checked === false)
        return row.kml.value.checked === false
          ? isChecked
          : checkboxState[rowIndex];
      },
    );

    setCheckboxState(updatedCheckboxState);

    if (isChecked) {
      // Select only non-degraded rows (checked === false) and update selectedKmlIds

      const nonDegradedRows = suggestedAction?.value?.data.filter(
        (row) => row?.kml?.value?.checked === false,
      );
      setSelectedCheckBoxRowData(nonDegradedRows);
      setSelectedKmlIds(nonDegradedRows.map((row) => row?.kmlId?.value));
    } else {
      // Deselect all rows
      setSelectedCheckBoxRowData([]);
      setSelectedKmlIds([]);
    }
  };

  const handleDegradeService = () => {
    //call reasons API fetchListOfDegradation
    callListOfDegradationAPI();
    callReasonOfDegradationAPI();
    setDegradeServiceModal(true);
    initializeDegradationModalTime();
  };

  const onCloseDegradeServiceModal = () => {
    setDegradeServiceModal(false);
  };

  const convertToIST = () => {
    // Convert to 24-hour format based on AM/PM
    let hourIn24 = parseInt(selectedHour, 10);
    if (selectedPeriod === 'PM' && hourIn24 < 12) {
      hourIn24 += 12; // Convert PM to 24-hour format
    } else if (selectedPeriod === 'AM' && hourIn24 === 12) {
      hourIn24 = 0; // Convert 12 AM to 0 hours
    }

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
    const currentDate = String(today.getDate()).padStart(2, '0');

    const selectedDate = `${currentYear}-${currentMonth}-${currentDate}`;

    // Manually construct the ISO string without using the Date object conversion to avoid time shift
    const dateString = `${selectedDate}T${hourIn24.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}:00`;

    return dateString; // Return the formatted local time string
  };

  const createSubTasksInstanceRequest = () => {
    const subTasksInstanceRequest = [];

    // Iterate over each row in selectedCheckBoxRowData
    selectedCheckBoxRowData.forEach((row) => {
      // Build the request object for each row
      const requestObject = {
        subTaskInstanceId: row.subTaskInstanceId.value, // Extract subTaskInstanceId
        updateSubTaskInstanceRequest: {
          status: 'COMPLETED', // Set a static status
          actionDetails: {
            kmlId: row.kmlId.value, // Extract kmlId from the row
            designedState: row.designedState.value, // Extract designedState
            previousState: row.previousState.value, // Extract previousState
            currentState: row.currentState.value, // Extract currentState
          },
          user: authUserResponse?.email_id, // Extract email_id from authUserResponse
        },
      };

      // Append the object to the subTasksInstanceRequest array
      subTasksInstanceRequest.push(requestObject);
    });

    return subTasksInstanceRequest;
  };

  const callResolveAPI = () => {
    const id = taskInstanceId?.value?.taskInstanceId?.value;
    setLoading(true);
    const selectedKmlDataToBePassed = createSubTasksInstanceRequest();
    const body = {
      status: 'COMPLETED',
      actionDetails: {
        alertStatus: 'Resolved',
      },
      user: authUserResponse?.email_id,
      subTasksInstanceRequest: selectedKmlDataToBePassed,
    };

    ResolveAlert(getResolveAlertUrl(id), body)
      .then((res) => {
        if (res?.data?.status === 'COMPLETED') {
          handleSuccess('Alert resolved.');
          setDegradeTicketResolved(true);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const isAllChecked =
    Array.isArray(checkboxState) &&
    checkboxState.length > 0 &&
    checkboxState.every(Boolean);

  const onSaveDegradeServiceModal = () => {
    const isTimeValid = validateTime();
    const validISTFormat = convertToIST(
      selectedHour,
      selectedMinute,
      selectedPeriod,
    );
    if (isTimeValid) {
      //call API here and if success, send success msg to genericModal & if fails send error msg.
      const body = {
        alertId: alertMessageId,
        deliveryNode: hubId, //hubId
        serviceAreas: selectedKmlIds, //kml_id's
        extentId: selectedExtendOfDegradation,
        endTime: validISTFormat,
        reasonId: selectedReasonOfDegradation,
        triggeredBy: authUserResponse?.email_id,
      };
      setDisableDegradeModalSave(true);
      degradeService(degradeServiceUrl(), body)
        .then((res) => {
          const data = res;
          if (data?.statusCode === '200') {
            setSuccErrModal(true);
            setDegradeSuccess(true);
            callResolveAPI();
            setDisableDegradeModalSave(false);
          }
        })
        .catch((e) => {
          setSuccErrModal(true);
          setDegradeSuccess(false);
          setDegradeFailureMsg(e?.data?.exception);
          setDisableDegradeModalSave(false);
        });
    } else {
      return;
    }
  };

  const handleExtendDegradation = (e) => {
    setSelectedExtendOfDegradation(e.target.value);
  };

  const handleDegradationReasonSelection = (e) => {
    setSelectedReasonOfDegradation(e.target.value);
  };

  const handleSuccErrModalclose = () => {
    setSuccErrModal(false);
    setDegradeServiceModal(false);
  };

  const setHour = (e) => {
    const value = e.target.value;

    // Allow the user to clear the input
    if (value === '0' || value === '') {
      setSelectedHour(''); // Clear the input without showing an alert
      clearTimeout(timeoutRef.current); // Clear any ongoing timeout
      return;
    }

    // Parse the input value to an integer
    const selectedHour = parseInt(value, 10);

    // Skip validation if the input is incomplete or invalid
    if (!isNaN(selectedHour)) {
      // If the length is greater than 2, prevent the user from entering more digits
      if (value.length > 2) {
        return; // Prevent entering the third digit
      }

      // Check if the selected hour is within the valid range (1-12)
      if (selectedHour >= 1 && selectedHour <= 12) {
        setSelectedHour(value); // Set the value as the user types

        // Clear any previous timeout
        clearTimeout(timeoutRef.current);

        // Set a timeout to pad the value with 0 if only one digit is entered
        timeoutRef.current = setTimeout(() => {
          if (value.length === 1) {
            setSelectedHour(value.padStart(2, '0')); // Pad with a leading zero if only one digit
          }
        }, 500); // Delay of 500 milliseconds
      } else if (value.length >= 2) {
        // Only alert when a full value is entered
        alert('Invalid input: Hour must be between 1 and 12.');
      }
    } else if (value.length >= 2) {
      alert('Invalid input: Hour must be a number.');
    }
  };

  const setMinute = (e) => {
    const value = e.target.value;

    // Allow the user to clear the input
    if (value === '' || value === '0') {
      setSelectedMinute(''); // Clear the input without showing any value
      clearTimeout(timeoutRef.current); // Clear any ongoing timeout
      return;
    }

    // Parse the input value to an integer
    const selectedMinute = parseInt(value, 10);

    // Validate input only if it's a valid number
    if (!isNaN(selectedMinute)) {
      // If the length is greater than 2, prevent the user from entering more digits
      if (value.length > 2) {
        return; // Prevent entering the third digit
      }

      // Check if the selected minute is within the valid range (0-59)
      if (selectedMinute >= 0 && selectedMinute <= 59) {
        setSelectedMinute(value); // Set the value as the user types

        // Clear any previous timeout
        clearTimeout(timeoutRef.current);

        // Set a timeout to pad the value with 0 if only one digit is entered
        timeoutRef.current = setTimeout(() => {
          if (value.length === 1) {
            setSelectedMinute(value.padStart(2, '0')); // Pad with a leading zero if only one digit
          }
        }, 1000); // Delay of 500 milliseconds
      } else if (value.length >= 2) {
        alert('Invalid input: Minute must be between 0 and 59.');
      }
    } else if (value.length >= 2) {
      alert('Invalid input: Minute must be a number.');
    }
  };

  // Create a ref for the timeout
  const timeoutRef = useRef(null);

  const setPeriod = (e) => {
    const selectedPeriod = e.target.value;
    const currentHour = new Date().getHours(); // Get the current hour (24-hour format)
    const adjustedCurrentHour =
      currentHour > 12 ? currentHour - 12 : currentHour;
    const currentPeriod = currentHour >= 12 ? 'PM' : 'AM';

    // If the user tries to set period to AM/PM when it's not allowed
    if (
      selectedPeriod === 'AM' &&
      currentPeriod === 'PM' &&
      selectedHour <= adjustedCurrentHour
    ) {
      alert('Cannot switch to AM, as the current time is in PM.');
      return;
    } else {
      setSelectedPeriod(selectedPeriod);
    }
  };

  // Validate Time
  const validateTime = () => {
    const currentTime = new Date();

    // Check if the selected hour and minute are provided
    if (!selectedHour || !selectedMinute || !selectedPeriod) {
      alert('Please select a complete time.');
      return;
    }

    // Convert selected time to 24-hour format
    let selectedHourNumber = parseInt(selectedHour, 10);
    if (selectedPeriod === 'PM' && selectedHourNumber < 12) {
      selectedHourNumber += 12; // Convert PM to 24-hour format
    } else if (selectedPeriod === 'AM' && selectedHourNumber === 12) {
      selectedHourNumber = 0; // Handle 12 AM case
    }

    // Create date objects for the current time and selected time
    const selectedTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      selectedHourNumber,
      parseInt(selectedMinute, 10),
    );

    // Compare times
    if (selectedTime < currentTime) {
      alert('Cannot select a past time.');
      return false;
    }

    return true;
  };

  useEffect(() => {
    const currentTime = new Date();
    const futureTime = new Date(currentTime.getTime() + 30 * 60000); // Add 30 minutes

    const defaultHour = futureTime.getHours();
    const defaultMinute = futureTime.getMinutes();
    const period = defaultHour >= 12 ? 'PM' : 'AM';
    const adjustedHour = defaultHour > 12 ? defaultHour - 12 : defaultHour;

    setSelectedHour(adjustedHour);
    setSelectedMinute(defaultMinute);
    setSelectedPeriod(period);
  }, []);

  useEffect(() => {
    getCardData();
    getCardNames();
    getFilters();
  }, []);

  const isValidSuggestedAction =
    suggestedAction &&
    suggestedAction?.value !== undefined &&
    Object.keys(suggestedAction).length > 0;

  return (
    <div className={styles.dashboard_container}>
      <If condition={degradeServiceModal}>
        <DegradeServiceModal
          open={degradeServiceModal}
          selectedExtendOfDegradation={selectedExtendOfDegradation}
          extendOfDegradation={extendOfDegradation}
          reasonsOfDegradation={reasonsOfDegradation}
          selectedReasonOfDegradation={selectedReasonOfDegradation}
          onCloseDegradeServiceModal={onCloseDegradeServiceModal}
          onSaveDegradeServiceModal={onSaveDegradeServiceModal}
          handleExtendDegradation={handleExtendDegradation}
          handleDegradationReasonSelection={handleDegradationReasonSelection}
          hour={selectedHour}
          minute={selectedMinute}
          period={selectedPeriod}
          setHour={setHour}
          setMinute={setMinute}
          setPeriod={setPeriod}
          disableDegradeModalSave={disableDegradeModalSave}
        />
      </If>
      <If condition={succErrModal}>
        <GenericModal
          open={succErrModal}
          onClose={handleSuccErrModalclose}
          title={degradeSuccess ? DEGRADE_SUCCESS_MSG : degradeFailureMsg}
          resolveSuccess={degradeSuccess}
          onSuccessResolveOkClick={handleSuccErrModalclose}
        />
      </If>

      <div className={styles.active_alert_container}>
        <If condition={cardData.length && cardNames.length}>
          <div>
            <CardComponent
              cardData={cardData}
              cardNames={cardNames}
              accessibilityKey={accessibilityKey}
              handleTabSelect={handleTabSelect}
              selectedTab={selectedTab}
            />
          </div>

          <Else />
          <div className={styles.card_no_data}>No Data Available!</div>
        </If>

        <div className={styles.actionable_container}>
          <div className={styles.subcontainer_bar}>
            <div className={styles.issue_txt}>
              {issueTypeLabel} ({totalCount ? totalCount : 0})
            </div>
            <div className={styles.filter_style}>
              {/* <button onClick={handleDegradeService}>click me!</button> */}
              <div className={styles.filter_flex}>
                {filters.length > 0 &&
                  filters.map((each) => (
                    <LsFilter
                      key={each.type}
                      options={each.options}
                      placeholder={each.type}
                      type={each.type}
                      onUpdate={(values) =>
                        handleUpdateFilters(values, each.type)
                      }
                      values={appliedFilters[each.type] || []}
                      appliedFilters={appliedFilters}
                      dependentOn={getDependentOn(each.type)}
                      dependentOnKey={getDependentOnKey(each.type)}
                      className={styles.filtr_width}
                    />
                  ))}
              </div>
              <>
                <FormControl>
                  <Select
                    classes={{ select: styles.custom_select }}
                    value={issueType}
                    name="issueFilter"
                    onChange={handleIssueFilter}>
                    {ISSUES_FILTER?.options.map((option, index) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={callLeftCardDataAndDetailAPI}
                  onClick={callLeftCardDataAndDetailAPI}
                  className={styles.refresh_box}>
                  <RefreshIcon />
                </div>
              </>
            </div>
          </div>
          {/* <If condition={loading}>
            <Spinner className={styles.custom_style_spinner} />
          </If> */}

          <If condition={tableRow?.length}>
            <div className={styles.main_container}>
              <div style={{ position: 'relative', minHeight: '300px' }}>
                <div
                  className={styles.data_card_component_scroll}
                  id="scrollableContainer">
                  {tableRow?.map((val, index) => (
                    <div key={index} id={`dataCard_${index}`}>
                      <DataCardComponent
                        ref={leftCardRef}
                        onCardCollapsible={() => {
                          onCardCollapsible(index, val);
                        }}
                        handleKeyPress={(e) => {
                          e.stopPropagation();
                        }}
                        data={val}
                        currentCardIndex={currentCardIndex}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.card_pagination}>
                  <TablePagination
                    className={styles.pagination_internal_css}
                    rowsPerPageOptions={[50, 100, 200]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={paginationVal}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div>
                <If condition={rightContainerLoading}>
                  <Spinner className={styles.custom_style_spinner} />
                  {/* <></> */}
                  <Else />
                  <div className={styles.right_container}>
                    <If condition={alertDetailAPIStatus}>
                      <div className={styles.right_sub_container}>
                        <If
                          condition={
                            orderDetails && Object.keys(orderDetails).length > 0
                          }>
                          <div className={styles.right_side_box1_style}>
                            <IssueDetailComponent data={orderDetails} />
                          </div>
                        </If>

                        <div>
                          <If condition={isValidSuggestedAction}>
                            <SuggestedActionComponent
                              showActionStatus={showActionStatus}
                              showIssueTypeDropdown={showIssueTypeDropdown}
                              showSDActionTaken={showSDActionTaken}
                              taskInstanceId={taskInstanceId?.value}
                              data={suggestedAction}
                              sliceResolvedAlertCard={sliceResolvedAlertCard}
                              selectedCardIssueType={selectedCardIssueType}
                              handleDegradeService={handleDegradeService}
                              handleCheckboxChange={handleCheckboxChange}
                              handleSelectAllCheckbox={handleSelectAllCheckbox}
                              checkboxState={checkboxState}
                              isAllChecked={isAllChecked}
                              degradeTicketResolved={degradeTicketResolved}
                            />
                          </If>
                        </div>
                        <If
                          condition={
                            selectedTab === 'INVENTORY' ||
                            selectedCardIssueType === 'INVENTORY'
                          }>
                          <If condition={productOutOfStockDetailData.length}>
                            <div className={styles.modification_detail_box}>
                              <div className={styles.modification_txt}>
                                Product Out Of Stock Details
                              </div>

                              <ActionTrailComponent
                                tableData={productOutOfStockDetailData}
                                columnHeaders={productOutOfStockDetailHeader}
                              />
                            </div>
                          </If>
                          <If condition={shortfallDetailData.length}>
                            <div className={styles.modification_detail_box}>
                              <div className={styles.modification_txt}>
                                Product Shortfall Details
                              </div>

                              <ActionTrailComponent
                                tableData={shortfallDetailData}
                                columnHeaders={shortfallDetailHeaders}
                              />
                            </div>
                          </If>
                          <If condition={iSModificationDetailRowData.length}>
                            <div className={styles.modification_detail_box}>
                              <div className={styles.modification_txt}>
                                Modification Details
                              </div>

                              <ActionTrailComponent
                                tableData={iSModificationDetailRowData}
                                columnHeaders={iSModificationDetailHeading}
                              />
                            </div>
                          </If>
                        </If>
                        <If
                          condition={
                            (selectedTab === 'LAST_MILE' ||
                              selectedCardIssueType === 'LAST_MILE') &&
                            lastMileTableData.length > 0
                          }>
                          <div className={styles.modification_detail_box}>
                            <div className={styles.modification_txt}>
                              Impacted Trip
                            </div>
                            <div
                              className={
                                lastMileMapFields.length > 0
                                  ? styles.impacted_trip_container_flex
                                  : styles.impacted_trip_container_no_flex
                              }>
                              <If
                                condition={
                                  lastMileMapFields &&
                                  lastMileMapFields.length > 0
                                }>
                                <DEMapView
                                  mapField={lastMileMapFields}
                                  selectedCardMsgId={selectedCardMsgId}
                                />
                              </If>
                              <If
                                condition={
                                  lastMileTableData && lastMileTableData.length
                                }>
                                <div>
                                  <LastMileMapActionTrailComponent
                                    tableData={lastMileTableData}
                                    columnHeaders={lastMileTableHeader}
                                  />
                                </div>
                              </If>
                            </div>
                          </div>
                        </If>
                        <div>
                          <If
                            condition={
                              linkedTicketTableData &&
                              linkedTicketTableData.length
                            }>
                            <div
                              className={styles.right_side_box2_style}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                              }}
                              role="button"
                              tabIndex={0}>
                              <div className={styles.flex_css}>
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={onLinkedTicketCollapsible}
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className={styles.suggestion_action_txt}>
                                  Linked Tickets
                                </div>
                                <div>
                                  <DownArrowIcon
                                    onClick={onLinkedTicketCollapsible}
                                  />
                                </div>
                              </div>
                              {showLinkedTickets && (
                                <If condition={linkedTicketTableData.length}>
                                  <LinkedTicketTable
                                    tableData={linkedTicketTableData}
                                    columnHeaders={linkedTicketHeading}
                                  />
                                  <Else />
                                  <div className={styles.no_action_trail_data}>
                                    No Data Available!
                                  </div>
                                </If>
                              )}
                              <div ref={linkedTicketRef}></div>
                            </div>
                          </If>
                        </div>
                        <div>
                          <div
                            className={styles.right_side_box2_style}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                            onClick={onActionTrailCollapsible}
                            role="button"
                            tabIndex={0}>
                            <div className={styles.flex_css}>
                              <div className={styles.suggestion_action_txt}>
                                Action Trail
                              </div>
                              <div>
                                <DownArrowIcon onClick={handleScrollBottom} />
                              </div>
                            </div>
                            {showActionTrail && (
                              <If condition={actionTrailData.length}>
                                <ActionTrailComponent
                                  tableData={actionTrailData}
                                  columnHeaders={actionTrailHeading}
                                />
                                <Else />
                                <div className={styles.no_action_trail_data}>
                                  No Data Available!
                                </div>
                              </If>
                            )}
                            <div ref={actionTrailRef}></div>
                          </div>
                        </div>
                      </div>
                      <Else />
                      <div className={styles.detail_unavailable}>
                        Sorry, details not available for the selected alert.
                      </div>
                    </If>
                  </div>
                </If>
              </div>
            </div>
          </If>

          <If condition={errorCase || tableRow?.length === 0}>
            <div>
              <div className={styles.no_data_txt}>Sorry! No Data Available</div>
              <div className={styles.no_data_subtxt}>
                Nothing to worry about, just try again
              </div>
              <div className={styles.refresh_btn_con}>
                <button
                  onClick={handleRefreshClick}
                  className={styles.refresh_btn}>
                  Refresh
                </button>
              </div>
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default BrechManagementDasboardTab;
