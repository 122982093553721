import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
const StyledTabs = styled((props) => (
  <Tabs
    style={{ backgroundColor: '' }}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    height: '3px',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#C92A2A',
  },
});

const IconTabs = (props) => {
  return (
    <StyledTabs
      centered={props.centered}
      TabIndicatorProps={{ style: { background: '#C51F47' } }}
      value={props.tabValue}
      onChange={props.handleChange}
      variant={props.standard ? 'standard' : 'scrollable'}
      scrollButtons={!props.standard}
      aria-label="scrollable auto tabs example">
      {props.tabList.map((value, index) => {
        return (
          <Tab
            key={`${props.getTabTitle(value)}_${index}`}
            label={props.getTabTitle(value)}
            sx={{
              textTransform: 'none',
              fontSize: 14,
              fontWeight: '400',
            }}
            icon={
              value?.colour_code && (
                <CircleRoundedIcon style={{ color: value?.colour_code }} />
              )
            }
            iconPosition="start"
          />
        );
      })}
    </StyledTabs>
  );
};
export default IconTabs;
