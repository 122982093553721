import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import styles from './style.module.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
import LsFilter from '../../common/Select/Filter';

const HourlyTrendComponent = ({
  data,
  filters,
  handleUpdateFilters,
  appliedFilters,
  getDependentOnKey,
  getDependentOn,
  handleHourlyTrendDateFilter,
  hourlyTrendDate,
  identifier,
  hourlyTrendActiveFilter,
}) => {
  Chart.register(...registerables);

  const [processedData, setProcessedData] = useState(null);

  useEffect(() => {
    if (data && data.datasets && data.labels) {
      const staticLabels = [0, ...Array.from({ length: 16 }, (_, i) => i + 8)];
      const newData = { labels: staticLabels, datasets: [] };

      data.datasets.forEach((dataset) => {
        const dataMap = dataset.data.reduce((acc, value, index) => {
          acc[data.labels[index]] = value;
          return acc;
        }, {});

        const mappedData = staticLabels.map((hour) =>
          dataMap[hour] !== undefined ? dataMap[hour] : null,
        );

        newData.datasets.push({
          ...dataset,
          data: mappedData,
        });
      });

      setProcessedData(newData);
    }
  }, [data]);

  if (
    !processedData ||
    !processedData.datasets ||
    processedData.datasets.length === 0
  ) {
    return null;
  }

  if (!data || !data.datasets || data.datasets.length === 0) {
    return null;
  }

  const allDataPoints = data.datasets.flatMap((dataset) => dataset.data);
  const maxValue = Math.max(...allDataPoints);
  const stepSize = Math.max(1, Math.ceil(maxValue / 5));
  const max = Math.ceil(maxValue / stepSize) * stepSize;

  const lineGraphOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hour',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Alerts Generated',
        },
        min: 0,
        max: max,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <div>
      <div className={styles.line_graph_container}>
        <div className={styles.hourly_trend_title}>
          <div className={styles.hourly_trend_txt}>Hourly Trend</div>
          <div className={styles.city_hub_date_filter}>
            <div className={styles.filter_flex}>
              {filters.length > 0 &&
                filters.map((each) => {
                  const isDisabled =
                    (each.type === 'CITY' &&
                      hourlyTrendActiveFilter === 'HUB') ||
                    (each.type === 'HUB' && hourlyTrendActiveFilter === 'CITY');
                  return (
                    <LsFilter
                      key={each.type}
                      options={each.options}
                      placeholder={each.type}
                      type={each.type}
                      onUpdate={(values) =>
                        handleUpdateFilters(values, each.type, identifier)
                      }
                      values={appliedFilters[each.type] || []}
                      appliedFilters={appliedFilters}
                      dependentOn={getDependentOn(each.type)}
                      dependentOnKey={getDependentOnKey(each.type)}
                      className={`${styles.filtr_width} ${isDisabled ? styles.filtr_width_disabled : ''}`}
                      // disabled={isDisabled}
                    />
                  );
                })}
            </div>
            <div style={{ marginLeft: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={hourlyTrendDate}
                  onChange={handleHourlyTrendDateFilter}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <>
          <Line
            width={100}
            height={30}
            data={processedData}
            options={lineGraphOptions}
          />
        </>
      </div>
    </div>
  );
};

export default HourlyTrendComponent;
