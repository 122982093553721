import styles from './styles.module.scss';
export const getTitle = (rule) => {
  const name = rule?.title;
  if (name) return name.toUpperCase();
};

export const ListTableConfig = {
  1: [
    {
      id: 'product_name',
      title: 'Product Name',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
      style: styles.column_date,
    },

    {
      id: 'hub_name',
      title: 'Hub Name',
    },
    {
      id: 'sla_express',
      title: 'SLA Express',
    },
    {
      id: 'delivered_datetime',
      title: 'Delivered At',
      style: styles.column_date_time,
    },
    {
      id: 'city_name',
      title: 'City ',
    },

    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'created_at',
      title: 'Created At',
      style: styles.column_date_time,
    },
    {
      id: 'expected_cut_off_delivery_time',
      title: ' Cut Off Delivery Status',
    },
    {
      id: 'breached_order_time',
      title: 'Breached Order Time',
      style: styles.column_date_time,
    },
    {
      id: 'delivery_slot',
      title: 'Delivery Slot',
      style: styles.column_date,
    },
    {
      id: 'order_id',
      title: 'Order Id',
    },
  ],
  2: [
    {
      id: 'product_name',
      title: 'Product Name',
    },
    {
      id: 'rule_audit_id',
      title: 'Rule Audit Id',
    },
    {
      id: 'city_name',
      title: 'City Name',
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
    },
    {
      id: 'total_customer_inventory',
      title: 'Total Customer  Inventory',
    },
    {
      id: 'customer_inventory_variance',
      title: ' Inventory Variance',
    },
    {
      id: 'customer_orders_quantity',
      title: 'Customer Order Quantity',
    },
    {
      id: 'customer_hub_physical_closing_quantity',
      title: 'hub Physical Closing Quantity',
    },
    {
      id: 'superhub_to_customer_hub_inward_quantity',
      title: 'Superhub Physical Closing Quantity',
    },
    {
      id: 'created_at',
      title: 'Created  At',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'hub_name',
      title: 'Hub Name',
    },
  ],
  3: [
    {
      id: 'product_name',
      title: 'Product Name',
      headerClassName: styles.name_column,
    },
    {
      id: 'city_name',
      title: 'City Name',
    },
    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'quantity',
      title: 'Quantity',
    },
    {
      id: 'created_at',
      title: 'Created At',
      style: styles.column_date_time,
    },
    {
      id: 'sale_date',
      title: 'Sale Date',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'hub_name',
      title: 'Hub Name',
    },
  ],
  12: [
    {
      id: 'product_name',
      title: 'Product Name',
      data_type: 'STRING',
      style: styles.column_date_time,
    },
    { id: 'category', title: 'Category', data_type: 'STRING' },
    {
      id: 'sale_date',
      title: 'Sale Date',
      data_type: 'STRING',
      style: styles.column_date,
    },
    { id: 'hub_id', title: 'Hub Id', data_type: 'INTEGER' },
    { id: 'hub_name', title: 'Hub Name', data_type: 'STRING' },
    { id: 'city_id', title: 'City Id', data_type: 'INTEGER' },
    { id: 'city_name', title: 'City Name', data_type: 'STRING' },
    { id: 'order_id', title: 'Order Id', data_type: 'STRING' },
    { id: 'customer_id', title: 'Customer Id', data_type: 'STRING' },
    { id: 'sheduled_date', title: 'Scheduled Date', data_type: 'STRING' },
    {
      id: 'delivery_slot',
      title: 'Delivery Slot',
      data_type: 'STRING',
      style: styles.column_date,
    },
    { id: 'status', title: 'Status', data_type: 'STRING' },
    {
      id: 'delivered_datetime',
      title: 'Delivered Datetime',
      data_type: 'DATETIME',
    },
    {
      id: 'creation_datetime',
      title: 'Creation Datetime',
      data_type: 'DATETIME',
    },
    { id: 'express', title: 'Express Order', data_type: 'STRING' },
    { id: 'sla_express', title: 'SLA for express order', data_type: 'INTEGER' },
    {
      id: 'expected_cut_off_delivery_time',
      title: 'Expected Cutoff Time For Delivery',
      data_type: 'LONG',
      style: styles.column_date_time,
    },

    {
      id: 'breached_order_time_seconds',
      title: 'Breached Order Time ',
      data_type: 'LONG',
      style: styles.column_date_time,
    },

    {
      id: 'breached_order_time',
      title: 'Breached Order Time',
      data_type: 'STRING',
      style: styles.column_date_time,
    },

    {
      id: 'customer_orders_quantity',
      title: 'Customer Order Quantity',
      data_type: 'INTEGER',
    },
    {
      id: 'created_at',
      title: 'Created At',
      data_type: 'TIMESTAMP',
      style: styles.column_date_time,
    },
    { id: 'rule_audit_id', title: 'Rule Execution Id', data_type: 'LONG' },
  ],
};
