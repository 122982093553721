import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import CheckboxCheckedIcon from '../../assets/checkbox_checked_icon.svg';
import CheckboxUnCheckedIcon from '../../assets/checkbox_unchecked_icon.svg';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '14px',
    color: '#575757',
  },
  link: {
    color: '#1C7ED6',
  },
  divider: {
    marginTop: '16px',
  },
}));
const CheckBoxComponent = (props) => {
  const classes = useStyles();
  const { checkedArray, arrayList, handleChange, title, disabled } = props;
  let list = arrayList || [];
  const [currentList, setCurrentList] = useState([...list.slice(0, 4)]);
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    setCurrentList([...list.slice(0, 4 * currentIndex)]);
  }, [arrayList]);

  const listMore = () => {
    setCurrentList([...list.slice(0, 4 * (currentIndex + 1))]);
    setCurrentIndex(currentIndex + 1);
  };
  const isMoreAvailable = () => {
    return !(Math.ceil(list.length / 4) === currentIndex);
  };
  const listLess = () => {
    setCurrentList([...list.slice(0, 4 * 1)]);
    setCurrentIndex(1);
  };

  return (
    <>
      {currentList &&
        currentList.map((item) => {
          return (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    type="checkbox"
                    value={item}
                    id={item}
                    onChange={(event) => handleChange(event, title)}
                    checked={[...checkedArray].includes(item.toString())}
                    icon={<CheckboxUnCheckedIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                    disabled={disabled}
                  />
                }
                classes={{ label: classes.label }}
                label={item}
              />
            </FormGroup>
          );
        })}
      {list.length > 0 &&
        (isMoreAvailable() ? (
          <Link
            underline="hover"
            classes={{ root: classes.link }}
            onClick={listMore}>
            See More
          </Link>
        ) : (
          <Link
            underline="hover"
            classes={{ root: classes.link }}
            onClick={listLess}>
            See Less
          </Link>
        ))}
      <Divider classes={{ root: classes.divider }} />
    </>
  );
};
export default CheckBoxComponent;
