import React, { useCallback, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import { getTitle } from './constant';
import {
  fetchAllJobConfig,
  fetchJobConfigData,
  fetchJobConfigDataS3Url,
  fetchJobConfigMetaData,
} from '../../Utils/endPoints';
import {
  getAllJobConfigUrl,
  getDataForJobConfig,
  getJobConfigMetaData,
  getS3UrlForTableData,
} from '../../Utils/serviceUrls';
import { makeStyles } from '@mui/styles';
import styles from './styles.module.scss';
import Spinner from '../../common/Spinner';
import Tabs from '../../common/TabsComponent/TabsComponent';
import Link from '@mui/material/Link';
import MuiTableComponent from '../../common/TableComponent/MuiTableComponent';
import CustomiseModal from '../../common/Modal/Modal';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import Sidebar from '../../common/SidebarComponent';
import ActiveFilters from '../../common/ChipsComponent/ChipsComponent';
import FilterIcon from '../../assets/filter_icon.svg';
import { Download } from '@mui/icons-material';
import { addNotification } from '../../redux/Snackbar/actions';
import { useDispatch } from 'react-redux';
import useToggle from '../../Utils/hooks/useToggle';
import SearchBox from '../../common/DropDownComponent/DropDownComponent';
import LastEventTime from '../../common/LastAlertTime';

const useStyles = makeStyles(() => ({
  root: {
    '&[disabled]': {
      color: 'grey',
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));
const initialState = {
  tabValue: 0,
  columns: [],
  filterList: [],
  pageNo: 0,
  rowsPerPage: 50,
  searchObject: {},
  checkedColumns: [],
  selectedColumn: '',
  selectedOrder: 'ASC',
  isJobConfig: true,
};
const initialCriteria = {
  PRODUCT: 'id',
  HUB: 'id',
  CITY: 'id',
};
const parseMethod = (value) => {
  return Number(value) || 0;
};

const JobConfigList = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [jobConfigDetails, setJobConfigDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingJobConfigList, setLoadingJobConfigList] = useState(false);
  const [metaLoading, setMetaLoading] = useState(false);

  const [selectedJobConfig, setSelectedJobConfig] = useState(
    queryParams?.selectedJobConfig
      ? {
          id: queryParams.selectedJobConfig,
          jobLink: 'https://flink.internal.prod.licious.app/',
          confluenceLink:
            'https://licious.atlassian.net/wiki/spaces/CP/pages/2133229577/API+Contracts+Notification+Service',
        }
      : null,
  );

  const [tabList, setTabList] = useState([]);
  const [metaDataList, setMetaDataList] = useState({});
  const [isColumn, setIsColumn] = useState(false);
  const getQueryParams = () => {
    return {
      pageNo: parseMethod(queryParams?.pageNo || initialState.selectedColumn),
      rowsPerPage: parseMethod(
        queryParams?.rowsPerPage || initialState.rowsPerPage,
      ),
      tabValue: parseMethod(queryParams?.tabValue || initialState.tabValue),
      selectedColumn:
        queryParams?.selectedColumn || initialState.selectedColumn,
      selectedOrder: queryParams?.selectedOrder || initialState.selectedOrder,
    };
  };
  const [selectedTab, setSelectedTab] = useState({
    ...initialState,
    ...getQueryParams(),
  });
  const [isSidebarOpen, setIsSidebarOpen] = useToggle();
  const [selectedCriteria, setSelectedCriteria] = useState(initialCriteria);

  const {
    pageNo,
    rowsPerPage,
    selectedOrder,
    searchObject,
    selectedColumn,
    checkedColumns,
    tabValue,
    filterList,
    columns,
    isJobConfig,
  } = selectedTab;

  useEffect(() => handleUrlParams(), [selectedTab, selectedJobConfig]);

  useEffect(() => getJobConfigDetails(), []);

  useEffect(() => {
    if (isJobConfig) getJobConfigById();
  }, [
    pageNo,
    rowsPerPage,
    selectedOrder,
    searchObject,
    selectedColumn,
    tabValue,
  ]);

  const handleSorting = (column) => {
    let order = selectedOrder;
    if (selectedOrder === 'DESC') order = 'ASC';
    else order = 'DESC';
    setSelectedTab({
      ...selectedTab,
      isJobConfig: true,
      selectedColumn: column,
      selectedOrder: order,
    });
  };

  const columnList =
    React.useMemo(() => {
      if (selectedTab) return checkedColumns;
      else return [];
    }, [selectedTab]) || [];

  const getJobConfigDetails = () => {
    setMetaLoading(true);
    setLoadingJobConfigList(true);
    fetchAllJobConfig(getAllJobConfigUrl())
      .then((res) => {
        setLoadingJobConfigList(false);
        setMetaLoading(false);
        const { data } = res;
        setJobConfigDetails(data);
        const selectedObject = data.find(
          (each) => each.id === parseMethod(queryParams.selectedJobConfig),
        );
        onSelectionChange('', selectedObject || data[0], true);
      })
      .catch((e) => {
        dispatch(
          addNotification({
            level: 'error',
            message: e?.data?.errors?.[0]?.code,
          }),
        );
        setMetaLoading(false);
      });
  };
  const handleCheckBoxArray = (array, newValue) => {
    let index = array.findIndex((data) => data == newValue);
    if (index !== -1) {
      array.splice(index, 1);
      array = [newValue, ...array];
    }
    return array;
  };

  const updateCategoryList = (index, newValue) => {
    let array = handleCheckBoxArray(filterList[index]?.column_values, newValue);
    filterList[index] = { ...filterList[index], column_values: array };
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateCityList = (index, newValue) => {
    let array =
      selectedCriteria.CITY == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.city_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.city_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.CITY == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          city_name_filter: {
            ...filterList[index]?.city_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          city_id_filter: {
            ...filterList[index]?.city_id_filter,
            column_values: array,
          },
        });

    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateHubList = (index, newValue) => {
    let array =
      selectedCriteria.HUB == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.hub_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.hub_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.HUB == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          hub_name_filter: {
            ...filterList[index]?.hub_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          hub_id_filter: {
            ...filterList[index]?.hub_id_filter,
            column_values: array,
          },
        });
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };
  const updateProductList = (index, newValue) => {
    let array =
      selectedCriteria.PRODUCT == 'name'
        ? handleCheckBoxArray(
            filterList[index]?.product_name_filter?.column_values,
            newValue,
          )
        : handleCheckBoxArray(
            filterList[index]?.product_id_filter?.column_values,
            newValue,
          );
    selectedCriteria.PRODUCT == 'name'
      ? (filterList[index] = {
          ...filterList[index],
          product_name_filter: {
            ...filterList[index]?.product_name_filter,
            column_values: array,
          },
        })
      : (filterList[index] = {
          ...filterList[index],
          product_id_filter: {
            ...filterList[index]?.product_id_filter,
            column_values: array,
          },
        });
    setSelectedTab({ ...selectedTab, filterList: filterList });
  };

  const onChange = (event, newValue, type) => {
    let index = filterList.findIndex((data) => data.type === type);
    if (index !== -1) {
      switch (type) {
        case 'CATEGORY':
          return updateCategoryList(index, newValue);
        case 'PRODUCT':
          return updateProductList(index, newValue);
        case 'CITY':
          return updateCityList(index, newValue);
        case 'HUB':
          return updateHubList(index, newValue);
        default:
          return updateCategoryList(index, newValue);
      }
    }
  };

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.code,
      }),
    );
    setMetaLoading(false);
    setLoading(false);
    setSelectedTab({
      ...initialState,
      columns: columns,
      checkedColumns: checkedColumns,
      filterList: filterList,
      tabValue: tabValue,
    });
  };
  const handlegetAllError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.code,
      }),
    );
    setTabList([]);
    setLoading(false);
    setMetaLoading(false);
    setSelectedTab(initialState);
  };

  const handleCheckBoxChange = (event, title) => {
    let checkedArray = searchObject[title] || [];
    let { value } = event.target;
    value = value.toString();
    checkedArray = [...checkedArray, value];
    if (searchObject[title]?.includes(value)) {
      checkedArray = checkedArray.filter((data) => data !== value);
    }
    setSelectedTab({
      ...selectedTab,
      isJobConfig: true,
      searchObject: {
        ...searchObject,
        [title]: checkedArray,
      },
    });
  };
  const getQueryDetails = () => {
    let { searchObject } = selectedTab;
    let jsonObject = {};
    let columnList = [];
    searchObject = Object.keys(searchObject).reduce((accumulator, key) => {
      if (searchObject[key]?.length !== 0) {
        accumulator[key] = searchObject[key];
      }
      return accumulator;
    }, {});
    let length = getLength(searchObject);
    for (let key in searchObject) {
      if (length === 1) {
        jsonObject = {
          type: 'IN',
          columnName: key,
          columnValues: searchObject[key],
        };
      } else {
        columnList.push({
          type: 'IN',
          columnName: key,
          columnValues: searchObject[key],
        });
        jsonObject = {
          type: 'LOGICAL_AND',
          columnName: key,
          columnValues: columnList,
        };
      }
    }
    return jsonObject;
  };
  const getLength = (object) => {
    return Object.keys(object)?.length;
  };

  const getS3RequestObject = (arrayList, tabValue) => {
    let object = {
      database: arrayList[tabValue]?.database,
      table: arrayList[tabValue]?.table,
    };
    if (selectedColumn !== '') {
      object = {
        ...object,
        sortDetails: [
          {
            sortOrder: selectedOrder,
            sortField: selectedColumn,
          },
        ],
      };
    }
    if (getLength(getQueryDetails()) > 0) {
      object = { ...object, queryCondition: getQueryDetails() };
    }
    return object;
  };

  const getRequestObject = (arrayList, tabValue) => {
    let object = {
      database: arrayList[tabValue]?.database,
      table: arrayList[tabValue]?.table,
      pageDetail: {
        pageNo: pageNo,
        pageSize: rowsPerPage,
      },
    };
    if (selectedColumn !== '') {
      object = {
        ...object,
        sortDetails: [
          {
            sortOrder: selectedOrder,
            sortField: selectedColumn,
          },
        ],
      };
    }
    if (getLength(getQueryDetails()) > 0) {
      object = { ...object, queryCondition: getQueryDetails() };
    }
    return [object];
  };

  const getJobConfigById = (
    metaDetails = metaDataList,
    tabValue = selectedTab?.tabValue,
  ) => {
    let jobResultRequest = [];

    if (metaDetails?.id) {
      let length = getArray(metaDetails).length;
      if (length > tabValue) {
        let arrayList = getArray(metaDetails);
        jobResultRequest = getRequestObject(arrayList, tabValue);
        const body = {
          id: metaDetails?.id,
          resultRequest: jobResultRequest,
        };
        setLoading(true);
        fetchJobConfigData(getDataForJobConfig(), body)
          .then((res) => {
            setLoading(false);
            const data = res?.data || [];
            let tabArray = [];
            tabArray = [...arrayList];
            tabArray[tabValue] = [...data][0];
            setTabList(tabArray);
          })
          .catch((e) => {
            handleError(e);
          });
      }
    }
  };

  const getTabTitle = (tab) => {
    if (tab?.title) return tab.title;
  };

  const handleChangePage = (event, newPage) => {
    setSelectedTab({ ...selectedTab, pageNo: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setSelectedTab({
      ...selectedTab,
      pageNo: 0,
      rowsPerPage: +event.target.value,
    });
  };
  const handleRadio = (e, title) => {
    setSelectedCriteria({
      ...selectedCriteria,
      [title]: e.target.value,
    });
  };

  const getArray = (metaList = metaDataList) => {
    const arrayList = [...metaList?.sinkMetaData];
    return arrayList;
  };
  const handleUrlParams = useCallback(() => {
    let searchString = '';
    for (let key in searchObject) {
      searchString = searchString.concat(`${key}=${searchObject[key]}&`);
    }
    searchString = searchString.slice(0, -1);
    const params = {
      selectedJobConfig: selectedJobConfig?.id,
      tabValue,
      pageNo,
      rowsPerPage,
      selectedColumn,
      selectedOrder,
      searchString,
    };
    navigate({
      pathname: '',
      search: `?${createSearchParams(params)}`,
    });
  }, [selectedTab, selectedJobConfig]);

  const handleChange = (event, newValue) => {
    const arrayList = getArray();
    let tabDetails = {
      ...initialState,
      tabValue: newValue,
      columns: arrayList[newValue]?.columnDetails,
      checkedColumns: arrayList[newValue]?.columnDetails,
      filterList: arrayList[newValue]?.baseFilterDetails,
    };
    setSelectedTab(tabDetails);
  };

  const handleMetaDeta = (res, reload = false) => {
    setMetaLoading(false);
    const arrayList = getArray(res?.data);
    const length = arrayList.length;
    const columns =
      arrayList[reload && length > tabValue ? tabValue : 0]?.columnDetails;
    const filterList =
      arrayList[reload && length > tabValue ? tabValue : 0]?.baseFilterDetails;
    let tabDetails = reload
      ? {
          ...initialState,
          ...getQueryParams(),
          columns: columns,
          checkedColumns: columns,
          filterList: filterList,
          isJobConfig: false,
          tabValue: length > tabValue ? tabValue : 0,
        }
      : {
          ...initialState,
          columns: columns,
          checkedColumns: columns,
          filterList: filterList,
          isJobConfig: false,
        };

    getJobConfigById(res?.data, tabDetails.tabValue);
    setMetaDataList(res?.data);
    setSelectedTab(tabDetails);
  };
  const onSelectionChange = (event, newValue, reload = false) => {
    setSelectedJobConfig(newValue ? newValue : null);
    setTabList([]);
    setSelectedTab(
      reload
        ? { ...initialState, isJobConfig: false, ...getQueryParams() }
        : {
            ...initialState,
            isJobConfig: false,
          },
    );
    if (newValue?.id) {
      setMetaLoading(true);
      fetchJobConfigMetaData(getJobConfigMetaData(newValue?.id))
        .then((res) => {
          handleMetaDeta(res, reload);
        })
        .catch((e) => {
          handlegetAllError(e);
        });
    } else {
      setTabList([]);
    }
  };

  const applyColumns = (columnData) => {
    let columnList = columns.filter((data) => columnData.includes(data.key));
    setSelectedTab({ ...selectedTab, checkedColumns: columnList });
    setIsColumn(false);
  };

  const handleCancelColumn = () => {
    setIsColumn(false);
  };
  const handleOpenColumn = () => {
    setIsColumn(true);
  };
  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleIndividualClear = (title) => {
    setSelectedTab({
      isJobConfig: true,
      ...selectedTab,
      searchObject: { ...searchObject, [title]: [] },
    });
  };
  const handleClearAll = () => {
    setSelectedTab({
      ...selectedTab,
      searchObject: {},
      selectedOrder: 'ASC',
      selectedColumn: '',
      isJobConfig: true,
    });
  };
  const handleDownload = (
    metaDetails = metaDataList,
    tabValue = selectedTab?.tabValue,
  ) => {
    console.log('In handleDownload');
    console.log('In metaDetails', metaDataList);
    console.log('In selectedTab', selectedTab?.tabValue);
    console.log('In tabValue', tabValue);
    let s3Url = '';
    if (metaDataList?.id) {
      let length = getArray(metaDataList).length;
      console.log('length ', length);
      if (length > tabValue) {
        let arrayList = getArray(metaDataList);
        let object = {
          database: arrayList[tabValue]?.database,
          table: arrayList[tabValue]?.table,
        };
        console.log('body ', object);
        setLoading(true);
        fetchJobConfigDataS3Url(
          getS3UrlForTableData(object?.database, object?.table),
        )
          .then((res) => {
            setLoading(false);
            console.log('response ', res);
            console.log('response v1 ', res?.data || null);

            s3Url = res?.data || null;
            console.log('In HERE ', s3Url);
            try {
              if (s3Url) {
                console.log('In HERE ', s3Url);
                window.open(s3Url, '_blank');
              }
            } catch (e) {
              dispatch(
                addNotification({
                  level: 'error',
                  message: 'csv is blank!!',
                }),
              );
            }
          })
          .catch((e) => {
            handleError(e);
          });
      }
    }
  };

  // const onSelectionChange = (event, newValue, reload = false) => {
  //   setSelectedJobConfig(newValue ? newValue : null);
  //   setTabList([]);
  //   setSelectedTab(
  //       reload
  //           ? { ...initialState, isJobConfig: false, ...getQueryParams() }
  //           : {
  //             ...initialState,
  //             isJobConfig: false,
  //           },
  //   );
  //   if (newValue?.id) {
  //     setMetaLoading(true);
  //     fetchJobConfigMetaData(getJobConfigMetaData(newValue?.id))
  //         .then((res) => {
  //           handleMetaDeta(res, reload);
  //         })
  //         .catch((e) => {
  //           handlegetAllError(e);
  //         });
  //   } else {
  //     setTabList([]);
  //   }
  // };
  const handleRedirect = (url) => {
    // console.log("url -> ", url)

    try {
      if (url) window.open(url, '_blank');
    } catch (e) {
      dispatch(
        addNotification({
          level: 'error',
          message: 'Failed to redirect to the new URL!',
        }),
      );
    }
  };
  //
  // console.log(metaDataList);
  // console.log("selectedJobConfig");
  // console.log("tabValue", tabValue);
  //     console.log("tabList", tabList);

  return (
    <>
      <div
        className={classNames(
          props.className,
          styles.container,
          isSidebarOpen ? styles.main_container : styles.less_container,
        )}>
        <div className={styles.left_container}>
          {metaLoading && isSidebarOpen ? (
            <Spinner />
          ) : (
            <Sidebar
              filterList={filterList}
              handleSidebar={handleSidebar}
              selectedJobConfig={selectedJobConfig}
              arrayList={jobConfigDetails}
              onSelectionChange={onSelectionChange}
              isSidebarOpen={isSidebarOpen}
              onChange={onChange}
              handleCheckBoxChange={handleCheckBoxChange}
              checkedArrayObject={searchObject}
              loading={loading}
              handleRadio={handleRadio}
              selectedCriteria={selectedCriteria}
            />
          )}
        </div>
        <div className={styles.right_container}>
          <div className={styles.job_config_container}>
            <div className={styles.job_config_select}>
              {!loadingJobConfigList && (
                <div className={styles.search_box_container}>
                  <SearchBox
                    arrayList={jobConfigDetails}
                    onChange={onSelectionChange}
                    value={selectedJobConfig}
                    label="Job Config"
                    placeholder="Select Job Config"
                  />
                </div>
              )}
            </div>

            <div
              className={`${styles.job_config_section} ${styles.rightmost_section}`}>
              {!loadingJobConfigList?.confluenceLink && (
                <div
                  className={`${styles.job_config_detail} ${styles.job_config_detail_link}`}
                  onClick={() =>
                    handleRedirect(selectedJobConfig?.confluenceLink)
                  }
                  aria-hidden="true">
                  <p>Confluence Link</p>
                </div>
              )}

              {!loadingJobConfigList?.jobLink && (
                <div
                  className={`${styles.job_config_detail} ${styles.job_config_detail_link}`}
                  onClick={() => handleRedirect(selectedJobConfig?.jobLink)}
                  aria-hidden="true">
                  <p>Job Url</p>
                </div>
              )}
            </div>
          </div>
          {!metaLoading && (
            <div className={styles.margin_32_px}>
              <Tabs
                tabValue={tabValue}
                handleChange={handleChange}
                tabList={tabList}
                getTabTitle={getTabTitle}
              />
            </div>
          )}
          {loading || metaLoading ? (
            <Spinner />
          ) : (
            <>
              {getLength(searchObject) > 0 && (
                <div>
                  <ActiveFilters
                    filterObject={searchObject}
                    handleIndividualClear={handleIndividualClear}
                  />
                </div>
              )}
              <div className={styles.flex_container}>
                <div
                  className={classNames(
                    styles.flex_1,
                    styles.table_title_container,
                  )}>
                  <p className={styles.title_container}>
                    <span className={styles.title}>
                      {getTitle(tabList[tabValue])}
                    </span>
                    <LastEventTime
                      timestamp={tabList[tabValue]?.lastEventTime}
                    />
                  </p>
                  <Link
                    className={styles.link}
                    underline="hover"
                    onClick={handleOpenColumn}>
                    Customise
                  </Link>
                </div>
                <div className={styles.right_align_items}>
                  {getLength(searchObject) > 0 && (
                    <Link
                      className={styles.link}
                      underline="hover"
                      onClick={handleClearAll}>
                      Clear All
                    </Link>
                  )}
                  <div className={styles.padding_8}>
                    <RefreshOutlined
                      onClick={() => getJobConfigById()}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <p className={styles.padding_8}>
                    count:<span>{tabList[tabValue]?.count}</span>
                  </p>
                  <button
                    className={styles.filter_button}
                    onClick={handleDownload}
                    aria-hidden="false">
                    <p>CSV</p>
                    <Download />
                  </button>
                  <div
                    className={styles.filter_button}
                    onClick={handleSidebar}
                    aria-hidden="true">
                    <p>Filters</p>
                    <FilterIcon />
                  </div>
                </div>
              </div>

              <MuiTableComponent
                data-testid="customer_list_table"
                rows={tabList[tabValue]?.data}
                columns={columnList}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                loading={loading}
                count={tabList[tabValue]?.count || 0}
                isPagination={true}
                isSorting={true}
                sortBy={selectedColumn}
                handleSorting={handleSorting}
                selectedOrder={selectedOrder}
                selectedColumn={selectedColumn}
              />
            </>
          )}
        </div>
      </div>
      {isColumn && (
        <CustomiseModal
          visible={isColumn}
          applyColumns={applyColumns}
          handleCancel={handleCancelColumn}
          allColumns={columns}
          checkedColumns={checkedColumns}
        />
      )}
    </>
  );
};
export default JobConfigList;
