export const TYPE_CONFIG = {
  PRODUCT: {
    idFilter: 'product_id_filter',
    nameFilter: 'product_name_filter',
  },
  CITY: {
    idFilter: 'city_id_filter',
    nameFilter: 'city_name_filter',
  },
  HUB: {
    idFilter: 'hub_id_filter',
    nameFilter: 'hub_name_filter',
  },
  CLUSTER: {
    idFilter: 'cluster_id_filter',
    nameFilter: 'cluster_name_filter',
  },
  AREA_MANAGER: {
    idFilter: 'area_manager_id_filter',
    nameFilter: 'area_manager_name_filter',
  },
};
