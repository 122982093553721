import React from 'react';
import styles from './styles.module.scss';
import { Divider, IconButton } from '@mui/material';
import { RefreshOutlined } from '@mui/icons-material';
import Spinner from '../../common/Spinner';
import LastUpdatedTime from '../../common/LastUpdatedTime';

const OrderDetails = (props) => {
  const { tabList, tabValue, loading, onRefresh } = props;
  if (loading) return <Spinner className={styles.sla_details_spinner} />;
  const data = tabList[tabValue]?.data?.[0] || {};
  const config = tabList[tabValue] || {};
  const { last_updated_at } = config || {};
  return (
    <div className={styles.left_container_1}>
      <div className={styles.sidebar_title_container}>
        <div className={styles.title_container}>
          <span>Licious Order</span>
          <LastUpdatedTime timestamp={last_updated_at} />
        </div>
        <IconButton onClick={onRefresh} disabled={loading}>
          <RefreshOutlined />
        </IconButton>
      </div>
      <div className={styles.b2}>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.accepted_orders || 0}</div>
            <div>Accepted Orders</div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <Divider />
        </div>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.billed_orders || 0}</div>
            <div>Billed Orders</div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <Divider />
        </div>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.cancelled_orders || 0}</div>
            <div>Cancelled Orders</div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <Divider />
        </div>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.delivered_orders || 0}</div>
            <div>Delivered Orders</div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <Divider orientation="vertical" />
        </div>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.dispatched_orders || 0}</div>
            <div>Dispatched Orders</div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <Divider orientation="vertical" />
        </div>
        <div className={styles.card_container_1}>
          <div className={styles.a2}>
            <div>{data.pending_orders || 0}</div>
            <div>Pending Orders </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
