import { Alert, Slide } from '@mui/material';
import { removeNotification } from '../../redux/Snackbar/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

function NotificationComponent({ setting, onClose }) {
  const { id, autoHideDuration, level, message } = setting;
  const handleClose = useCallback(() => {
    onClose(setting, id);
  }, [setting, id, onClose]);

  useEffect(() => {
    let timeout = null;
    if (autoHideDuration) {
      timeout = setTimeout(() => {
        handleClose();
      }, autoHideDuration);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoHideDuration, handleClose]);

  return (
    <Slide direction="down" in mountOnEnter unmountOnExit>
      <Alert
        sx={{ marginTop: '10px' }}
        variant="filled"
        onClose={handleClose}
        severity={level}>
        {message}
      </Alert>
    </Slide>
  );
}

function Notification() {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (rootReducer) => rootReducer.notificationReducer.notifications,
  );
  const onClose = useCallback(
    (setting, id) => {
      dispatch(removeNotification(id, notifications));
      if (typeof setting.onClose === 'function') {
        setting.onClose(setting);
      }
    },
    [dispatch],
  );

  return (
    <div className={styles.notification_container}>
      {notifications.map((each) => (
        <NotificationComponent
          key={each.id}
          setting={each}
          onClose={() => onClose(each, each.id)}
        />
      ))}{' '}
    </div>
  );
}

export default Notification;
