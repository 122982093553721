import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  checkbox_root: {
    padding: 0,
  },
  dialogContainer: {
    alignItems: 'flex-start',
    [`& .MuiDialog-paper`]: {
      width: '60%',
    },
  },
});
