import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styles from './styles.module.scss';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Spinner from '../Spinner';
import CustomTableCell from './CustomTableCell';
import CustomTableRow from './TableRow';

export default function MuiTableComponent(props) {
  const {
    rows,
    columns,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    count,
    isPagination,
    isSorting,
    handleSorting,
    selectedOrder,
    selectedColumn,
    loading,
    customStyle,
    handleOrderId,
  } = props;

  const refs = React.useRef([]);

  const renderEmptyRow = () => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell className="text-center" colSpan={columns?.length}>
          No Data available
        </TableCell>
      </TableRow>
    );
  };

  const renderLoadingRow = () => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell className="text-center" colSpan={columns?.length}>
          <Spinner className={styles.spinner} />
        </TableCell>
      </TableRow>
    );
  };

  const renderDetails = () => {
    if (loading) return renderLoadingRow();
    if (rows?.length === 0) return renderEmptyRow();
    else
      return (
        rows &&
        rows.map((row, index) => (
          <CustomTableRow
            key={index}
            loading={loading}
            row={row}
            columns={columns}
            index={index}
            handleOrderId={handleOrderId}
          />
        ))
      );
  };
  return (
    <>
      <div className={styles.main_container}>
        <TableContainer
          className={customStyle ? customStyle : styles.table_container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, indx) => (
                  <CustomTableCell
                    ref={(el) => (refs.current[indx] = el)}
                    loading={loading}
                    getPrevElements={() => refs.current.slice(0, indx)}
                    sticky={column.sticky}
                    key={column.key}
                    className={column.style}>
                    {isSorting && (
                      <TableSortLabel
                        className={styles.table_header_label}
                        active={selectedColumn === column.key}
                        direction={
                          selectedColumn === column.key
                            ? selectedOrder.toLowerCase()
                            : 'asc'
                        }
                        onClick={() => handleSorting(column.key)}>
                        {column.title}
                        {/* //transform description to title  */}
                        <If condition={selectedColumn === column.key}>
                          <Box component="span" sx={visuallyHidden}>
                            {selectedOrder.toLowerCase() === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        </If>
                      </TableSortLabel>
                    )}
                  </CustomTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderDetails()}</TableBody>
          </Table>
        </TableContainer>
        {isPagination && (
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  );
}
