import React from 'react';
import Modal from '@mui/material/Modal';
import styles from './styles.module.scss';
import TickIcon from '../../assets/tickIcon.svg';
import CrossMark from '../../assets/CrossMark.svg';
import {
  RESOLVE_FAILURE_MSG,
  RESOLVE_WARNING_MSG,
  RESOLVE_SUCCESS_MSG,
  LOG_WARNING_MSG,
  SAME_VAL_WARNING,
  IS_WARNING_MSG,
  DE_ISSUE_TYPE_WARNING,
  DEGRADE_SUCCESS_MSG,
  DEGRADE_FAILURE_MSG,
  API_DEGRADE_FAILURE_MSG,
} from '../../pageComponents/BrechManagementDasboardTab/constants';

const GenericModal = ({
  open,
  onClose,
  title,
  content,
  resolveSuccess,
  callResolveAPI,
  onSuccessResolveOkClick,
  degradeFailureMsg,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.container}>
        <If condition={resolveSuccess}>
          <div className={styles.icon_css}>
            <TickIcon />
          </div>
        </If>
        <If
          condition={
            title === RESOLVE_FAILURE_MSG || title === API_DEGRADE_FAILURE_MSG
          }>
          <div className={styles.icon_css}>
            <CrossMark />
          </div>
        </If>

        <div className={styles.modal_title}>{title}</div>
        <div className={styles.modal_content}>{content}</div>
        <div className={styles.modal_btns_align}>
          <If condition={title === RESOLVE_FAILURE_MSG}>
            <button onClick={onClose} className={styles.cancel_btn}>
              Cancel
            </button>
            <button onClick={callResolveAPI} className={styles.ok_btn}>
              Retry
            </button>
          </If>
          <If
            condition={
              title === RESOLVE_SUCCESS_MSG || title === DEGRADE_SUCCESS_MSG
            }>
            <button onClick={onSuccessResolveOkClick} className={styles.ok_btn}>
              Ok
            </button>
          </If>
          <If
            condition={
              title === RESOLVE_WARNING_MSG || title === IS_WARNING_MSG
            }>
            <button onClick={onClose} className={styles.cancel_btn}>
              No
            </button>
            <button onClick={callResolveAPI} className={styles.ok_btn}>
              Yes
            </button>
          </If>
          <If
            condition={
              title === LOG_WARNING_MSG ||
              title === SAME_VAL_WARNING ||
              title === DE_ISSUE_TYPE_WARNING ||
              title === API_DEGRADE_FAILURE_MSG
            }>
            <button onClick={onClose} className={styles.ok_btn}>
              Ok
            </button>
          </If>
        </div>
      </div>
    </Modal>
  );
};
export default GenericModal;
