import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';

const DataCardComponent = ({
  index,
  onCardCollapsible,
  handleKeyPress,
  data,
  currentCardIndex,
  handleMessageIdClick,
}) => {
  const formatTime = (date) => {
    return moment(date).format('DD MMM YY, hh:mma');
  };

  const formatText = (text) => {
    const textWithSpaces = text.replace(/_/g, ' ');
    return textWithSpaces
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase());
  };

  return (
    <div
      onKeyDown={handleKeyPress}
      onClick={onCardCollapsible}
      role="button"
      tabIndex={currentCardIndex}>
      <div
        className={
          currentCardIndex === index
            ? styles.active_left_side_box_style
            : styles.left_sub_container
        }>
        <div
          className={
            currentCardIndex === index
              ? styles.active_left_side_box_style
              : styles.left_side_box_style
          }>
          {currentCardIndex === index && (
            <div className={styles.active_bar}></div>
          )}

          <div className={styles.card_content}>
            <div className={styles.card_txt_align}>
              <div
                className={styles.card_id}
                onKeyDown={handleKeyPress}
                onClick={handleMessageIdClick}
                role="button"
                tabIndex={currentCardIndex}>
                {data?.issueIdentifier
                  ? data?.issueIdentifier
                  : data?.messageId}
              </div>
              <div className={styles.due_in}>
                {data?.dueIn ? 'Due in:' + data?.dueIn : ''}
              </div>
            </div>
            <div className={styles.card_txt_align}>
              <div className={styles.issue_box}>
                <If condition={data?.alertTag.toLowerCase() === 'high'}>
                  <div className={styles.flag_delay_con}>
                    <div className={styles.issue_priority_high}>
                      {data?.alertTag.toUpperCase()}
                    </div>
                    <div className={styles.delayed_by_high}>
                      {data?.issueDetail ? data?.issueDetail : '-'}
                    </div>
                  </div>
                </If>
                <If condition={data?.alertTag.toLowerCase() === 'medium'}>
                  <div className={styles.flag_delay_con}>
                    <div className={styles.issue_priority_medium}>
                      {data?.alertTag.toUpperCase()}
                    </div>
                    <div className={styles.delayed_by_medium}>
                      {data?.issueDetail ? data?.issueDetail : '-'}
                    </div>
                  </div>
                </If>
                <If condition={data?.alertTag.toLowerCase() === 'low'}>
                  <div className={styles.flag_delay_con}>
                    {data?.alertTag && (
                      <div className={styles.issue_priority_low}>
                        {data?.alertTag.toUpperCase()}
                      </div>
                    )}
                    <div className={styles.delayed_by_low}>
                      {data?.issueDetail ? data?.issueDetail : '-'}
                    </div>
                  </div>
                </If>
              </div>

              <div className={styles.date}>
                {data?.alertTime ? formatTime(data?.alertTime) : '-'}
              </div>
            </div>
            {currentCardIndex === index && (
              <div className={styles.card_txt_align}>
                <div className={styles.other_detail_1}>
                  <span>Issue Source: </span>
                  <span className={styles.other_detail_val}>
                    {data?.bu ? formatText(data?.bu) : '-'}
                  </span>
                </div>
                <div className={styles.other_detail_2}>
                  <span>Issue Type: </span>
                  <span className={styles.other_detail_val}>
                    {data?.alertName ? formatText(data?.alertName) : '-'}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCardComponent;
