import React, { useEffect, useState } from 'react';
import { accessibilityKey } from '../SSORedirect/utils';
import styles from './style.module.scss';
import CardComponent from '../BrechManagementDasboardTab/cardComponent';
import BreachDetailsComponent from './BreachDetails';
import GraphComponent from './GraphComponent';
import {
  getBreachDetailsUrl,
  getDailyTrendsTopCardsUrl,
  getDTCardWiseDataUrl,
  getFilterDataUrl,
} from '../../Utils/serviceUrls';
import {
  fetchBreachDetails,
  fetchDailyTrendsTopCards,
  fetchDTCardWiseDetails,
  fetchFiltersData,
} from '../../Utils/endPoints';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/Snackbar/actions';
import dayjs from 'dayjs';
import Spinner from '../../common/Spinner';
import HourlyTrendComponent from './HourlyTrend';
import { Divider } from '@mui/material';
import { useCallback, useMemo } from 'react';

const DailyTrendsComponent = () => {
  const [manuallyResolvedRowData, setManuallyResolvedRowData] = useState([]);
  const [totalBreachesRowData, setAllBreachesRowData] = useState([]);
  const [twoDLeftCardData, setTwoDLeftCardData] = useState([]);
  const [twoDRightCardData, setTwoDRightCardData] = useState([]);
  const [cardNames, setCardNameList] = useState([]);
  const [topCardDetails, setTopCardDetails] = useState({ id: null, bu: null });
  const [cardWiseDetails, setCardWiseDetails] = useState([]);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [cityWiseClicked, setCityWiseClicked] = useState(true);
  const [hubWiseClicked, setHubWiseClicked] = useState(false);
  const [graphDateFilter1, setGraphDateFilter1] = useState();
  const [graphDateFilter2, setGraphDateFilter2] = useState();
  const [toggleVal, setToggleVal] = useState('');
  const [rightGraphtoggleVal, setRightGraphToggleVal] = useState('');
  const [rightGraphCityWiseClicked, setRightGraphCityWiseClicked] =
    useState(true);
  const [rightGraphHubWiseClicked, setRightGraphHubWiseClicked] =
    useState(false);
  const [leftGraphCheckbox, setLeftGraphCheckbox] = useState(0);
  const [rightGraphCheckbox, setRightGraphCheckbox] = useState(0);
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  var [filterBodySelected, setFilterBody] = useState([]);

  const [leftGraphCityHubVal, setLeftGraphCityHubFilters] = useState([]);
  const [rightGraphCityHubVal, setRightGraphCityHubFilters] = useState([]);
  const [hourlyTrendCityHubFilters, setHourlyTrendCityHubFilters] = useState(
    [],
  );
  const [hourlyTrendData, setHourlyTrendData] = useState([]);
  const [hourlyTrendDate, setHourlyTrendDate] = useState(dayjs());
  const [hourlyTrendActiveFilter, setHourlyTrendActiveFilter] = useState(null);
  const [issueType, setIssueType] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  // const [activeKey, setActiveKey] = useState();

  const dispatch = useDispatch();

  const getFilters = (bu) => {
    const activeBu = topCardDetails?.bu === null ? bu : topCardDetails?.bu;

    fetchFiltersData(getFilterDataUrl(activeBu))
      .then((res) => {
        if (res?.data?.length) {
          const data = res?.data;

          const modifiedFilterArray = data
            ?.map((item) => {
              if (item?.name === 'CITY') {
                return {
                  type: item?.name,
                  options:
                    item.cityDetails.map((city) => ({
                      label: city.name,
                      value: city.name,
                    })) || [],
                };
              } else if (item?.name === 'HUB') {
                return {
                  type: item?.name,
                  options:
                    item.hubDetails.map((hub) => ({
                      label: hub.hubName,
                      value: hub.hubName,
                      city: hub?.cityName,
                    })) || [],
                };
              } else if (item?.name === 'ISSUE_TYPE') {
                setIssueType(item?.values);
              }
              return null;
            })
            .filter((item) => item !== null);
          setFilters(modifiedFilterArray);
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const getDependentOn = (type) => {
    switch (type) {
      case 'HUB':
        return 'CITY';
      default:
        return '';
    }
  };

  const getDependentOnKey = (type) => {
    switch (type) {
      case 'HUB':
        return 'city';
      default:
        return '';
    }
  };
  const getDependentKey = (type) => {
    switch (type) {
      case 'CITY':
        return 'HUB';
      default:
        return '';
    }
  };

  const updateFilterBody = (newFilterBody) => {
    setFilterBody((prevFilterBody) => {
      const existingFilterIndex = prevFilterBody.findIndex(
        (filter) => filter.columnName === newFilterBody.columnName,
      );

      if (existingFilterIndex !== -1) {
        prevFilterBody[existingFilterIndex] = newFilterBody;
      } else {
        prevFilterBody.push(newFilterBody);
      }
      return [...prevFilterBody];
    });
  };

  const handleAppliedFilters = (type, values, identifier) => {
    const updateFilters = (setFilters) => {
      setFilters((currValue) => {
        const dependentKey = getDependentKey(type);
        return {
          ...currValue,
          [type]: values,
          ...(dependentKey ? { [dependentKey]: [] } : {}),
        };
      });
    };

    switch (identifier) {
      case 'leftBlock':
        updateFilters(setLeftGraphCityHubFilters);
        break;
      case 'rightBlock':
        updateFilters(setRightGraphCityHubFilters);
        break;
      case 'hourlyTrend':
        updateFilters(setHourlyTrendCityHubFilters);
        break;
      default:
        console.warn(`Unknown identifier: ${identifier}`);
        break;
    }
  };

  const handleUpdateFilters = (values, type, identifier) => {
    var newFilterBody = {};
    var filterSelected = '';

    handleAppliedFilters(type, values, identifier);
    if (type === 'HUB') {
      filterSelected = 'hubName';
    } else if (type === 'CITY') {
      filterSelected = 'cityName';
      // Clear HUB object every time CITY is changed
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'hubName'),
        );
      }
      // Clear CITY object if values array is empty
      if (values.length === 0) {
        setFilterBody((prevFilterBody) =>
          prevFilterBody.filter((filter) => filter.columnName !== 'cityId'),
        );
      }
    } else {
      filterSelected = type;
      if (values.length === 0) {
        setFilterBody((prevFilterBody) => {
          return prevFilterBody.filter((filter) => filter.columnName !== type);
        });
      }
    }
    // Check if values array is empty and clear the corresponding filter
    if (values.length === 0) {
      setFilterBody((prevFilterBody) => {
        const updatedFilterBody = prevFilterBody.filter(
          (filter) => filter.columnName !== filterSelected,
        );
        return updatedFilterBody;
      });
      //resetting filters
      if (identifier === 'leftBlock') {
        const findCorrectParam = cardWiseDetails.filter(
          (item) => item.uiRank === 3,
        )[0];
        const formattedDate = dayjs(graphDateFilter1).format('YYYY-MM-DD');
        findCorrectParam.queryFields.forEach((field) => {
          if (
            (field.fieldName === 'cityName' || field.fieldName === 'hubName') &&
            Array.isArray(field.defaultValue) &&
            field.defaultValue.length === 0
          ) {
            field.selectedCity = [];
            field.onLoad = false;
          }
        });

        getBreachDetails(
          findCorrectParam,
          formattedDate,
          [toggleVal],
          leftGraphCheckbox,
          topCardDetails,
        );
      } else if (identifier === 'rightBlock') {
        const findCorrectParam = cardWiseDetails.filter(
          (item) => item.uiRank === 4,
        )[0];
        const formattedDate = dayjs(graphDateFilter2).format('YYYY-MM-DD');
        findCorrectParam.queryFields.forEach((field) => {
          if (
            (field.fieldName === 'cityName' || field.fieldName === 'hubName') &&
            Array.isArray(field.defaultValue) &&
            field.defaultValue.length === 0
          ) {
            field.selectedCity = [];
            field.onLoad = false;
          }
        });

        getBreachDetails(
          findCorrectParam,
          formattedDate,
          [rightGraphtoggleVal],
          rightGraphCheckbox,
          topCardDetails,
        );
      } else {
        setHourlyTrendActiveFilter(null);
        const findCorrectParam = cardWiseDetails.filter(
          (item) => item.uiRank === 5,
        )[0];
        findCorrectParam.queryFields.forEach((field) => {
          if (
            (field.fieldName === 'cityName' || field.fieldName === 'hubName') &&
            Array.isArray(field.defaultValue) &&
            field.defaultValue.length === 0
          ) {
            field.selectedCity = [];
            field.onLoad = false;
          }
        });
        const formattedDate = dayjs(hourlyTrendDate).format('YYYY-MM-DD');

        getBreachDetails(
          findCorrectParam,
          formattedDate,
          '',
          0,
          topCardDetails,
        );
      }
    } else {
      // Replace existing filter if values array is not empty
      if (type === 'HUB' || type === 'CITY') {
        if (values.length > 1) {
          newFilterBody = {
            type: 'IN',
            columnName: filterSelected,
            columnValue: {
              name: filterSelected,
              dataType: 'LIST',
              value: {
                type: 'Static',
                value: values,
              },
            },
          };
        } else {
          newFilterBody = {
            type: 'EQUAL',
            columnName: filterSelected,
            columnValue: {
              name: filterSelected,
              dataType: 'STRING',
              value: {
                type: 'Static',
                value: values[0] ? values[0] : [],
              },
            },
          };
        }

        if (identifier === 'leftBlock') {
          if (values.length === 0) {
            setLeftGraphCityHubFilters((prevFilterBody) => {
              const updatedFilterBody = prevFilterBody.filter(
                (filter) => filter.columnName !== filterSelected,
              );
              return updatedFilterBody;
            });
          } else {
            // Replace existing filter if values array is not empty
            if (type === 'HUB' || type === 'CITY') {
              updateFilterBody(newFilterBody);
            }
            const findCorrectParam = cardWiseDetails.filter(
              (item) => item.uiRank === 3,
            )[0];

            // Check if fieldName and cityName defaultValue are empty arrays
            if (type === 'CITY') {
              findCorrectParam.queryFields.forEach((field) => {
                if (
                  field.fieldName === 'cityName' &&
                  Array.isArray(field.defaultValue) &&
                  field.defaultValue.length === 0
                ) {
                  field.selectedCity = values;
                  field.onLoad = true;
                }
              });
            } else if (type === 'HUB') {
              findCorrectParam.queryFields.forEach((field) => {
                if (
                  field.fieldName === 'hubName' &&
                  Array.isArray(field.defaultValue) &&
                  field.defaultValue.length === 0
                ) {
                  field.selectedHub = values;
                  field.onLoad = true;
                }
              });
            }

            const formattedDate = dayjs(graphDateFilter1).format('YYYY-MM-DD');

            getBreachDetails(
              findCorrectParam,
              formattedDate,
              [toggleVal],
              leftGraphCheckbox,
              topCardDetails,
            );
          }

          setLeftGraphCityHubFilters((prev) => ({
            ...prev,
            [type]: values,
          }));
        } else if (identifier === 'rightBlock') {
          if (values.length === 0) {
            setRightGraphCityHubFilters((prevFilterBody) => {
              const updatedFilterBody = prevFilterBody.filter(
                (filter) => filter.columnName !== filterSelected,
              );
              return updatedFilterBody;
            });
          } else {
            // Replace existing filter if values array is not empty
            if (type === 'HUB' || type === 'CITY') {
              updateFilterBody(newFilterBody);
            }
          }
          const findCorrectParam = cardWiseDetails.filter(
            (item) => item.uiRank === 4,
          )[0];

          // Check if fieldName and cityName defaultValue are empty arrays
          if (type === 'CITY') {
            findCorrectParam.queryFields.forEach((field) => {
              if (
                field.fieldName === 'cityName' &&
                Array.isArray(field.defaultValue) &&
                field.defaultValue.length === 0
              ) {
                field.selectedCity = values;
                field.onLoad = true;
              }
            });
          } else if (type === 'HUB') {
            findCorrectParam.queryFields.forEach((field) => {
              if (
                field.fieldName === 'hubName' &&
                Array.isArray(field.defaultValue) &&
                field.defaultValue.length === 0
              ) {
                // field.fieldName = 'hubName';
                field.selectedHub = values;
                field.onLoad = true;
              }
            });
          }

          const formattedDate = dayjs(graphDateFilter2).format('YYYY-MM-DD');
          getBreachDetails(
            findCorrectParam,
            formattedDate,
            [rightGraphtoggleVal],
            rightGraphCheckbox,
            topCardDetails,
          );
          setRightGraphCityHubFilters((prev) => ({
            ...prev,
            [type]: values,
          }));
        } else if (identifier === 'hourlyTrend') {
          if (type === 'CITY' && values.length > 0) {
            setHourlyTrendActiveFilter('CITY');
          } else if (type === 'HUB' && values.length > 0) {
            setHourlyTrendActiveFilter('HUB');
          } else {
            setHourlyTrendActiveFilter(null);
          }
          if (values.length === 0) {
            setHourlyTrendCityHubFilters((prevFilterBody) => {
              const updatedFilterBody = prevFilterBody.filter(
                (filter) => filter.columnName !== filterSelected,
              );
              return updatedFilterBody;
            });
          } else {
            // Replace existing filter if values array is not empty
            if (type === 'HUB' || type === 'CITY') {
              updateFilterBody(newFilterBody);
            }
          }
          const findCorrectParam = cardWiseDetails.filter(
            (item) => item.uiRank === 5,
          )[0];
          let groupByToPass;

          // Check if fieldName and cityName defaultValue are empty arrays
          if (type === 'CITY') {
            groupByToPass = 'cityName';
            findCorrectParam.queryFields.forEach((field) => {
              if (
                field.fieldName === 'cityName' &&
                Array.isArray(field.defaultValue) &&
                field.defaultValue.length === 0
              ) {
                field.selectedCity = values;
                field.onLoad = true;
              }
            });
          } else if (type === 'HUB') {
            groupByToPass = 'hubName';
            findCorrectParam.queryFields.forEach((field) => {
              if (
                field.fieldName === 'hubName' &&
                Array.isArray(field.defaultValue) &&
                field.defaultValue.length === 0
              ) {
                field.selectedHub = values;
                field.onLoad = true;
              }
            });
          }

          const formattedDate = dayjs(hourlyTrendDate).format('YYYY-MM-DD');

          getBreachDetails(
            findCorrectParam,
            formattedDate,
            groupByToPass,
            0,
            topCardDetails,
          );
          setHourlyTrendCityHubFilters((prev) => ({
            ...prev,
            [type]: values,
          }));
        }
      }
    }
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    setCityWiseClicked(true);
    setHubWiseClicked(false);
    setRightGraphCityWiseClicked(true);
    setRightGraphHubWiseClicked(false);
    let matchedCard = cardNames.find((card) => card.name === tab);
    if (matchedCard) {
      setTopCardDetails({
        id: matchedCard.id,
        bu: matchedCard.bu,
      });
    }
    getDTCardWiseDetails(matchedCard?.id, matchedCard);
  };

  const setOneDDateFilter = (newValue) => {
    if (newValue && dayjs(newValue).isValid()) {
      const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
      setDate(dayjs(formattedDate).format('YYYY-MM-DD'));
      const findRightParam = cardWiseDetails.filter(
        (item) => item.uiRank === 1 || item.uiRank === 2,
      );
      findRightParam.forEach((card) => {
        getBreachDetails(card, formattedDate, [], false, topCardDetails);
      });
    } else {
      setDate(null);
    }
  };

  const handleGraphDateFilter = (newValue, identifier) => {
    if (newValue && dayjs(newValue).isValid()) {
      const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
      if (identifier === 'leftBlock') {
        setGraphDateFilter1(newValue);
        const findCorrectParam = cardWiseDetails.filter(
          (item) => item.uiRank === 3,
        );
        getBreachDetails(
          findCorrectParam[0],
          formattedDate,
          [toggleVal],
          leftGraphCheckbox,
          topCardDetails,
        );
      } else if (identifier === 'rightBlock') {
        setGraphDateFilter2(newValue);
        const findCorrectParam = cardWiseDetails.filter(
          (item) => item.uiRank === 4,
        );
        getBreachDetails(
          findCorrectParam[0],
          formattedDate,
          [rightGraphtoggleVal],
          rightGraphCheckbox,
          topCardDetails,
        );
      }
    } else {
      const today = dayjs();
      if (identifier === 'leftBlock') {
        setGraphDateFilter1(today);
      } else if (identifier === 'rightBlock') {
        setGraphDateFilter2(today);
      }
    }
  };

  const handleHourlyTrendDateFilter = (newValue) => {
    setHourlyTrendDate(newValue);
    const findCorrectParam = cardWiseDetails.filter(
      (item) => item.uiRank === 5,
    );
    getBreachDetails(
      findCorrectParam[0],
      dayjs(newValue).format('YYYY-MM-DD'),
      '',
      0,
      topCardDetails,
    );
  };

  const getDTTopCardNames = async () => {
    const body = {};
    try {
      const res = await fetchDailyTrendsTopCards(
        getDailyTrendsTopCardsUrl(),
        body,
      );
      const data = res?.data;
      setCardNameList(data);
      if (data && data.length > 0) {
        setCardNameList(data);
        setTopCardDetails({
          id: data[0]?.id,
          bu: data[0]?.bu,
        });
      }
      getFilters(data[0].bu);
      getDTCardWiseDetails(data[0].id, data[0]); // Ensure this awaits the details fetching
    } catch (e) {
      handleError(e);
    }
  };

  const allotDefaultDatesForBothGraphs = (data) => {
    data.length &&
      data.forEach((item) => {
        const dateField = item.queryFields.find(
          (field) => field.fieldName === 'eventDate',
        );

        if (dateField) {
          const dateValue = dayjs(dateField.defaultValue);

          if (item.uiRank === 3) {
            setGraphDateFilter1(dateValue);
          } else if (item.uiRank === 4) {
            setGraphDateFilter2(dateValue);
          }
        }

        const isManuallyResolved = item.queryFields.find(
          (field) => field.fieldName === 'manuallyResolved',
        );
        const toggleDefaultVal = item?.groupByFields?.defaultGroupBy?.find(
          (field) => field.name === 'cityHubWiseToggle',
        );

        if (item.uiRank === 3) {
          setLeftGraphCheckbox(
            isManuallyResolved ? isManuallyResolved?.defaultValue : 0,
          );

          setToggleVal(toggleDefaultVal && toggleDefaultVal?.value?.value);
        } else if (item.uiRank === 4) {
          setRightGraphCheckbox(
            isManuallyResolved ? isManuallyResolved?.defaultValue : 0,
          );
          setRightGraphToggleVal(
            toggleDefaultVal && toggleDefaultVal?.value?.value,
          );
        }
      });
  };

  const getDTCardWiseDetails = (id, topCardData) => {
    const body = {};
    setLoading(true);
    fetchDTCardWiseDetails(getDTCardWiseDataUrl(id), body)
      .then((res) => {
        setLoading(false);
        const data = res?.data;
        if (data && data.length > 0) {
          setCardWiseDetails(data);
          allotDefaultDatesForBothGraphs(data);
          for (const [index, card] of data.entries()) {
            try {
              getBreachDetails(card, '', '', 0, topCardData);
            } catch (error) {
              console.error(error);
            }
          }
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const extractGroupByArr = (data) => {
    const groupByArr = [];
    if (
      data &&
      data.groupByFields &&
      Array.isArray(data.groupByFields.defaultGroupBy)
    ) {
      const defaultGroupByValue =
        data.groupByFields.defaultGroupBy[0]?.value?.value;
      if (defaultGroupByValue) {
        groupByArr.push(defaultGroupByValue);
      }
    }
    return groupByArr;
  };

  const formGraphData = (labels, data, cityOrHub) => {
    const lowData = Array(labels.length).fill(0);
    const mediumData = Array(labels.length).fill(0);
    const highData = Array(labels.length).fill(0);

    if (cityOrHub === 'cityName') {
      data.forEach((item) => {
        const index = labels.indexOf(item.cityName);
        if (item.alertTag === 'Low') {
          lowData[index] += item.count;
        } else if (item.alertTag === 'Medium') {
          mediumData[index] += item.count;
        } else if (item.alertTag === 'High') {
          highData[index] += item.count;
        }
      });
    } else if (cityOrHub === 'hubName') {
      data.forEach((item) => {
        const index = labels.indexOf(item.hubName);
        if (item.alertTag === 'Low') {
          lowData[index] += item.count;
        } else if (item.alertTag === 'Medium') {
          mediumData[index] += item.count;
        } else if (item.alertTag === 'High') {
          highData[index] += item.count;
        }
      });
    } else {
      data.forEach((item) => {
        const index = labels.indexOf(item.hourOfDay);
        if (item.alertTag === 'Low') {
          lowData[index] += item.count;
        } else if (item.alertTag === 'Medium') {
          mediumData[index] += item.count;
        } else if (item.alertTag === 'High') {
          highData[index] += item.count;
        }
      });
    }

    const result = {
      labels: labels,
      datasets: [
        {
          label: 'Low',
          data: lowData,
          backgroundColor: '#F9DA78',
          borderColor: '#F9DA78',
        },
        {
          label: 'Medium',
          data: mediumData,
          backgroundColor: '#F19E38',
          borderColor: '#F19E38',
        },
        {
          label: 'High',
          data: highData,
          backgroundColor: '#8B1A10',
          borderColor: '#8B1A10',
        },
      ],
    };
    return result;
  };

  const transformJsonForLeftBarGraph = (data, hubOrCityData, uiRank) => {
    if (!Array.isArray(data)) {
      return;
    }
    var labels = [];
    var graphData = [];
    if (uiRank === 3) {
      if (hubOrCityData === 'cityName') {
        labels = [...new Set(data.map((item) => item.cityName))];
        graphData = formGraphData(labels, data, 'cityName');
        setTwoDLeftCardData(graphData);
      } else {
        labels = [...new Set(data.map((item) => item.hubName))];
        graphData = formGraphData(labels, data, 'hubName');
        setTwoDLeftCardData(graphData);
      }
    }
  };

  const transformJsonForRightBarGraph = (data, hubOrCityData, uiRank) => {
    if (!Array.isArray(data)) {
      return;
    }
    var labels = [];
    var graphData = [];

    if (uiRank === 4) {
      if (hubOrCityData === 'cityName') {
        labels = [...new Set(data.map((item) => item.cityName))];
        graphData = formGraphData(labels, data, 'cityName');
        setTwoDRightCardData(graphData, data);
      } else {
        labels = [...new Set(data.map((item) => item.hubName))];
        graphData = formGraphData(labels, data, 'hubName');
        setTwoDRightCardData(graphData, data);
      }
    }
  };

  const transformJsonForHourlyTrend = (data, dataOfHourlyTrend, uiRank) => {
    if (!Array.isArray(data)) {
      return;
    }
    var labels = [];
    var graphData = [];
    if (uiRank === 5) {
      if (dataOfHourlyTrend === 'hourOfDay') {
        labels = [...new Set(data.map((item) => item.hourOfDay))];
        graphData = formGraphData(labels, data, dataOfHourlyTrend);
        setHourlyTrendData(graphData);
      }
    }
  };

  const mapBreachDetailsWithUiRank = (data, detail) => {
    var uiRank = null;
    if (data.id === detail.id) {
      uiRank = detail.uiRank;
    }

    if (uiRank === 1) {
      setAllBreachesRowData(data && data?.data);
    } else if (uiRank === 2) {
      setManuallyResolvedRowData(data && data?.data);
    } else if (uiRank === 3) {
      var dataOfCity = data?.displayType?.xAxis;
      transformJsonForLeftBarGraph(data?.data, dataOfCity, uiRank);
    } else if (uiRank === 4) {
      var dataOfHub = data?.displayType?.xAxis;
      transformJsonForRightBarGraph(data?.data, dataOfHub, uiRank);
    } else if (uiRank === 5) {
      var dataOfHourlyTrend = data?.displayType?.xAxis;
      transformJsonForHourlyTrend(data?.data, dataOfHourlyTrend, uiRank);
    }
  };

  const generateBodyFromQueryFields = (
    detail,
    selectedDate,
    defaultDate,
    checkboxVal,
    buData,
  ) => {
    let queryFields = Array.isArray(detail)
      ? detail[0]?.queryFields
      : detail?.queryFields;

    if (!queryFields) {
      return [];
    }

    queryFields = queryFields.filter((field) => field.onLoad !== false);

    const buField = {
      fieldName: 'bu',
      dataType: 'STRING',
      defaultValue: buData ? buData?.bu : cardNames[0]?.bu,
    };

    if (!queryFields.find((field) => field.fieldName === 'bu')) {
      queryFields = [...queryFields, buField];
    }
    return queryFields.map((field) => {
      let value;
      let type;
      if (field.fieldName === 'eventDate') {
        value = selectedDate ? selectedDate : defaultDate;
        type = 'EQUAL';
      } else if (field.fieldName === 'manuallyResolved') {
        value = checkboxVal ? checkboxVal : field?.defaultValue;
        type = 'EQUAL';
      } else if (field.fieldName === 'cityName') {
        value = field?.selectedCity;
        type = 'IN';
      } else if (field.fieldName === 'hubName') {
        value = field?.selectedHub;
        type = 'IN';
      } else if (field.fieldName === 'alertName') {
        value = field?.selectedIssueType.length
          ? field?.selectedIssueType
          : field?.defaultValue;
        type = 'IN';
      } else {
        value = field.defaultValue; // Fallback to default value if not specified
        type = 'EQUAL';
      }

      return {
        type: type,
        columnName: field.fieldName,
        columnValue: {
          name: field.fieldName,
          dataType: field.dataType,
          value: {
            type: 'Static',
            value: value,
          },
        },
      };
    });
  };

  const getBreachDetails = (detail, date, groupBy, checkboxVal, buData) => {
    try {
      // Ensure detail is an object or array before processing
      if (Array.isArray(detail)) {
        if (detail.length > 0) {
          detail.forEach((item) => {
            processItem(item, date, groupBy, checkboxVal, buData);
          });
        }
      } else if (typeof detail === 'object' && detail !== null) {
        processItem(detail, date, groupBy, checkboxVal, buData);
      } else {
        console.error('Invalid data format:', detail);
      }
    } catch (error) {
      console.error('Error in getBreachDetails:', error);
    }
  };

  const processItem = (item, date, groupBy, checkboxVal, buData) => {
    var groupByArr;
    try {
      if (!item) {
        console.error('Invalid item:', item);
        return;
      }

      if (item.uiRank === 3 || item.uiRank === 4 || item.uiRank === 5) {
        const defaultGroupByFields = extractGroupByArr(item);

        groupByArr =
          Array.isArray(groupBy) && groupBy.length > 0
            ? groupBy
            : defaultGroupByFields;
      }

      // Safely get defaultDate based on item structure
      var defaultDate = item?.queryFields?.[0]?.defaultValue ?? null;
      var selectedDate = date ? date : defaultDate;

      const conditionObj = generateBodyFromQueryFields(
        item,
        selectedDate,
        defaultDate,
        checkboxVal,
        buData,
      );

      const body = {
        id: item.id,
        queryCondition: {
          type: 'LOGICAL_AND',
          conditions: conditionObj,
        },
        groupBy: groupByArr,
      };

      fetchBreachDetails(getBreachDetailsUrl(), body)
        .then((res) => {
          const data = res?.data;
          if (data && Object.keys(data).length > 0 && data !== undefined) {
            mapBreachDetailsWithUiRank(data, item);
          }
        })
        .catch((e) => {
          handleError(e);
        });
    } catch (error) {
      console.error('Error in processItem:', error);
    }
  };

  const clearHubCityArray = (findCorrectParam) => {
    if (findCorrectParam.length > 0) {
      findCorrectParam.forEach((param) => {
        if (Array.isArray(param.queryFields)) {
          param.queryFields.forEach((field) => {
            if (
              field.fieldName === 'cityName' ||
              (field.fieldName === 'hubName' &&
                Array.isArray(field.defaultValue) &&
                field.defaultValue.length === 0)
            ) {
              field.selectedCity = [];
              field.onLoad = false;
            }
          });
        }
      });
      return findCorrectParam;
    }
  };

  const handleCityWiseClick = () => {
    setHubWiseClicked(false);
    setToggleVal('cityName');
    setLeftGraphCityHubFilters({});
    setCityWiseClicked(true);
    if (!cityWiseClicked) {
      setLeftGraphCheckbox(0);
      const findCorrectParam = cardWiseDetails.filter(
        (item) => item.uiRank === 3,
      );
      const param = clearHubCityArray(findCorrectParam);
      getBreachDetails(
        param[0],
        dayjs(graphDateFilter1).format('YYYY-MM-DD'),
        ['cityName'],
        0,
        topCardDetails,
      );
    }
  };

  const handleHubWiseClick = () => {
    setToggleVal('hubName');
    setCityWiseClicked(false);
    setLeftGraphCityHubFilters({});
    setHubWiseClicked(true);
    if (!hubWiseClicked) {
      setLeftGraphCheckbox(0);
      const findCorrectParam = cardWiseDetails.filter(
        (item) => item.uiRank === 3,
      );
      const params = clearHubCityArray(findCorrectParam);
      getBreachDetails(
        params[0],
        dayjs(graphDateFilter1).format('YYYY-MM-DD'),
        ['hubName'],
        0,
        topCardDetails,
      );
    }
  };

  const handleRightGraphCityWiseClicked = () => {
    setRightGraphToggleVal('cityName');
    setRightGraphHubWiseClicked(false);
    setRightGraphCityHubFilters({});
    setRightGraphCityWiseClicked(true);
    if (!rightGraphCityWiseClicked) {
      setRightGraphCheckbox(0);
      const findCorrectParam = cardWiseDetails.filter(
        (item) => item.uiRank === 4,
      );
      const params = clearHubCityArray(findCorrectParam);

      getBreachDetails(
        params[0],
        dayjs(graphDateFilter2).format('YYYY-MM-DD'),
        ['cityName'],
        rightGraphCheckbox,
        topCardDetails,
      );
    }
  };

  const handleRightGraphHubWiseClicked = () => {
    setRightGraphToggleVal('hubName');
    setRightGraphCityWiseClicked(false);
    setRightGraphCityHubFilters({});
    setRightGraphHubWiseClicked(true);
    if (!rightGraphHubWiseClicked) {
      setRightGraphCheckbox(0);

      const findCorrectParam = cardWiseDetails.filter(
        (item) => item.uiRank === 4,
      );
      const params = clearHubCityArray(findCorrectParam);

      getBreachDetails(
        params[0],
        dayjs(graphDateFilter2).format('YYYY-MM-DD'),
        ['hubName'],
        rightGraphCheckbox,
        topCardDetails,
      );
    }
  };

  const handleLeftGraphCheckbox = () => {
    const findCorrectParam = cardWiseDetails.filter(
      (item) => item.uiRank === 3,
    );

    if (leftGraphCheckbox) {
      setLeftGraphCheckbox(0);
      getBreachDetails(
        findCorrectParam[0],
        dayjs(graphDateFilter1).format('YYYY-MM-DD'),
        [toggleVal],
        0,
        topCardDetails,
      );
    } else {
      setLeftGraphCheckbox(1);
      getBreachDetails(
        findCorrectParam[0],
        dayjs(graphDateFilter1).format('YYYY-MM-DD'),
        [toggleVal],
        1,
        topCardDetails,
      );
    }
  };

  const handleRightGraphCheckBox = (val) => {
    const findCorrectParam = cardWiseDetails.filter(
      (item) => item.uiRank === 4,
    );

    if (rightGraphCheckbox) {
      setRightGraphCheckbox(0);
      getBreachDetails(
        findCorrectParam[0],
        dayjs(graphDateFilter2).format('YYYY-MM-DD'),
        [rightGraphtoggleVal],
        0,
        topCardDetails,
      );
    } else {
      setRightGraphCheckbox(1);
      getBreachDetails(
        findCorrectParam[0],
        dayjs(graphDateFilter2).format('YYYY-MM-DD'),
        [rightGraphtoggleVal],
        1,
        topCardDetails,
      );
    }
  };

  useEffect(() => {
    getDTTopCardNames();
  }, []);

  useEffect(() => {
    if (cardNames && cardNames.length > 0) {
      setSelectedTab(cardNames[0]?.name);
    }
  }, [cardNames]);

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.message,
      }),
    );
    setLoading(false);
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  };

  // Memoized callback for handling card clicks
  const handleIssueTypeCardClickDebounced = useMemo(
    () =>
      debounce((key, value) => {
        // Manage active keys for multiple selection
        let newActiveKeys = [...activeKeys];
        let newValues = [];

        // Toggle selection
        if (newActiveKeys.includes(key)) {
          newActiveKeys = newActiveKeys.filter((k) => k !== key);
        } else {
          newActiveKeys.push(key);
        }

        // Collect the values of all selected keys
        newValues = newActiveKeys.reduce((acc, k) => {
          const values = issueType[k];

          if (values) {
            return [...acc, ...values];
          } else {
            console.warn(`No values found for key: ${k}`);
            return acc;
          }
        }, []);

        // Update state with new active keys
        setActiveKeys(newActiveKeys);

        const updatedData = cardWiseDetails.map((item) => {
          return {
            ...item,
            queryFields: item.queryFields.map((field) => {
              if (field.fieldName === 'alertName') {
                return { ...field, selectedIssueType: newValues, onLoad: true };
              } else if (item.uiRank === 1 || item.uiRank === 2) {
                // For uiRank 1 and 2, update the eventDate with `date` state

                if (field.fieldName === 'eventDate') {
                  return { ...field, defaultValue: date, onLoad: true };
                }
              } else if (item.uiRank === 3) {
                // For uiRank 3, update the eventDate with `graphDateFilter1` state
                if (field.fieldName === 'eventDate') {
                  const formatDate =
                    dayjs(graphDateFilter1).format('YYYY-MM-DD');

                  return {
                    ...field,
                    defaultValue: formatDate,
                    onLoad: true,
                  };
                }
              } else if (item.uiRank === 4) {
                // For uiRank 4, update the eventDate with `graphDateFilter2` state
                if (field.fieldName === 'eventDate') {
                  const formatDate =
                    dayjs(graphDateFilter1).format('YYYY-MM-DD');
                  return {
                    ...field,
                    defaultValue: formatDate,
                    onLoad: true,
                  };
                }
              } else if (item.uiRank === 5) {
                // For uiRank 5, update the eventDate with `hourlyTrendDate` state
                if (field.fieldName === 'eventDate') {
                  return {
                    ...field,
                    defaultValue: dayjs(hourlyTrendDate).format('YYYY-MM-DD'),
                    onLoad: true,
                  };
                }
              }
              return field;
            }),
          };
        });

        setCardWiseDetails(updatedData);

        // Call APIs with the updated data
        const updatedDataPassing = updatedData.map((item) => ({
          ...item,
          queryFields: item.queryFields.map((field) =>
            field.fieldName === 'alertName'
              ? {
                  ...field,
                  selectedIssueType:
                    Array.isArray(field.selectedIssueType) &&
                    field.selectedIssueType.length > 0
                      ? newValues
                      : [], // Ensure it’s an array
                  onLoad:
                    Array.isArray(field.selectedIssueType) &&
                    field.selectedIssueType.length === 0
                      ? false
                      : field.onLoad, // Update `onLoad` if `selectedIssueType` is empty
                }
              : field,
          ),
        }));

        updatedDataPassing.forEach((updatedItem) => {
          getBreachDetails(updatedItem, '', '', 0, topCardDetails);
        });
      }, 1000),
    [
      activeKeys,
      cardWiseDetails,
      issueType,
      setActiveKeys,
      setCardWiseDetails,
      getBreachDetails,
    ],
  );

  // Click handler function
  const handleIssueTypeCardClick = useCallback(
    (key, value) => {
      handleIssueTypeCardClickDebounced(key, value);
    },
    [handleIssueTypeCardClickDebounced],
  );

  return (
    <div className={styles.dt_main_container}>
      <If condition={cardNames && cardNames.length}>
        <div>
          <CardComponent
            cardData={cardWiseDetails}
            cardNames={cardNames}
            accessibilityKey={accessibilityKey}
            handleTabSelect={handleTabSelect}
            selectedTab={selectedTab}
          />
        </div>

        <Else />
        <div className={styles.top_card_no_data}>No Data Available!</div>
      </If>
      <div className={styles.divider_header}>
        <Divider />
      </div>
      <div className={styles.flex_property}>
        <div className={styles.issue_type_txt}>Issue Type</div>
        <div className={styles.gc_flex_property}>
          {Object.entries(issueType).map(([key, value]) => (
            <div
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ')
                  handleIssueTypeCardClick(key, value);
              }}
              role="button"
              tabIndex={0}
              key={key}
              className={`${styles.issue_type_card} ${
                activeKeys.includes(key) ? styles.active_issue_type_card : ''
              }`}
              onClick={() => handleIssueTypeCardClick(key, value)}>
              {key}
            </div>
          ))}
        </div>
      </div>
      <If condition={!loading}>
        <div>
          <div className={styles.bm_component}>
            <BreachDetailsComponent
              manuallyResolvedRowData={manuallyResolvedRowData}
              totalBreachesRowData={totalBreachesRowData}
              setDateFilter={setOneDDateFilter}
              date={date}
            />
          </div>

          <div className={styles.container}>
            <div className={styles.div1}>
              <GraphComponent
                twoDData={twoDLeftCardData}
                cityWiseClicked={cityWiseClicked}
                hubWiseClicked={hubWiseClicked}
                handleCityWiseClick={handleCityWiseClick}
                handleHubWiseClick={handleHubWiseClick}
                handleGraphDateFilter={handleGraphDateFilter}
                graphDateFilter1={graphDateFilter1}
                identifier={'leftBlock'}
                handleLeftGraphCheckbox={handleLeftGraphCheckbox}
                leftGraphCheckbox={leftGraphCheckbox}
                filters={filters}
                handleUpdateFilters={handleUpdateFilters}
                appliedFilters={leftGraphCityHubVal}
                getDependentOnKey={getDependentOnKey}
                getDependentOn={getDependentOn}
              />
            </div>

            <div className={styles.div2}>
              <GraphComponent
                twoDData={twoDRightCardData}
                rightGraphCityWiseClicked={rightGraphCityWiseClicked}
                rightGraphHubWiseClicked={rightGraphHubWiseClicked}
                handleRightGraphCityWiseClicked={
                  handleRightGraphCityWiseClicked
                }
                handleRightGraphHubWiseClicked={handleRightGraphHubWiseClicked}
                handleGraphDateFilter={handleGraphDateFilter}
                graphDateFilter2={graphDateFilter2}
                identifier={'rightBlock'}
                handleRightGraphCheckBox={handleRightGraphCheckBox}
                rightGraphCheckbox={rightGraphCheckbox}
                filters={filters}
                handleUpdateFilters={handleUpdateFilters}
                appliedFilters={rightGraphCityHubVal}
                getDependentOnKey={getDependentOnKey}
                getDependentOn={getDependentOn}
              />
            </div>
          </div>
          <div>
            <HourlyTrendComponent
              data={hourlyTrendData}
              filters={filters}
              handleUpdateFilters={handleUpdateFilters}
              appliedFilters={hourlyTrendCityHubFilters}
              getDependentOnKey={getDependentOnKey}
              getDependentOn={getDependentOn}
              handleHourlyTrendDateFilter={handleHourlyTrendDateFilter}
              hourlyTrendDate={hourlyTrendDate}
              hourlyTrendActiveFilter={hourlyTrendActiveFilter}
              identifier={'hourlyTrend'}
            />
          </div>
        </div>
        <Else />
        <Spinner className={styles.custom_style_spinner} />
      </If>
    </div>
  );
};

export default DailyTrendsComponent;
