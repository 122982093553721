import React, { useEffect, useRef, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const DEMapView = ({ mapField, selectedCardMsgId }) => {
  const mapRef = useRef(null);
  const [routeData, setRouteData] = useState([]);
  const [center, setCenter] = useState([]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.addControl(new L.Control.Fullscreen());
    }
  }, []);

  useEffect(() => {
    if (mapField.length > 0) {
      fetchRouteData();
    }
  }, [mapField]);

  const fetchRouteData = async () => {
    try {
      if (mapField && mapField.length > 2) {
        const coordinates = mapField
          .map((point) => {
            if (
              point &&
              point.latitude &&
              point.longitude &&
              point.latitude.value &&
              point.longitude.value
            ) {
              return `${point.longitude.value},${point.latitude.value}`;
            } else {
              throw new Error('Invalid data format for coordinates');
            }
          })
          .join(';');

        const response = await fetch(
          `https://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full&geometries=geojson`,
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();

        if (responseData.routes && responseData.routes.length > 0) {
          const routeCoordinates = responseData.routes[0].geometry.coordinates;
          setRouteData(routeCoordinates);
          setCenter([mapField[0].latitude.value, mapField[0].longitude.value]);
        }
      } else {
        setCenter([mapField[0].latitude.value, mapField[0].longitude.value]);
      }
    } catch (error) {
      console.error('Error fetching route data:', error);
    }
  };

  const getMarkerIcon = (status) => {
    if (status === 'DELIVERED') {
      return L.icon({
        iconUrl:
          'https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg',
        iconSize: [22, 22],
      });
    } else {
      return L.icon({
        iconUrl:
          'https://upload.wikimedia.org/wikipedia/commons/0/0d/Hourglass_icon_%28orange%29.svg',
        iconSize: [22, 22],
      });
    }
  };

  return (
    <If condition={center && center.length}>
      <MapContainer
        center={center}
        zoom={14}
        style={{ height: '300px', width: '40%' }}
        ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Polyline
          positions={routeData.map((point) => [point[1], point[0]])}
          color="#C5003E"
          weight={4}
        />
        {mapField.map((point, index) => (
          <Marker
            key={index}
            position={[point.latitude.value, point.longitude.value]}
            icon={getMarkerIcon(point.status.value)}>
            <Popup>
              <div>
                <h3>Details-</h3>
                <p>
                  Status:
                  <span style={{ color: 'green', fontWeight: 'bold' }}>
                    {point.status.value}
                  </span>
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <Else />
      {null}
    </If>
  );
};

export default DEMapView;
