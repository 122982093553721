import React from 'react';
import { red } from '@mui/material/colors';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import styles from './styles.module.scss';
import RadioCheckedIcon from '../../assets/radio_checked_icon.svg';
import RadioUnCheckedIcon from '../../assets/radio_unchecked_icon.svg';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
  },
}));

const RadioComponent = (props) => {
  const classes = useStyles();
  const { handleChange, radioList, defaultValue, value } = props;
  return (
    <div className={styles.radio_wrapper}>
      <FormControl component="fieldset">
        <RadioGroup
          defaultValue={defaultValue}
          value={value}
          aria-label="measure"
          name="row-radio-buttons-group"
          row
          style={{ paddingTop: '0' }}>
          {radioList.map((item) => (
            <FormControlLabel
              value={item.value}
              control={
                <Radio
                  icon={<RadioUnCheckedIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  onChange={handleChange}
                  sx={{
                    '&.Mui-checked': {
                      color: red[600],
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 28,
                    },
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={item.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
export default RadioComponent;
