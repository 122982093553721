import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

const Row = ({ index, style, data }) => {
  return <div style={style}>{data[index]}</div>;
};

function VirtualisedList({ list, rowRenderer }) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemCount={list.length}
          itemSize={35}
          itemData={list}>
          {rowRenderer || Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}

export default VirtualisedList;
