import styles from './styles.module.scss';

function getColorClassForExpressPercentage(value) {
  if (!value) return '';
  if (value >= 50) return styles.green_cell;
  if (value < 30) return styles.red_cell;
  return '';
}

function getColorClassForSla(value) {
  if (!value) return '';
  if (value < 95) return styles.red_cell;
  if (value < 98) return styles.yellow_cell;
  return styles.green_cell;
}

function getColorClassForOverride(value) {
  if (!value) return '';
  if (value > 10) return styles.red_cell;
  if (value >= 5) return styles.yellow_cell;
  return '';
}

export function getColorClass(key, value) {
  switch (key) {
    case 'express_order_percentage':
      return getColorClassForExpressPercentage(value);
    case 'sla_compliance_percentage':
    case 'express_sla_percentage':
    case 'scheduled_sla_percentage':
    case 'on_demand_sla_percentage':
      return getColorClassForSla(value);
    case 'override_percentage':
      return getColorClassForOverride(value);
    default:
      return () => '';
  }
}

export function getDelayTagColorClass(key, value) {
  if (!value) return '';
  if (value === 'LOW') return styles.green_cell;
  if (value === 'MEDIUM') return styles.yellow_cell;
  if (value === 'HIGH') return styles.red_color_cell;
  return '';
}
