import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import styles from './style.module.scss';
import { ToggleComponent } from './ToggleComponent';
import Checkbox from '@mui/material/Checkbox';
import LsFilter from '../../common/Select/Filter';
import Spinner from '../../common/Spinner';

const GraphComponent = ({
  twoDData,
  cityWiseClicked,
  hubWiseClicked,
  handleCityWiseClick,
  handleHubWiseClick,
  handleGraphDateFilter,
  identifier,
  graphDateFilter1,
  graphDateFilter2,
  rightGraphCityWiseClicked,
  rightGraphHubWiseClicked,
  handleRightGraphCityWiseClicked,
  handleRightGraphHubWiseClicked,
  leftGraphCheckbox,
  rightGraphCheckbox,
  handleLeftGraphCheckbox,
  handleRightGraphCheckBox,
  filters,
  handleUpdateFilters,
  appliedFilters,
  getDependentOn,
  getDependentOnKey,
}) => {
  Chart.register(...registerables);

  const numberOfBars = Object.keys(twoDData.labels || {}).length;
  const barWidth = 50;
  const minWidth = numberOfBars * barWidth;
  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: { stepSize: 100 },
      },
    },
  };

  const enhancedOptions = {
    ...options,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: { stepSize: 100 },
        grid: {
          drawOnChartArea: true,
        },
      },
    },
  };

  // if (
  // !twoDData ||
  // !twoDData.labels ||
  // twoDData.labels.length === 0 ||
  // !twoDData.datasets ||
  // twoDData.datasets.length === 0
  // ) {
  //   return <div className={styles.top_card_no_data}>No Data Available!</div>;
  // }

  let isCityWiseClicked, isHubWiseClicked;

  switch (identifier) {
    case 'leftBlock':
      isCityWiseClicked = cityWiseClicked;
      isHubWiseClicked = hubWiseClicked;
      break;
    case 'rightBlock':
      isCityWiseClicked = rightGraphCityWiseClicked;
      isHubWiseClicked = rightGraphHubWiseClicked;
      break;
    default:
      isCityWiseClicked = false;
      isHubWiseClicked = false;
      break;
  }

  return (
    <div>
      <div className={styles.gc_flex_property}>
        <div className={styles.gc_first_block}>
          <ToggleComponent
            handleCityWiseClick={
              identifier === 'leftBlock'
                ? handleCityWiseClick
                : handleRightGraphCityWiseClicked
            }
            handleHubWiseClick={
              identifier === 'leftBlock'
                ? handleHubWiseClick
                : handleRightGraphHubWiseClicked
            }
            cityWiseClicked={isCityWiseClicked}
            hubWiseClicked={isHubWiseClicked}
            handleGraphDateFilter={(newValue) =>
              handleGraphDateFilter(newValue, identifier)
            }
            identifier={identifier}
            graphDateFilter1={graphDateFilter1}
            graphDateFilter2={graphDateFilter2}
          />
          <div className={styles.checkbox_filter_container}>
            <div className={styles.filter_flex}>
              {filters.length > 0 &&
                filters.map((each) => {
                  // const isDisabled = each.type === 'CITY' && isHubWiseClicked;
                  const isDisabled = each.type === 'HUB' && isCityWiseClicked;
                  return (
                    <LsFilter
                      key={each.type}
                      options={each.options}
                      placeholder={each.type}
                      type={each.type}
                      onUpdate={(values) =>
                        handleUpdateFilters(values, each.type, identifier)
                      }
                      values={appliedFilters[each.type] || []}
                      appliedFilters={appliedFilters}
                      dependentOn={getDependentOn(each.type)}
                      dependentOnKey={getDependentOnKey(each.type)}
                      className={`${styles.filtr_width} ${
                        isDisabled ? styles.filtr_width_disabled : ''
                      }`}
                      disabled={isDisabled}
                    />
                  );
                })}
            </div>

            <div className={styles.flex}>
              <Checkbox
                checked={
                  identifier === 'leftBlock'
                    ? leftGraphCheckbox
                    : rightGraphCheckbox
                }
                onClick={
                  identifier === 'leftBlock'
                    ? () => handleLeftGraphCheckbox(1)
                    : () => handleRightGraphCheckBox(1)
                }
                name="resolvedBreaches"
              />
              <div className={styles.resolved_breaches_txt}>
                Manually Resolved Breaches
              </div>
            </div>
          </div>
          <If
            condition={
              Object.keys(twoDData).length ||
              !twoDData ||
              !twoDData.labels ||
              twoDData.labels.length === 0 ||
              !twoDData.datasets ||
              twoDData.datasets.length === 0
            }>
            {Object.keys(twoDData).length > 0 ? (
              <div>
                <div className={styles.yAxisContainer}></div>
                <div className={styles.yAxisContainer}>
                  <div
                    className={styles.barGraphScrollable}
                    style={{ minWidth: `${minWidth}px` }}>
                    <Bar
                      height={350}
                      data={twoDData}
                      options={enhancedOptions}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.top_card_no_data}>No Data Available!</div>
            )}
          </If>
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;
