import React, { useState } from 'react';
import styles from './style.module.scss';

const TimePicker = ({
  hour,
  minute,
  period,
  setHour,
  setMinute,
  setPeriod,
  // handleBlur,
}) => {
  //   const [hour, setHour] = useState('09');
  //   const [minute, setMinute] = useState('45');
  //   const [period, setPeriod] = useState('PM');

  return (
    <div className={styles.time_picker_container}>
      <div className={styles.element_heading}>Degradation active till</div>
      <div className={styles.time_picker}>
        <div>
          <div className={styles.time_picker_input}>
            <div>
              <input
                type="text"
                value={hour}
                // onChange={(e) => setHour(e.target.value)}
                onChange={(e) => setHour(e)}
                maxLength="2"
                className={styles.input_val}
              />
            </div>
          </div>
          <div className={styles.box_placeholder}>Hour</div>
        </div>

        <span className={styles.colon}>:</span>
        <div>
          <div className={styles.time_picker_input}>
            <div>
              <input
                type="text"
                value={minute}
                // onChange={(e) => setMinute(e.target.value)}
                onChange={(e) => setMinute(e)}
                maxLength="2"
                // handleBlur={handleBlur}
              />
            </div>
          </div>
          <div className={styles.box_placeholder}>Min</div>
        </div>

        <div className={styles.time_picker_period}>
          <select
            value={period}
            //   onChange={(e) => setPeriod(e.target.value)}
            onChange={(e) => setPeriod(e)}>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
