import classNames from 'classnames';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LiciousLogo from '../../assets/licious_logo.png';
import styles from './styles.module.scss';

function LoginForm({ onSubmit, className, isAuthLoading }) {
  const handleSubmitForm = (evt) => {
    evt.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit();
    }
  };
  return (
    <>
      <form className={styles.form_container} onSubmit={handleSubmitForm}>
        <div className={styles.login_header}>
          <div className={styles.login_header_text_wrapper}>
            <p>Sign In</p>
            <p>Use your Licious Account</p>
          </div>
          <img src={LiciousLogo} alt="Licious Logo" />
        </div>

        <div className={styles.login_button_container}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isAuthLoading}
            loadingPosition="end"
            sx={{ backgroundColor: '#C5003E' }}
            onClick={handleSubmitForm}
            disableElevation
            disableFocusRipple
            disableTouchRipple
            disableRipple>
            {isAuthLoading ? 'Logging in..' : 'Login with Google'}
          </LoadingButton>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
