import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from '@mui/material';
import styles from '../../pageComponents/BrechManagementDasboardTab/styles.module.scss';

const TableRowComponent = ({
  headers,
  rowData,
  disabledLogs,
  selectedDEType,
  handleLogClick,
  onDeTypeChange,
  disableLMActionBtn,
  rowIndex,
}) => {
  return (
    <TableRow>
      {headers.map((header, index) => (
        <TableCell key={index} className={styles.suggested_action_row}>
          {header === 'deIssueType' &&
          rowData[header]?.value.type === 'dropDown' ? (
            <Select
              value={selectedDEType}
              onChange={(event) => onDeTypeChange(event)}
              style={{ width: 180 }}>
              <MenuItem value="None">None</MenuItem>
              {rowData['deIssueType']?.value?.values.map((value, idx) => (
                <MenuItem key={idx} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <span>
              {typeof rowData[header].value === 'object'
                ? JSON.stringify(rowData[header].value)
                : rowData[header]?.value}
            </span>
          )}
        </TableCell>
      ))}
      <TableCell className={styles.suggested_action_row}>
        <button
          onClick={() => handleLogClick(rowData, rowIndex, selectedDEType)}
          className={
            disabledLogs[rowIndex]
              ? styles.diabled_suggested_action_confirm_btn
              : styles.suggested_action_confirm_btn
          }
          disabled={disableLMActionBtn}>
          {rowData['confirm']?.description}
        </button>
      </TableCell>
    </TableRow>
  );
};

const TableHeader = ({ headers }) => (
  <TableHead className={styles.suggested_action_table_header}>
    <TableRow>
      {headers.map((header, index) => (
        <TableCell className={styles.suggested_action_header} key={index}>
          {header}
        </TableCell>
      ))}
      <TableCell className={styles.suggested_action_header}>Action</TableCell>
    </TableRow>
  </TableHead>
);

const LastMileSuggestedActionTable = ({
  data,
  disabledLogs,
  handleLogClick,
  disableLMActionBtn,
  selectedDEType,
  onDeTypeChange,
}) => {
  if (
    !data ||
    !data.value ||
    !data.value.data ||
    data.value.data.length === 0
  ) {
    return <div>No data available</div>;
  }

  const rowData = data.value.data;
  const tableHeaders = Object.keys(rowData[0]).filter(
    (header) => header !== 'subTaskInstanceId' && header !== 'confirm',
  );

  return (
    <TableContainer className={styles.suggested_action_table_container}>
      <Table>
        <TableHeader headers={tableHeaders} />
        <TableBody>
          {rowData.map((row, index) => (
            <TableRowComponent
              key={index}
              disabledLogs={disabledLogs}
              selectedDEType={selectedDEType}
              handleLogClick={handleLogClick}
              headers={tableHeaders}
              rowData={row}
              onDeTypeChange={onDeTypeChange}
              disableLMActionBtn={disableLMActionBtn}
              rowIndex={index}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LastMileSuggestedActionTable;
