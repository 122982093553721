export const RESOLVE_SUCCESS_MSG = 'This ticket has been resolved';
export const RESOLVE_WARNING_MSG =
  'Are you sure all KML actions have been logged?';
export const RESOLVE_FAILURE_MSG = 'Resolve Failed.';
export const LOG_WARNING_MSG =
  'Please select current state and Action Taken for selected kml!';

export const SAME_VAL_WARNING =
  'Current State and Action Taken cannot be same.';

export const IS_WARNING_MSG =
  'Are you sure all the suggested action have be taken?';

export const DE_ISSUE_TYPE_WARNING = 'Please Select DE Issue Type.';

export const DEGRADE_SUCCESS_MSG =
  'Service has been successfully degraded for the selected hubs.';

export const DEGRADE_FAILURE_MSG =
  'Attempt to degrade service has failed. Please try again.';

export const API_DEGRADE_FAILURE_MSG =
  'An active degradation already exists, try again!';

export const ISSUES_FILTER = {
  type: 'ISSUES FILTER',
  options: [
    {
      label: 'Actionable Issues',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Non Actionable Issues',
      value: 'non_actionable',
    },
    {
      label: 'Resolved Issues',
      value: 'RESOLVED',
    },
  ],
};

export const DE_ISSUE_TYPE = {
  type: 'DE ISSUE TYPE',
  options: [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: 'Solvable',
      value: 'solvable',
    },
    {
      label: 'Non Solvable',
      value: 'non_solvable',
    },
  ],
};

export const SLOT_DETAILS = ['6-8AM', '8-10AM'];

export const CARD_DATA = [
  {
    name: 'PRODUCTION',
    description: 'Production',
    data: {
      totalAlertCount: 0,
    },
  },
  {
    name: 'SOURCING',
    description: 'Sourcing',
    data: {
      totalAlertCount: 0,
    },
  },
  {
    name: 'INVENTORY',
    description: 'Inventory',
    data: {
      totalAlertCount: 0,
    },
  },
  {
    name: 'LAST_MILE',
    description: 'Last Mile',
    data: {
      totalAlertCount: 2069,
    },
  },
];

export const ActionTrail = {
  columnData: [
    'Actor',
    'Action_Recommended',
    'Timer_Status',
    'Time_Stamp',
    'Action_Status',
  ],
  RowData: [
    {
      Actor: 'hi',
      Action_Recommended: '11',
      Timer_Status: '22',
      Time_Stamp: '33',
      Action_Status: '44',
    },
    {
      Actor: 'hi',
      Action_Recommended: '11',
      Timer_Status: '22',
      Time_Stamp: '33',
      Action_Status: '44',
    },
  ],
};

export const LM_suggested_action = {
  columnData: ['Actor', 'Action_Recommended', 'Due_in'],
  rowData: [
    {
      Actor: 'Central CT',
      Action_Recommended: 'Call HM',
      Due_in: '16:00',
    },
  ],
};

export const inventory_shortfal_detail_api_mock = {
  data: {
    orderModifiactionDetails: {
      value: {
        data: [
          {
            achievableQuantity: {
              value: 0,
              description: 'Achievable Quantity',
            },
            requiredQuantity: {
              value: 1,
              description: 'Required Quantity',
            },
            productId: {
              value: 'pr_ikuk3im2bid',
              description: 'Product Id',
            },
            orderId: {
              value: 'feoi4pbv4u8e',
              description: 'Order Id',
            },
            deliverySlot: {
              value: '3 PM - 5 PM',
              description: 'Delivery Slot',
            },
          },
          {
            achievableQuantity: {
              value: 0,
              description: 'Achievable Quantity',
            },
            requiredQuantity: {
              value: 1,
              description: 'Required Quantity',
            },
            productId: {
              value: 'pr_8loklvt7ks3',
              description: 'Product Id',
            },
            orderId: {
              value: 'owjx2wcqwn6a',
              description: 'Order Id',
            },
            deliverySlot: {
              value: '3 PM - 5 PM',
              description: 'Delivery Slot',
            },
          },
        ],
      },
      description: 'Order Modification Details',
    },
    actionTrail: {
      value: {
        data: [
          {
            timeStamp: {
              value: '2024-02-29 12:56:39.000',
              description: 'Time Stamp',
            },
            actionTaken: {
              value: '{"inwardTime": "18:24 PM"}',
              description: 'Action Taken',
            },
            comments: {
              value: '{"inwardTime": "18:24 PM"}',
              description: 'Comments',
            },
          },
        ],
      },
      description: 'Action Trail',
    },
    suggestedAction: {
      value: {
        resolve: {
          value: {
            type: 'actionButton',
          },
          description: 'Resolve',
        },
        data: [
          {
            actor: {
              value: 'Central CT',
              description: 'Actor',
            },
            confirm: {
              value: {
                type: 'actionButton',
              },
              description: 'confirm',
            },
            actionRecommended: {
              value: 'Call Hub Manager',
              description: 'Action actionRecommended',
            },
          },
        ],
      },
      description: 'Suggested Action',
    },
    inventoryInwardDetails: {
      value: {
        cityName: {
          value: 'Bangalore',
          description: 'CITY',
        },
        hubName: {
          value: 'ECT',
          description: 'HUB',
        },
        inwardTime: {
          value: '18:24 PM',
          description: 'Inward Time',
        },
      },
      description: 'Inventory Inward Details',
    },
  },
};

export const tableRowMock = [
  {
    bu: 'LAST_MILE',
    issueIdentifier: 'NCR-SPC-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: '1da54728-0ffc-4ab3-9788-1637c068785d',
    alertTime: '2024-03-04 11:50:22.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:15',
    index: 0,
  },
  {
    bu: 'LAST_MILE',
    issueIdentifier: 'NCR-SMG-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: '739db685-a44e-4c08-81da-9218818fb5c8',
    alertTime: '2024-03-04 11:50:22.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:17',
    index: 1,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Mumbai-MLD-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: '8540349c-e458-48df-a218-990e9cffe3db',
    alertTime: '2024-03-04 11:50:22.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:1',
    index: 2,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Kanpur-KKD-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: 'b54a47f8-7b25-46de-a6b1-dfd7d1cac1f3',
    alertTime: '2024-03-04 11:50:22.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:1',
    index: 3,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Bangalore-HOODI-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: 'd53dd9c6-4b84-4bf1-9b85-6da46986577d',
    alertTime: '2024-03-04 11:50:21.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:17',
    index: 4,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Surat-AJN-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: '2b5be543-4a21-4365-8e2d-0b7067f393ad',
    alertTime: '2024-03-04 11:50:20.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:17',
    index: 5,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'NCR-SUN-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: 'dbbf1126-de36-4adc-9798-769f838ce180',
    alertTime: '2024-03-04 11:50:09.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:16',
    index: 6,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Bangalore-KAM-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: 'fea3f223-ce5a-43b1-8a2b-6cfced4fb867',
    alertTime: '2024-03-04 11:50:09.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:18',
    index: 7,
  },
  {
    bu: 'INVENTORY',
    issueIdentifier: 'Bangalore-BEG-SHORTFALL',
    alertName: 'hub_inventory_shortfall_alert',
    alertTag: 'High',
    messageId: 'd177b205-52d9-47f8-94e9-d63422d6cdfd',
    alertTime: '2024-03-04 11:50:09.000',
    issueDetail: 'Order Modified',
    dueIn: '-23:17',
    index: 8,
  },
];
export const mapField = [
  {
    latitude: {
      value: '12.9218549',
      description: 'Latitude',
    },
    longitude: {
      value: '77.6651981',
      description: 'Longitude',
    },
    status: {
      value: 'DELIVERED',
      description: 'Order Status',
    },
  },
  {
    latitude: {
      value: '12.9239382',
      description: 'Latitude',
    },
    longitude: {
      value: '77.668465',
      description: 'Longitude',
    },
    status: {
      value: 'DISPATCHED',
      description: 'Order Status',
    },
  },
  {
    latitude: {
      value: '12.905279394531881',
      description: 'Latitude',
    },
    longitude: {
      value: '77.67190282056377',
      description: 'Longitude',
    },
    status: {
      value: 'DISPATCHED',
      description: 'Order Status',
    },
  },
];

export const suggestedActionForLASTMILE = {
  suggestedAction: {
    value: {
      resolve: {
        value: {
          type: 'actionButton',
        },
        description: 'Resolve',
      },
      data: [
        {
          actor: {
            value: 'CT System',
            description: 'Actor',
          },
          actionRecommended: {
            value: 'Call Hub Manager',
            description: 'Action Recommended',
          },
          confirm: {
            value: {
              type: 'actionButton',
            },
            description: 'Confirm',
          },
          subTaskInstanceId: {
            value: '3064118',
            description: 'SubTask Instance Id',
          },
          deIssueType: {
            value: {
              values: [
                'Vehicle breakdown',
                'Accident/ Injury',
                'Legal/Police Issues',
                'Vehicle Refuelling',
                'Traffic Issues',
              ],
              type: 'dropDown',
            },
            description: 'DE Issue Type',
          },
        },
      ],
    },
    description: 'Suggested Action',
  },
};
