import { createReducer } from '../../Utils/createReducer';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_NOTIFICATION,
} from './constants';

const NOTIFICATION_SETTING = {
  vertical: 'top',
  horizontal: 'center',
  level: 'info',
  message: '',
  onClose: null,
  open: false,
  autoHideDuration: 2000,
};

const INITIAL_STATE = {
  notifications: [],
};

const notificationReducer = createReducer(INITIAL_STATE, {
  [ADD_NOTIFICATION]: (state, action) => ({
    notifications: [
      ...state.notifications,
      {
        ...NOTIFICATION_SETTING,
        ...action.payload,
        id: `${new Date().valueOf()}_${Math.random()}`,
      },
    ],
  }),
  [REMOVE_NOTIFICATION]: (state, action) => ({
    notifications: [...action.payload],
  }),
  [RESET_NOTIFICATION]: (state) => ({
    notifications: [],
  }),
});

export default notificationReducer;
