import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from './styles.module.scss';

export default function LastMileMapDetailTable({
  tableData,
  columnHeaders,
  customStyle,
}) {
  return (
    <>
      <If condition={tableData.length > 0}>
        <TableContainer
          className={customStyle ? customStyle : styles.table_container}
          component={Paper}>
          <Table sx={{ minWidth: 400 }} size="small">
            <TableHead className={styles.table_header}>
              <TableRow>
                {tableData.map((col, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    className={styles.table_cell}>
                    {col?.description}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {columnHeaders.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {tableData.map((colHeader, colIndex) => {
                    const cellValue = rowData[colHeader?.value]?.value;
                    return (
                      <TableCell
                        className={styles.lm_table_row}
                        key={colIndex}
                        align="center">
                        {cellValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Else />
        <center>No Data Available!</center>
      </If>
    </>
  );
}
