import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import styles from './styles.module.scss';

const LsInput = forwardRef(
  (
    {
      className,
      label,
      placeholder,
      error,
      helperText,
      inputProps,
      InputProps,
      readOnly,
      ...rest
    },
    ref,
  ) => {
    return (
      <div>
        <TextField
          ref={ref}
          variant="outlined"
          fullWidth
          label={label}
          {...rest}
          className={classNames(styles.custom_input, className)}
          placeholder={placeholder}
          error={error}
          helperText={helperText ?? ' '}
          inputProps={{
            ...inputProps,
            required: false,
          }}
          InputProps={{
            readOnly,
            ...InputProps,
          }}
        />
      </div>
    );
  },
);

export default LsInput;
