import LsSelect from '..';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FilterOption from './FilterOption';
import FilterOptionContainer from './FilterOptionContainer';
import classNames from 'classnames';
import styles from './styles.module.scss';
import InputAdornment from '@mui/material/InputAdornment';

function LsFilter({
  values,
  onUpdate,
  popperClass,
  placeholder,
  appliedFilters,
  dependentOn,
  dependentOnKey,
  options,
  ...rest
}) {
  const [_appliedFilters, _setAppliedFilters] = useState([]);
  const count = values.length;

  const handleUpdate = (option, state) => {
    if (state) {
      _setAppliedFilters([..._appliedFilters, option.value]);
    } else {
      _setAppliedFilters(
        _appliedFilters.filter((each) => each !== option.value),
      );
    }
  };

  const handleApplyFilters = () => {
    onUpdate(_appliedFilters);
  };

  const handleResetFilters = () => {
    _setAppliedFilters([]);
    onUpdate([]);
  };

  const handleInitSelection = useCallback(() => {
    _setAppliedFilters(values || []);
  }, [values]);

  useEffect(() => {
    handleInitSelection();
  }, [handleInitSelection]);

  const filterOptions = useMemo(() => {
    if (!dependentOn) return options;
    if (!appliedFilters[dependentOn] || !appliedFilters[dependentOn].length)
      return options;
    return options.filter((each) =>
      appliedFilters[dependentOn].includes(each[dependentOnKey]),
    );
  }, [options, dependentOn, appliedFilters, dependentOnKey]);

  return (
    <LsSelect
      multiple
      disableCloseOnSelect
      value={[]}
      options={filterOptions}
      classes={{
        inputRoot: styles.input_root,
        popper: classNames(styles.autocomplete_popper, popperClass),
        paper: styles.autocomplete_paper,
      }}
      onOpen={handleInitSelection}
      ListboxComponent={FilterOptionContainer}
      ListboxProps={{
        onApplyFilters: handleApplyFilters,
        onResetFilters: handleResetFilters,
      }}
      helperText=""
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {placeholder}&nbsp;
            {count > 0 && (
              <p className={styles.count_circle}>
                <span className="margin-6">{count}</span>
              </p>
            )}
          </InputAdornment>
        ),
      }}
      renderOption={(props, option) => (
        <FilterOption
          key={props.key}
          optionProps={props}
          option={option}
          selected={_appliedFilters.includes(option.value)}
          selectedValues={_appliedFilters}
          onClick={handleUpdate}
        />
      )}
      {...rest}
    />
  );
}

export default LsFilter;
