import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_TOKEN_STORAGE_KEY } from './constants';
import AuthProviderUtils from './utils';
import Loader from '../../common/Spinner/index';
import { LOGIN_PATH } from '../../Routing/RouteConfig';
import StorageService from '../../Utils/storageService';
import { verifyTokenCompleteAction } from '../../redux/saga/UserReducer/actions';

function LoginProvider(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);

  const handleInitialise = useCallback(async () => {
    const token = StorageService.getValue(USER_TOKEN_STORAGE_KEY);
    try {
      const data = await AuthProviderUtils.verifyToken(token);
      dispatch(
        verifyTokenCompleteAction({
          token: data.access_token,
          user: data.claims,
          tokenStatus: data.status,
        }),
      );
    } catch (e) {
      navigate(LOGIN_PATH, { replace: true });
    } finally {
      setInitialised(true);
    }
  }, [dispatch]);

  useEffect(() => {
    handleInitialise();
  }, [handleInitialise]);

  if (!initialised) return <Loader data-testid="spinner" />;

  return <>{props.children}</>;
}

export default LoginProvider;
