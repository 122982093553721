import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import CustomTableCell from './CustomTableCell';
import PercentageCell from './PercentageCell';
import DelayTagCell from './DelayTagCell';
import styles from './styles.module.scss';
import style from './styles.module.scss';

const CustomTableRow = ({ loading, row, columns, index, handleOrderId }) => {
  const evenRow = !!(index % 2);
  const [dummyIndex, setDummyIndex] = useState(0);

  const refs = useRef([]);

  const getValue = (value) => {
    if (value || value == 0) return value;
    return '-';
  };

  const handleClick = (data) => {
    handleOrderId(data);
  };

  useEffect(() => {
    setDummyIndex((ind) => ind + 1);
  }, [loading]);

  return (
    <TableRow
      className={classNames({
        [styles.even_row]: evenRow,
        [styles.odd_row]: !evenRow,
      })}
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}>
      {columns.map((column, indx) => {
        const value = row[column?.key];
        const Component = column.component;

        return (
          <Choose>
            <When condition={column.represents === 'LINK'}>
              <TableCell
                key={column?.key}
                className={classNames(column.style, styles.leftAlign)}>
                <button onClick={() => handleClick(row)} className={style.link}>
                  {getValue(value)}
                </button>
              </TableCell>
            </When>
            <When condition={column.represents === 'DELAY_TAG_STATUS'}>
              <DelayTagCell
                key={column.key}
                column={column}
                value={getValue(value)}
              />
            </When>
            <When condition={column.represents === 'PERCENTAGE'}>
              <PercentageCell
                key={column.key}
                column={column}
                value={getValue(value)}
              />
            </When>
            <When condition={Component}>
              <Component
                key={column.key}
                column={column}
                value={getValue(value)}
                {...(column.componentProps || {})}
              />
            </When>
            <When
              condition={
                column.data_type === 'INTEGER' || column.data_type === 'DOUBLE'
              }>
              <CustomTableCell
                ref={(el) => (refs.current[indx] = el)}
                loading={loading}
                getPrevElements={() => refs.current.slice(0, indx)}
                sticky={column.sticky}
                key={column?.key}
                className={classNames(column.style, styles.centerAlign)}>
                {getValue(value)}
              </CustomTableCell>
            </When>
            <Otherwise>
              <CustomTableCell
                ref={(el) => (refs.current[indx] = el)}
                loading={dummyIndex}
                getPrevElements={() => refs.current.slice(0, indx)}
                sticky={column.sticky}
                key={column?.key}
                className={classNames(column.style)}>
                {getValue(value)}
              </CustomTableCell>
            </Otherwise>
          </Choose>
        );
      })}
    </TableRow>
  );
};

export default CustomTableRow;
