import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import styles from '../../pageComponents/BrechManagementDasboardTab/styles.module.scss';

const DegradeServiceTable = ({
  data,
  checkboxState,
  handleCheckboxChange,
  handleSelectAllCheckbox,
  isAllChecked,
}) => {
  // Check if data exists and has the necessary structure
  if (
    !data ||
    !data.value ||
    !Array.isArray(data.value.data) ||
    data.value.data.length === 0
  ) {
    return <div>No data available</div>;
  }

  return (
    <TableContainer className={styles.suggested_action_table_container}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead className={styles.suggested_action_table_header}>
          <TableRow>
            {/* Add the "Select All" checkbox before the "kml Name" header */}
            <TableCell
              align="center"
              className={styles.suggested_action_header}
              style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={isAllChecked} // Controlled state for "Select All"
                onChange={handleSelectAllCheckbox} // Handle "Select All" change
                style={{ marginRight: '8px' }} // Adjust space between checkbox and "kml Name"
              />
              {data.value.data[0].kml?.value.description || 'KML Name'}
            </TableCell>
            {['designedState', 'currentState', 'previousState'].map(
              (key, colIndex) => (
                <TableCell
                  key={colIndex}
                  align="center"
                  className={styles.suggested_action_header}>
                  {data.value.data[0][key]?.description || key}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.value?.data.map((row, rowIndex) => {
            const isRowInactive = row.kml.value.checked === true;

            return (
              <TableRow
                key={row.subTaskInstanceId.value + rowIndex}
                className={styles.suggested_action_row}
                style={{
                  backgroundColor: isRowInactive ? '#f0f0f0' : 'white', // Inactive rows are grayed out
                  opacity: isRowInactive ? 0.5 : 1, // Lower opacity for inactive rows
                }}>
                {['kml', 'designedState', 'currentState', 'previousState'].map(
                  (key, colIndex) => (
                    <TableCell
                      key={`${row.subTaskInstanceId.value}-${colIndex}`}
                      className={styles.suggested_action_row}
                      style={{ width: '200px' }}
                      align="center">
                      {key === 'kml' ? (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {/* Conditionally render a checkbox before "kml" */}
                          {row.kml.value.type === 'checkBox' && (
                            <input
                              type="checkbox"
                              checked={checkboxState[rowIndex]} // Bind checked state
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event,
                                  rowIndex,
                                  row.kmlId.value,
                                  row,
                                )
                              } // Pass kmlId when checkbox is clicked
                              disabled={isRowInactive} // Disable if row is inactive
                              style={{ marginRight: '8px' }} // Space between checkbox and text
                            />
                          )}
                          {row.kml.value.value || 'KML Name'}
                        </span>
                      ) : (
                        row[key]?.value
                      )}
                    </TableCell>
                  ),
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DegradeServiceTable;
