import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from './styles.module.scss';
import { fetchLinkedTicketDetails } from '../../Utils/endPoints';
import { fetchLinkedTicketUrl } from '../../Utils/serviceUrls';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/Snackbar/actions';
import moment from 'moment';

export default function LinkedTicketTable({
  tableData,
  columnHeaders,
  customStyle,
}) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [viewLogDetails, setViewLogDetails] = useState([]);

  const dispatch = useDispatch();

  const handleError = (e) => {
    dispatch(
      addNotification({
        level: 'error',
        message: e?.data?.errors?.[0]?.message,
      }),
    );
  };

  const callViewLogDetailsAPI = async (messageId) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    await fetchLinkedTicketDetails(
      fetchLinkedTicketUrl(messageId),
      null,
      headers,
    )
      .then((res) => {
        const data = res?.data;
        setViewLogDetails(data);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const toggleRowExpansion = (rowIndex, rowData) => {
    const messageId = rowData?.messageId?.value;
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows([]);
    } else {
      setExpandedRows([rowIndex]);
      callViewLogDetailsAPI(messageId);
    }
  };

  const formatTime = (date) => {
    return moment(date).format('hh:mma');
  };
  return (
    <>
      <If condition={tableData.length > 0}>
        <TableContainer
          className={customStyle ? customStyle : styles.table_container}
          component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead className={styles.table_header}>
              <TableRow>
                {columnHeaders.map((col, index) => {
                  if (col?.value !== 'details' && col?.value !== 'messageId') {
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        className={styles.table_cell}>
                        {col?.description}
                      </TableCell>
                    );
                  }
                })}
                <TableCell align="center" className={styles.table_cell}>
                  View Log
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((rowData, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <TableRow>
                    {columnHeaders.map((colHeader, colIndex) => {
                      if (
                        colHeader?.value !== 'details' &&
                        colHeader?.value !== 'messageId'
                      ) {
                        let cellValue = rowData[colHeader.value]?.value;
                        if (
                          colHeader?.value === 'createdAt' ||
                          colHeader?.value === 'actionTime'
                        ) {
                          const date = new Date(cellValue);
                          cellValue = formatTime(date);
                        }
                        return (
                          <TableCell
                            className={styles.table_row}
                            key={colIndex}
                            align="center">
                            {cellValue}
                          </TableCell>
                        );
                      }
                    })}
                    <TableCell className={styles.table_row} align="center">
                      <button
                        className={styles.view_log_btn}
                        onClick={() => toggleRowExpansion(rowIndex, rowData)}>
                        View Log
                      </button>
                    </TableCell>
                  </TableRow>
                  {expandedRows.includes(rowIndex) && (
                    <TableRow className={styles.view_log_details}>
                      <TableCell
                        colSpan={columnHeaders.length + 1}
                        className={styles.view_log_details_td}>
                        <TableContainer
                          className={styles.view_log_details_container}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                {viewLogDetails.length > 0 &&
                                  Object.keys(viewLogDetails[0]).map((key) => (
                                    <TableCell
                                      key={key}
                                      className={styles.view_log_headers}>
                                      {viewLogDetails[0][key]?.description}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {viewLogDetails.map((item, index) => (
                                <TableRow key={index}>
                                  {Object.keys(item).map((key) => {
                                    const value = item[key]?.value;
                                    if (key === 'actionTime') {
                                      const date = new Date(value);
                                      return (
                                        <TableCell
                                          key={key}
                                          className={styles.table_cell}>
                                          {value ? formatTime(date) : '-'}
                                        </TableCell>
                                      );
                                    } else {
                                      return (
                                        <TableCell
                                          key={key}
                                          className={styles.table_cell}>
                                          {value}
                                        </TableCell>
                                      );
                                    }
                                  })}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Else />
        <center>No Data Available!</center>
      </If>
    </>
  );
}
